<!-- TODO: check styling -->
<template>
  <div class="h-full min-h-screen w-full flex flex-col justify-center p-4 dragBody">
    <form action="#" @submit.prevent="">
      <h1 class="text-2xl mb-3 font-bold w-fit">{{ $t('overlay.header') }}</h1>
      <textarea
        v-model="description"
        :disabled="isLoading"
        class="block w-full mb-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-mossgray placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-mossgray sm:text-sm sm:leading-6"
        :placeholder="$t('overlay.textarea_placeholder')"
        autofocus
        ref="textInput"
      ></textarea>
      <!-- TODO: Abbrechen button. -->
      <div class="text-end">
        <button
          class="button-mossgray"
          @click="saveAndClose()"
        >
          <!-- <kbd class="text-gray-400 text-xs font-bold"
            ><kbd v-if="platform.includes('Mac')">⌘</kbd><kbd v-else>CTRL</kbd> <span>+</span>
            <kbd>Enter</kbd></kbd
          > -->
          <span>
            {{ $t('save') }}
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<style type="text/css">
.dragBody {
  -webkit-app-region: drag;
}
h1,
textarea,
button {
  -webkit-app-region: no-drag;
}
</style>

<script>
import desktopService from '@/services/desktop.service'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useAuthUserStore } from '@/stores/auth-user'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useOverlayWindowStore } from '@/stores/overlay-window'

export default {
  components: {},
  setup() {
    const overlayWindowStore = useOverlayWindowStore()
    return {
      overlayWindowStore: overlayWindowStore
    }
  },
  data() {
    return {
      isLoading: true,
      userName: null,
      description: null,
      foo: 'bar',
      lastState: null,
      isSaving: false,
      platform: ''
    }
  },
  mounted() {
    this.platform = navigator.platform
    // this.platform = 'Mac ARM'
    this.userName = useAuthUserStore().user.first_name
    this.description = useTimeEntryStore().getActiveTimeEntry?.description
    window.addEventListener('keyup', this.closeKeyEvent)
    window.addEventListener('keydown', this.saveKeyEvent)
    window.addEventListener('focus', this.focus)
    window.addEventListener('blur', this.blur)
    this.isLoading = false
    this.$refs.textInput.focus()

    this.$nextTick(() => {
      this.$refs.textInput.focus()
    })
    if (useTimeEntryStore().getActiveTimeEntry == null) {
      this.close()
    }
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.closeKeyEvent)
    window.removeEventListener('keydown', this.saveKeyEvent)
    window.removeEventListener('focus', this.focus)
    window.removeEventListener('blur', this.blur)
  },
  methods: {
    blur() {
      this.lastState = 'blur'
      this.saveAndClose()
    },
    focus() {
      if (this.lastState == 'blur') {
        this.isSaving = false
        this.isLoading = true
        window.location.reload()
      }
    },
    closeKeyEvent(event) {
      if (event.key == 'Escape') {
        this.close()
      }
    },
    saveAndClose() {
      if (this.isSaving) {
        return
      }
      this.isSaving = true
      if (!useTimeEntryStore().activeTimeEntry) {
        this.close()
      }
      useTimeEntryStore().getActiveTimeEntry.description = this.description
      timelinkStoresService.updateTl(useTimeEntryStore().getActiveTimeEntry)
      useTimeEntryStore().updateId(useTimeEntryStore().activeTimeEntry)
      this.overlayWindowStore.last_push = Date.now()
      this.close()
    },
    close() {
      this.description = null
      desktopService.setStatus('overlayWindowActive', false)
      this.isLoading = true
    },
    /**
     *
     * @param {KeyboardEvent} event
     */
    saveKeyEvent(event) {
      if ((event.metaKey || event.ctrlKey) && (event.key == 'Enter' || event.code == 13)) {
        this.saveAndClose()
        return false
      }
    }
  }
}
</script>
