class FeatureFlagsService {
  skipDev = true
  features = {
    panel: {
      domains: ['emfits.de', 'immotender.de'],
      users: [
        'm.nowocyn@swphl.de',
        'marcel.nowocyn@ikobb.de',
        // 'm.faehnle@swphl.de',
        'testuser@swphl.de',
        'b.richter@swphl.de',
        'marcel@example.com',
        'marcel@nowocyn.de',
        'pahl@revoolt.de'
      ]
    },
    language: {
      domains: ['emfits.de', 'immotender.de'],
      users: [
        'm.nowocyn@swphl.de',
        'marcel.nowocyn@ikobb.de',
        //  'm.faehnle@swphl.de',
        'b.richter@swphl.de',
        'marcel@example.com',
        'marcel@nowocyn.de',
        'testuser@nowocyn.de'
      ]
    },
    retrySymbol: {
      domains: ['emfits.de', 'immotender.de'],
      users: [
        'm.nowocyn@swphl.de',
        'marcel.nowocyn@ikobb.de',
        //  'm.faehnle@swphl.de',
        'b.richter@swphl.de',
        'marcel@example.com',
        'marcel@nowocyn.de',
        'testuser@nowocyn.de'
      ]
    },
    checkIdleState: {
      domains: ['emfits.de', 'immotender.de'],
      users: [
        'm.nowocyn@swphl.de',
        'marcel.nowocyn@ikobb.de',
        //  'm.faehnle@swphl.de',
        'b.richter@swphl.de',
        'marcel@example.com',
        'marcel@nowocyn.de',
        'testuser@nowocyn.de',
        'pahl@revoolt.de'
      ]
    },
    uploadImage: {
      domains: ['emfits.de', 'immotender.de'],
      users: [
        'marcel@example.com',
        'pahl@revoolt.de'
      ]
    }
  }
  canUseFeature(featureName = null, user = null) {
    if (!user) {
      return false
    }
    if (!featureName) {
      return false
    }

    if (this.features[featureName] == undefined || this.features[featureName] == null) {
      return false
    }

    let feature = this.features[featureName]
    let mail_domain = user.email.split('@')[1] ?? null
    if (!mail_domain) {
      return
    }

    if (!this.skipDev && import.meta.env.DEV) {
      return true
    }

    return (
      (feature?.domains ?? []).includes(mail_domain) ||
      (feature?.users ?? []).includes(user.email.toLowerCase())
    )
  }
}

export default new FeatureFlagsService()
