import { $t } from '@/config/i18n'
import timelinkStoresService from '@/services/timelink-stores.service'
import { defineStore, acceptHMRUpdate } from 'pinia'

export const useAlertsStore = defineStore('alerts', {
  /**
   *
   * @returns {{alerts: Array<{
   *  created_at: number,
   *  type: string,
   *  message: string,
   *  until: number
   * }>}}
   */
  state: () => ({
    alerts: []
  }),
  getters: {},
  actions: {
    addAlert(type, message, timer = 3) {
      timelinkStoresService.generateId()
      let until = Date.now() + timer * 1000
      let obj = {
        id: timelinkStoresService.generateId(),
        created_at: Date.now(),
        type: type,
        message: message,
        until: until
      }
      this.alerts.push(obj)
      timelinkStoresService.setOrRenewTimeout(
        this.$id,
        obj.id,
        () => {
          this.removeId(obj.id)
        },
        timer * 1000
      )
      return obj.id
    },
    successfullySaved(timer = 3) {
      return this.addAlert('success', $t('success_message'), timer)
    },
    error(message, timer = 3) {
      return this.addAlert('error', message, timer)
    },
    warning(message, timer = 3) {
      return this.addAlert('warning', message, timer)
    },
    info(message, timer = 3) {
      return this.addAlert('info', message, timer)
    },
    success(message, timer = 3) {
      return this.addAlert('success', message, timer)
    },
    removeId(id) {
      this.alerts = this.alerts.filter((item) => item.id != id)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAlertsStore, import.meta.hot))
}
