import { acceptHMRUpdate, defineStore } from 'pinia'
import apiService from '@/services/api.service'
import { useAuthUserStore } from './auth-user'
import { useProjectsStore } from './projects'
import timelinkStoresService from '@/services/timelink-stores.service'

export const useClientsStore = defineStore('clients', {
  /**
   *
   * @returns {{
   * clients: Array<{
   * id: string,
   * name: ?string,
   * info: ?string,
   * color: ?string,
   * image_id: ?string,
   * created_at: ?string,
   * updated_at: ?string,
   * tl: {
   * isDirty: boolean
   * origin: Object
   * }
   * }>,
   * last_fetch: number,
   * activeCount: number,
   * },
   * last_fetch: ?number,
   * activeCount: number}
   */
  state: () => {
    return {
      clients: [],
      last_fetch: null,
      activeCount: 0
    }
  },
  persist: true,
  getters: {
    getId: (state) => {
      return (id) => {
        if (id == undefined || id == null) {
          return null
        }
        return state.clients.find((client) => client.id === id)
      }
    },
    getLastUsed: (state) => {
      const last_used = useAuthUserStore().user?.last_used?.clients
      if (last_used == undefined || last_used == null) {
        return []
      }
      return state.clients.filter((item) => last_used.includes(item.id))
    },
    getDisplayName: (state) => {
      return (id) => {
        let entry = state.getId(id)
        if (!entry) {
          return ''
        }
        return entry.acronym ?? entry.name
      }
    }
  },
  actions: {
    initFetch() {
      if (this.clients.length == 0) {
        apiService.fetch(
          import.meta.env.VITE_API_URL + '/api/v1/clients',
          {
            limit: 10
          },
          (data) => {
            data.data.forEach((item) => this.addOrUpdate(item))
          },
          () => {
            this.clients = []
          }
        )
        let last_used = useAuthUserStore().user?.last_used?.clients
        if (last_used) {
          this.fetchIds(last_used)
        }
        this.sortEntries()
      }
      this.fetchActiveCount()
    },
    async fetchAll() {
      apiService.fetchAll(
        import.meta.env.VITE_API_URL + '/api/v1/clients',
        {},
        (data) => {
          data.data.forEach((item) => this.addOrUpdate(item))
        },
        () => {
          this.clients = []
        }
      )
    },
    async fetch(filter = {}, addSearch = false, addPanel = false, callback = null) {
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/clients',
        filter,
        (data, response) => {
          data.data.forEach((item) => this.addOrUpdate(item, addSearch, addPanel))
          if (typeof callback == 'function') {
            callback(data, response)
          }
        },
        () => {
          return
        }
      )
    },
    fetchCount(filter = {}, callback = null) {
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/clients',
        { ...filter, limit: 1 },
        (data, response) => {
          if (typeof callback == 'function') {
            callback(response.data.meta.total, response)
          }
        },
        () => {}
      )
    },
    fetchActiveCount(filter = {}, callback = null) {
      this.fetchCount({ ...filter, active: true }, (total, response) => {
        this.activeCount = total
        if (typeof callback == 'function') {
          callback(total, response)
        }
      })
    },
    fetchIds(ids = [], addSearch = false) {
      ids.filter((item) => item != undefined && item != null)
      // create mapIds as a array which holding the ids as chunks
      let mapIds = []
      let ids_chunks = ids.length / 10
      for (let i = 0; i < ids_chunks; i++) {
        mapIds.push(ids.slice(i * 10, (i + 1) * 10))
      }
      // fetch all the clients
      mapIds.map((item) => {
        apiService.fetchAll(
          import.meta.env.VITE_API_URL + '/api/v1/clients',
          { ids: item },
          (data) => {
            data.data.forEach((item) => this.addOrUpdate(item, addSearch))
          },
          () => {}
        )
      })
    },
    fetchUpdates() {
      let ids = []
      if (ids.length > 200) {
        return
      }
      this.clients.forEach((item) => {
        ids.push(item.id)
      })
      apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/clients',
        {
          limit: 200,
          ids: ids
        },
        (data) => {
          let foundIds = []
          data.data.forEach((item) => {
            foundIds.push(item.id)
            this.addOrUpdate(item, true)
          })
          let notFound = ids.filter((item) => !foundIds.includes(item))
          this.clients = this.clients.filter((item) => !notFound.includes(item.id))
        }
      )
    },
    fetchIfNotExists(id) {
      if (this.getId(id) == null) {
        this.fetchIds([id])
      }
    },
    async search(query, params = {}) {
      await apiService.fetch(
        import.meta.env.VITE_API_URL + '/api/v1/clients',
        {
          search: query,
          limit: 10,
          ...params
        },
        (data) => {
          data.data.forEach((item) => this.addOrUpdate(item, true))
        },
        () => {}
      )
    },
    sortEntries() {
      timelinkStoresService.setOrRenewTimeout(
        'clients',
        'sortEntries',
        () => {
          let used_clients = []
          let last_used = useAuthUserStore().user?.last_used?.clients
          if (last_used == null || last_used == undefined) {
            last_used = []
          }
          last_used?.forEach((item) => {
            let found = this.getId(item)
            if (found) {
              used_clients.push(found)
            }
          })
          this.clients = this.clients.filter((item) => !last_used.includes(item.id))
          this.clients.sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
          this.clients.unshift(...used_clients)
        },
        20
      )
    },
    addOrUpdate(entry, addSearch = false, addPanel = false) {
      let o_entry = null
      let projects = entry.limited_part_of_projects ?? null
      entry.limited_part_of_projects = undefined
      if ((o_entry = this.clients.find((item) => entry.id === item.id))) {
        if (entry.updated_at != o_entry.updated_at) {
          o_entry.tl.origin = entry
          if (!o_entry.tl.isDirty) {
            let fetchImage = false
            Object.entries(entry).forEach((item) => {
              if (item[0] == 'image_id' && o_entry[item[0]] != item[1]) {
                fetchImage = true
              }

              o_entry[item[0]] = item[1]
            })
            if (fetchImage || o_entry.tl.image == null) {
              setTimeout(() => {
                timelinkStoresService.getImageFor(this.getId(o_entry.id), null, false)
              }, 20)
            }
          }
        }
        if (
          entry.active_projects_count != undefined &&
          typeof entry.active_projects_count != 'undefined'
        ) {
          o_entry.tl.activeProjects = entry.active_projects_count
        }
      } else {
        entry.tl = {
          origin: { ...entry },
          isDirty: false,
          search: false,
          panel: false,
          image: {
            data: null
          }
        }
        if (addSearch) {
          entry.tl.search = true
        }
        if (addPanel) {
          entry.tl.panel = true
        }

        this.clients.push(entry)
        setTimeout(() => {
          timelinkStoresService.getImageFor(this.getId(entry.id), null, false)
        }, 20)
      }
      if (projects) {
        projects.forEach((item) => {
          useProjectsStore().addOrUpdate(item, addSearch, addPanel)
        })
      }
      setTimeout(() => {
        useProjectsStore()
          .projects.filter((item) => item.client_id == entry.id)
          .forEach((item) => {
            item.tl.clientActive = entry.active
          })
      }, 20)
      this.sortEntries()
    },
    updateIfExists(entry) {
      if (this.clients.find((item) => entry.id === item.id)) {
        this.addOrUpdate(entry)

        return true
      }
      return false
    },
    removeId(id) {
      this.clients = this.clients.filter((item) => item.id != id)
    },
    removeSearch(foundIds) {
      this.clients = this.clients.filter(
        (item) =>
          (useAuthUserStore().user.last_used ?? { clients: [] })['clients'].includes(item.id) ||
          foundIds.includes(item.id) ||
          !(item.tl.search ?? false) ||
          (item.tl.panel ?? false)
      )
      this.clients.forEach((item) => {
        item.tl.search = false
      })
    },
    removePanel(foundIds) {
      this.clients = this.clients.filter(
        (item) =>
          (useAuthUserStore().user.last_used ?? { clients: [] })['clients'].includes(item.id) ||
          foundIds.includes(item.id) ||
          (item.tl.search ?? false) ||
          !(item.tl.panel ?? false)
      )
      this.clients.forEach((item) => {
        item.tl.panel = false
      })
    },
    removeOld(foundIds = []) {
      this.clients = this.clients.filter(
        (item) =>
          foundIds.includes(item.id) ||
          ((useAuthUserStore().user.last_used ?? { clients: [] })['clients'] || []).includes(
            item.id
          ) ||
          (item.tl.search ?? false) ||
          (item.tl.panel ?? false)
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClientsStore, import.meta.hot))
}
