<template>
  <div class="timelink-stencil squircle-clip" :style="style">
    <draggable-area :movable="movable" @move="onMove" @move-end="onMoveEnd">
      <stencil-preview
        class="timelink-stencil__preview "
        :image="image"
        :coordinates="coordinates"
        :width="stencilCoordinates.width"
        :height="stencilCoordinates.height"
        :transitions="transitions"
      />
    </draggable-area>
    <SquirclePath></SquirclePath>
  </div>
</template>

<script>
import {
  DraggableElement,
  DraggableArea,
  StencilPreview,
  ResizeEvent,
} from "vue-advanced-cropper";
import SquirclePath from '@/components/general/SquirclePath.vue'

export default {
  emits: ["move", "moveEnd", "resize", "resizeEnd"],
  components: {
    StencilPreview,
    DraggableArea,
    DraggableElement,
    SquirclePath
  },
  props: {
    image: {
      type: Object,
    },
    coordinates: {
      type: Object,
    },
    transitions: {
      type: Object,
    },
    stencilCoordinates: {
      type: Object,
    },
		movable: {
			type: Boolean,
			default: true,
		},    
  },
  computed: {
    style() {
      const { height, width, left, top } = this.stencilCoordinates;
      const style = {
        width: `${width}px`,
        height: `${height}px`,
        transform: `translate(${left}px, ${top}px)`,
      };
      if (this.transitions && this.transitions.enabled) {
        style.transition = `${this.transitions.time}ms ${this.transitions.timingFunction}`;
      }
      return style;
    },
  },
  methods: {
    onMove(moveEvent) {
      this.$emit("move", moveEvent);
    },
    onMoveEnd() {
      this.$emit("move-end");
    },
    onResize(dragEvent) {
      const shift = dragEvent.shift();

      const widthResize = shift.left;
      const heightResize = -shift.top;

      this.$emit(
        "resize",
        new ResizeEvent(
          {
            left: widthResize,
            right: widthResize,
            top: heightResize,
            bottom: heightResize,
          },
          {
            compensate: true,
          }
        )
      );
    },
    onResizeEnd() {
      this.$emit("resize-end");
    },
    aspectRatios() {
      return {
        minimum: 1,
        maximum: 1,
      };
    },
  },
};
</script>


