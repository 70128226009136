import { captureMessage } from '@sentry/vue'
import { defineStore, acceptHMRUpdate } from 'pinia'

export const useIdleStore = defineStore('idle', {
  state: () => ({
    states: []
  }),
  persist: true,
  getters: {},
  actions: {
    addState(userState, screenState) {
      if (!userState || !screenState) {
        return
      }
      if (!Array.isArray(this.states)) {
        this.states = []
        captureMessage('States was not an array like it was defined')
      }
      this.states.push({ time: Date.now(), userState: userState, screenState: screenState })
      if (this.states.length >= 400) {
        this.states = this.states.slice(-300, this.states.length)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIdleStore, import.meta.hot))
}
