import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import de from '@/locales/de.json'

const i18n = createI18n({
  locale: 'de',
  legacy: false,
  fallbackLocale: 'en',
  messages: {
    en,
    de
  }
})

const $t = i18n.global.t

export { i18n, $t }
