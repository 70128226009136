<template>
  <li
    :class="[
      'relative cursor-default select-none py-2 pl-3 pr-9 border-b-1 border-mossgray group',
      active ? 'bg-mossgray ' : ''
    ]"
  >
    <div class="flex flex-col items-start space-y">
      <span
        :class="['ml-3 truncate   text-sm font-semibold', active ? ' text-white' : 'text-black']"
      >
        {{ item.name }}
      </span>
      <span :class="['  text-xs ml-3 truncate', active ? 'text-gray-300' : 'text-gray-500']">
        {{ item.info }}
      </span>
    </div>

    <span
      v-if="selected"
      :class="[
        'absolute inset-y-0 right-0 flex items-center pr-4',
        active ? 'text-white' : 'text-mossgray'
      ]"
    >
      <font-awesome-icon :icon="['fa-kit', 'tl-check']" />
      
    </span>
  </li>
</template>

<script>
export default {
  props: ['item', 'active', 'selected']
}
</script>
