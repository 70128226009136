<template>
  <div class="px-6 py-4 flex flex-col justify-between">
    <div class="flex flex-col">
      <div>
        <div
          class="font-medium"
          :class="{
            'text-gray-900': item.active,
            'text-gray-500 line-through ': !item.active
          }"
        >
          <router-link
            :to="{ name: 'ServiceEdit', params: { service_id: item.id } }"
            class="flex items-center gap-2 leading-5"
            >
            {{ item.name }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vue default export
export default {
  props: ['service'],
  components: {},
  setup() {
    return {}
  },
  created() {
    this.item = { ...this.service }
  },
  mounted() {
    this.$echo
      .private('service.' + this.item.id)
      .listen('ServiceUpdated', (payload) => {
        this.item = { ...this.item, ...payload.service }
      })
      .listen('.service.updated', (payload) => {
        this.item = { ...this.item, ...payload.service }
      })
  },
  beforeUnmount() {
    this.$echo.leave('service.' + this.item.id)
  },
  data() {
    return {
      item: {}
    }
  },
  computed: {},
  methods: {}
}
</script>
