import { defineStore, acceptHMRUpdate } from 'pinia'

export const useDebuggingStore = defineStore('debugging', {
  state: () => ({
    logEntries: []
  }),
  getters: {},
  actions: {
    addLogEntry(type, message, data = null) {
      this.logEntries.push({ type: type, message: message, data: data })
      if (this.logEntries.length > 200) {
        this.logEntries = this.logEntries.splice(0, 5)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDebuggingStore, import.meta.hot))
}
