import { usePanelStore } from '@/stores/panel'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useClientsStore } from '@/stores/clients'
import deckService from '@/services/deck.service'
import { useProjectsStore } from '@/stores/projects'

import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { nextTick, toRaw, watch } from 'vue'
// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { $t } from '@/config/i18n'
import { setActiveClient } from '../helper/clients'
import { setSelectableProject } from '../helper/projects'
import { setActiveService } from '../helper/services'
import timelinkStoresService from '@/services/timelink-stores.service'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} page
 */
function viewProjects(panel, arr, page = null) {
  const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
  const dims = deckService.getDimensions()
  let pagination = null
  let column = 0
  let row = 1
  let id = column + row * dims.columns
  let projects = []
  panel.setId(
    arr,
    0,
    !activeTimeEntry?.client_id || !activeTimeEntry?.service_id
      ? {
          ...panel.getCancelButton(),
          init: (item, panelArr) => {
            timelinkStoresService.setOrRenewWatcher(
              'panel',
              panel.getHandlerName(item),
              watch(
                () => activeTimeEntry.project_id,
                async (newVal) => {
                  await nextTick()
                  if (usePanelStore().view != 'selectProject') {
                    return
                  }
                  if (newVal) {
                    usePanelStore().updateView('recording')
                  }
                }
              )
            )
            timelinkStoresService.setOrRenewWatcher(
              'panel',
              panel.getHandlerName(item) + '_2',
              watch(
                () => activeTimeEntry.service_id,
                async (newVal) => {
                  await nextTick()
                  if (usePanelStore().view != 'selectProject') {
                    return
                  }
                  if (newVal) {
                    usePanelStore().updateView('recording')
                  }
                }
              )
            )
          }
        }
      : activeTimeEntry?.project_id == null &&
          useTimeEntryStore().requiredFields.includes('project_id')
        ? {
            type: 'image',
            text: $t('panel.back_to_customer_select'),
            image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-up-left' })),
            callback: () => {
              return () => {
                usePanelStore().updateView('selectClient')
              }
            }
          }
        : panel.getBackButton()
  )
  if (activeTimeEntry?.client_id) {
    setActiveClient(panel, arr, 1, activeTimeEntry)
    if (usePanelStore().wizardFinished) {
      if (activeTimeEntry?.service_id) {
        setActiveService(panel, arr, 3, activeTimeEntry)
      }
    }
  }
  panel.setId(arr, 2, {
    type: 'text',
    text: $t('panel.project_selection'),
    image: null
  })
  if (
    !useTimeEntryStore().requiredFields.includes('project_id') &&
    activeTimeEntry?.project_id == null &&
    activeTimeEntry?.service_id == null
  ) {
    panel.setId(arr, dims.columns - 2, {
      type: 'text',
      text: $t('panel.to_service_selection'),
      callback: () => {
        return () => {
          usePanelStore().updateView('selectService')
        }
      }
    })
  }
  let needToLoadMore = false

  if (page == null) {
    projects = useProjectsStore()
      .getLastUsed(activeTimeEntry?.client_id)
      .filter((item) => item.active)

    setActiveClient(panel, arr, 1, activeTimeEntry)

    panel.setId(arr, dims.columns - 1, {
      type: 'image',
      text: $t('panel.all_projects'),
      image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-down-az' })),
      callback: () => {
        return () => {
          usePanelStore().updatePage(0)
        }
      }
    })

    useProjectsStore().fetch(
      {
        active: true,
        client_id: activeTimeEntry?.client_id,
        limit: dims.columns * dims.rows * 2,
        page: 1,
        orders: [
          {
            column: 'name',
            direction: 'asc'
          }
        ]
      },
      false,
      true,
      () => {}
    )
  } else {
    let count = useClientsStore().getId(activeTimeEntry?.client_id)?.tl?.activeProjects ?? 0
    pagination = panel.getPagination(row, column, dims.rows, dims.columns, count, page)
    projects = useProjectsStore()
      .projects.filter((item) => item.client_id == activeTimeEntry?.client_id && item.active)
      .toSorted((a, b) => a.name.localeCompare(b.name))
    let tempCount = projects.length
    if (tempCount - pagination.end < count - pagination.end) {
      needToLoadMore = true
    }
    projects = projects.slice(pagination.start, pagination.end)
    let lastChange = toRaw(usePanelStore().lastChange)
    useProjectsStore().fetch(
      {
        active: true,
        client_id: activeTimeEntry?.client_id,
        limit: dims.columns * dims.rows * 2,
        page: page + 2,
        orders: [
          {
            column: 'name',
            direction: 'asc'
          }
        ]
      },
      false,
      true,
      () => {
        if (usePanelStore().view != 'selectProject' || !pagination.next || !needToLoadMore) {
          return
        }
        panel.setId(arr, dims.rows * dims.columns - 1, panel.getPaginationNextButton(page))
      }
    )
    panel.setId(arr, dims.columns - 1, {
      type: 'image',
      text: $t('panel.last_ten_projects'),
      image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-clock-rotate-left' })),
      callback: () => {
        return () => {
          usePanelStore().updatePage(null)
        }
      }
    })
  }
  projects.forEach((item) => {
    if (pagination) {
      if (pagination.previous && id == 0 + (dims.rows - 1) * dims.columns) {
        id = id + 1
      }
      if (pagination.next && id == dims.rows * dims.columns - 1) {
        id = id + 1
      }
      if (id >= dims.rows * dims.columns) {
        return
      }
    }
    setSelectableProject(panel, arr, id, item)

    id = id + 1
  })
  if (pagination?.previous) {
    panel.setId(arr, 0 + (dims.rows - 1) * dims.columns, panel.getPaginationPreviousButton(page))
  }
  if (pagination?.next) {
    if (page == null || !needToLoadMore) {
      panel.setId(arr, dims.rows * dims.columns - 1, panel.getPaginationNextButton(page))
    } else {
      panel.setId(arr, dims.rows * dims.columns - 1, {
        type: 'image',
        text: $t('panel.loading_dots'),
        image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-loader' }))
      })
    }
  }
}

export default viewProjects
