<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
    <div class="flex items-center justify-between w-full py-3 mb-3">
      <h1 class="text-2xl font-bold">
        {{ $t('edit_client') }}
      </h1>
    </div>

    <div class="flex flex-col-reverse gap-4 xl:grid xl:grid-cols-5">
      <div class="w-full h-full col-span-1">
        <div class="flex flex-col items-center justify-center w-full h-full gap-2">
          <div
            class="flex items-center justify-center w-32 h-32 text-sm leading-6 text-center text-gray-600 aspect-square squircle-clip"
            :style="{
              color: !client.image_id
                ? timelinkService.generateForegroundColor(
                  client.color ? client.color : timelinkService.generateBackgroundColor(client)
                )
                : null,
              backgroundColor: !client.image_id
                ? client.color
                  ? client.color
                  : timelinkService.generateBackgroundColor(client)
                : null
            }">
            <!-- {{ $t('client_edit_description') }} -->
            <img v-if="client.image_id" :src="timelinkService.getImageFor(client)" class="object-contain" />
            <span class="text-4xl font-bold" v-else v-text="timelinkService.acronymOrShortName(client)"></span>
          </div>
          <div class="flex flex-col mt-3 space-y-2">
            <BaseButton class="justify-center button button-lime" @click="showImageUpload = true">
              {{ $t('upload_image') }}
            </BaseButton>
            <BaseButton class="button button-raspberry" v-if="client.image_id" @click="showImageDeleteModal = true">
              {{ $t('delete') }}
            </BaseButton>
          </div>
        </div>
      </div>

      <form class="col-span-4 bg-white ring-1 ring-mossgray-200 rounded-xl" @submit.prevent="save">
        <div class="px-4 py-6 sm:p-8">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label for="name" class="input-label">{{ $t('name') }}</label>
              <input :disabled="!canEdit" type="text" name="name" id="name" autocomplete="name" v-model="client.name"
                class="w-full input" />
              <div class="text-sm text-red-500" v-if="errors.name">
                <template v-for="message in errors.name" :key="message">
                  <div>
                    {{ message }}
                  </div>
                </template>
              </div>
            </div>
            <div class="sm:col-span-3">
              <label for="acronym" class="input-label">{{ $t('acronym') }}</label>
              <input :disabled="!canEdit" type="text" name="acronym" id="acronym" autocomplete="acronym"
                v-model="client.acronym" class="w-full input" />
              <div class="text-sm text-red-500" v-if="errors.acronym">
                <template v-for="message in errors.acronym" :key="message">
                  <div>
                    {{ message }}
                  </div>
                </template>
              </div>
            </div>

            <div class="sm:col-span-6">
              <label for="info" class="input-label">{{ $t('info') }}</label>
              <div class="mt-2">
                <textarea :disabled="!canEdit" type="text" name="info" id="info" autocomplete="Off"
                  v-model="client.info" class="w-full textarea"></textarea>
              </div>
              <div class="text-sm text-red-500" v-if="errors.info">
                <template v-for="message in errors.info" :key="message">
                  <div>
                    {{ message }}
                  </div>
                </template>
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="active" class="input-label">{{ $t('active') }}</label>
              <div class="mt-2">
                <input :disabled="!canEdit" type="checkbox" name="active" id="active" autocomplete="Off"
                  v-model="client.active" class="checkbox" />
              </div>
              <div class="text-sm text-red-500" v-if="errors.active">
                <template v-for="message in errors.active" :key="message">
                  <div>
                    {{ message }}
                  </div>
                </template>
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="billable" class="input-label">{{ $t('billable') }}</label>
              <div class="mt-2">
                <input :disabled="!canEdit" type="checkbox" name="billable" id="billable" autocomplete="Off"
                  v-model="client.billable" class="checkbox" />
              </div>
              <div class="text-sm text-red-500" v-if="errors.billable">
                <template v-for="message in errors.billable" :key="message">
                  <div>
                    {{ message }}
                  </div>
                </template>
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="color" class="input-label">{{ $t('color') }}</label>
              <div class="mt-2">
                <input :disabled="!canEdit" type="color" name="color" id="color" autocomplete="Off"
                  v-model="client.color" class="input" />
              </div>
              <div class="text-sm text-red-500" v-if="errors.color">
                <template v-for="message in errors.color" :key="message">
                  <div>
                    {{ message }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="alert-danger" v-show="errors.length">
            <template v-for="item in errors" :key="item">
              <div>
                {{ item }}
              </div>
            </template>
          </div>
        </div>

        <div class="flex items-center px-4 py-4 border-t gap-x-6 border-mossgray-200 sm:px-6" :class="{
          'justify-end': companyStore?.company?.pull_provider,
          'justify-between': !companyStore?.company?.pull_provider
        }">
          <BaseButton class="button-red" type="button" @click="showDeleteModal = true"
            v-if="!companyStore?.company?.pull_provider" :disabled="!canEdit">
            <font-awesome-icon :icon="['fa-kit', 'tl-trash']" size="lg" class="mr-2" />
            {{ $t('delete') }}
          </BaseButton>
          <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
          <button v-if="!companyStore?.company?.pull_provider" type="submit" class="button-mossgray"
            :disabled="!canEdit">
            {{ $t('save') }}
          </button>
          <div v-else>
            {{ $t('managed_through') }}

            <span class="font-bold">{{ companyStore?.company?.pull_provider }}</span>.
          </div>
        </div>
      </form>
    </div>

    <div class="flex items-center w-full mt-8 mb-5">
      <h1 class="mr-auto text-2xl font-bold">
        {{ $t('project', 2) }}
      </h1>

      <input type="text" id="search" name="search" v-model="search" class="mr-3 input" :placeholder="$t('search')"
        v-if="projects.length > 0" />

      <BaseButton class="button-mossgray" @click="showCreateModal = true"
        :disabled="companyStore?.company?.pull_provider" v-if="authUserStore.user.admin >= 9">
        <font-awesome-icon :icon="['fa-kit', 'tl-plus']" class="mr-2 text-lg" /> {{ $t('new_project') }}
      </BaseButton>
    </div>

    <div class="overflow-x-auto">
      <!-- TODO: Translation -->
      <Alert v-if="projects.length == 0">Keine Projekte vorhanden.</Alert>

      <div class="border rounded-lg border-mossgray-300" v-if="projects.length > 0">
        <div class="bg-white divide-y rounded-t-lg divide-mossray-300">
          <template v-for="project in projects" :key="project.id">
            <ProjectsListElement :project="project"></ProjectsListElement>
          </template>
        </div>
        <div class="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 rounded-b-lg sm:px-6">
          <div class="flex justify-between flex-1 sm:hidden">
            <a href="#"
              class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">{{
                $t('previous') }}</a>
            <a href="#"
              class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">{{
                $t('next') }}</a>
          </div>
          <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                {{ $t('pagination_showing') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.from }}</span>
                {{ ' ' }}
                {{ $t('pagination_to') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.to }}</span>
                {{ ' ' }}
                {{ $t('pagination_of') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.total }}</span>
                {{ ' ' }}
                {{ $t('pagination_total') }}
              </p>
            </div>
            <div>
              <input type="number" class="input" min="1" :max="pagination.last_page" v-model="page" @change="fetch" />
            </div>
            <div>
              <nav class="inline-flex -space-x-px rounded-md shadow-sm isolate" aria-label="Pagination">
                <button type="button" @click="previous"
                  class="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-l-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                  <span class="sr-only">{{ $t('previous') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-left']" fixed-width />
                </button>

                <template v-for="item in pagination.links" :key="item.label">
                  <button :class="{
                    'relative z-10 inline-flex items-center bg-mossgray px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mossgray':
                      page == item.label,
                    'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0':
                      page != item.label
                  }" type="button" :disabled="item.label == '...'" @click="gotoPage(parseInt(item.label))">
                    {{ item.label }}
                  </button>
                </template>

                <button type="button" @click="next"
                  class="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-r-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                  <span class="sr-only">{{ $t('next') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-right']" fixed-width />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProjectsCreate v-model="showCreateModal" />
    <BaseModal v-model="showDeleteModal" @close-modal="showDeleteModal = false">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.client') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showDeleteModal = false" ref="closeDeleteModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-red" :disabled="!timer || timer.seconds > 0" @click="deleteClient"><span class="w-6"
              v-if="timer && timer.seconds > 0"> ({{ timer.seconds }}) </span>{{ $t('delete') }}</BaseButton>
        </div>
      </template>
    </BaseModal>
    <ImageDeleteModal v-model="showImageDeleteModal" @confirmed="deleteImage"></ImageDeleteModal>
    <!-- <BaseModal v-model="showImageDeleteModal" @close-modal="showImageDeleteModal = false">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.image') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showImageDeleteModal = false"
            ref="cancelImageDeleteModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-red" @click="deleteImage">{{ $t('delete') }}</BaseButton>
        </div>
      </template>
    </BaseModal> -->
  </TemplateComponent>
  <SquirclePath></SquirclePath>
  <template>
    <ImageUploadModal v-model="showImageUpload" :uploadUrl="uploadUrl" @upload-successfully="onUploadSuccessfully" />
  </template>

</template>

<script setup>
import TemplateComponent from '@/components/settings/TemplateComponent.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import apiService from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import ImageUploadModal from '@/components/modals/ImageUploadModal.vue'
import ImageDeleteModal from '@/components/modals/ImageDeleteModal.vue'
import SquirclePath from '@/components/general/SquirclePath.vue'
import Alert from '@/components/general/AlertComponent.vue'
import ProjectsCreate from './ProjectsCreate.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import featureFlagsService from '@/services/feature-flags.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useClientsStore } from '@/stores/clients'
import { useAlertsStore } from '@/stores/alerts'
import ProjectsListElement from '@/components/settings/ProjectsListElement.vue'
import { useTimer } from 'vue-timer-hook'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { captureException } from '@sentry/vue'
import { computed, nextTick, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { $t } from '@/config/i18n'
import datetime from '@/lib/datetime'
import axios from 'axios'

const timelinkService = timelinkStoresService
const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const clientsStore = useClientsStore()
const alertsStore = useAlertsStore()
const route = useRoute()
const router = useRouter()

const isLoading = ref(true)
const canEdit = ref(false)
const defaultClient = ref({
  name: null,
  info: null,
  color: null,
  acronym: null,
  active: false,
  billable: false
})
const client = ref({
  name: null,
  info: null,
  color: null,
  acronym: null,
  active: false,
  billable: false,
  image_id: null
})

const limit = ref(10)
const projects = ref([])
const search = ref('')
const pagination = ref({
  current_page: 1,
  from: 1,
  last_page: 1,
  links: [],
  path: '',
  per_page: 10,
  to: 1,
  total: 1
})
const page = computed({
  get: () => {
    return parseInt(route.query.page ?? 1)
  },
  set: (value) => {
    value = parseInt(value)
    router.replace({
      name: 'ClientEdit',
      params: { client_id: route.params.client_id },
      query: { ...route.query, page: value }
    })
  }
})

const timer = ref(null)
const errors = ref([])
const validationErrors = ref([])
const showCreateModal = ref(false)
const showDeleteModal = ref(false)
const closeDeleteModalButton = ref(null)
const showImageDeleteModal = ref(false)
const cancelImageDeleteModalButton = ref(null)
const showImageUpload = ref(false)
const uploadUrl = ref(
  import.meta.env.VITE_API_URL + '/api/v1/clients/' + route.params.client_id + '/image'
)
watch(
  () => showCreateModal.value,
  (newVal) => {
    if (!newVal) {
      fetchProjects(true)
    }
  }
)

watch(
  () => route.params.page,
  () => {
    fetchProjects()
  }
)

watch(
  () => search.value,
  () => {
    timelinkService.setOrRenewTimeout(
      'clientsProjectsList',
      'search',
      () => {
        if (page.value != 1) {
          page.value = 1
        } else {
          fetchProjects()
        }
      },
      400
    )
  }
)

watch(
  () => showDeleteModal.value,
  async (newVal) => {
    if (newVal) {
      timer.value = useTimer(new Date(Date.now() + 5 * 1000), true)
      await nextTick()
      closeDeleteModalButton.value?.focus()
    }
  }
)

watch(
  () => showImageDeleteModal.value,
  async (newVal) => {
    if (newVal) {
      await nextTick()
      cancelImageDeleteModalButton.value?.focus()
    }
  }
)

onMounted(() => {
  canEdit.value = authUserStore.user.admin >= 9 && !companyStore.company.pull_provider
  fetch()
  fetchProjects()
  window.echo
    .private('company.' + authUserStore.user.company_id)
    .listen('ProjectCreated', (payload) => {
      if ((payload.project.client_id ?? null) == route.params.client_id) {
        fetchProjects(true)
      }
    })
    .listen('.project.created', (payload) => {
      if ((payload.project.client_id ?? null) == route.params.client_id) {
        fetchProjects(true)
      }
    })
})

async function fetch() {
  isLoading.value = true
  try {
    let data = await apiService.fetchId(
      import.meta.env.VITE_API_URL + '/api/v1/clients',
      route.params.client_id
    )
    client.value = data.data
    client.value.tl = { image: null }
    isLoading.value = false
    clientsStore.updateIfExists(data.data)
  } catch (error) {
    if (error.response.status == 404) {
      alertsStore.error($t('errors.client.not_found'))
      router.push({ name: 'ClientsList' })
    } else {
      alertsStore.error($t('errors.ups'))
      console.error(error)
    }
  }
}
async function save() {
  if (authUserStore.user.admin < 5) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  if (companyStore.company.pull_provider) {
    // TODO: Add support for editable fields that don't belong to
    return
  }
  isLoading.value = true
  errors.value = []
  validationErrors.value = []

  let sendData = {}
  Object.entries(defaultClient.value).forEach(([key, value]) => {
    sendData[key] = client.value[key]
  })
  try {
    let response = await apiService.update(
      import.meta.env.VITE_API_URL + '/api/v1/clients',
      route.params.client_id,
      sendData
    )
    if (response.success) {
    }
    client.value = response.data
    client.value.tl = {
      image: null
    }
    alertsStore.successfullySaved()
  } catch (error) {
    if (apiService.checkIfNotAuthenticated(error)) {
      return
    } else if (
      error.response.status == 403 &&
      error.response.data.message == $t('errors.no_subscription')
    ) {
      // TODO: Add better error handling!
      alertsStore.error($t('errors.no_subscription'))
    } else if (apiService.checkIfServerError(error)) {
      //
    } else if (error.response.status == 422) {
      errors.value = []
      error?.response?.data?.errors?.forEach((item) => {
        validationErrors.value[item] = $t(apiService.convertValidationKey(item))
      })
    } else {
      errors.value = [$t('errors.ups')]
      console.error(error)
    }
  }

  isLoading.value = false
}

function isEditable(field) {
  // let provider = useCompanyStore().company.pull_provider
  if (field == 'acronym') {
    return true
  }
  if (field == 'color') {
    return true
  }
  return false
}

async function deleteClient() {
  isLoading.value = true
  try {
    await apiService.delete(
      import.meta.env.VITE_API_URL + '/api/v1/clients',
      route.params.client_id
    )
    router.push({
      name: 'ClientsList'
    })
  } catch (error) {
    if (
      error.response.status == 403 &&
      error.response.data.message == $t('errors.no_subscription')
    ) {
      // TODO: Add better error handling!
      alertsStore.error($t('errors.no_subscription'))
    } else if (apiService.checkIfServerError(error)) {
      alertsStore.error($t('errors.500'))
    } else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }
  isLoading.value = false
}

async function fetchProjects(withoutLoading = false) {
  if (!withoutLoading) {
    isLoading.value = true
  }
  try {
    let data = await apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/projects', {
      limit: limit.value,
      page: page.value,
      client_id: route.params.client_id,
      search: search.value,
      orders: [
        {
          column: 'name',
          direction: 'asc'
        }
      ]
    })
    pagination.value = { ...data.meta }
    pagination.value.links = pagination.value.links.filter(
      (item) => !item.label.includes('Previous') && !item.label.includes('Next')
    )
    projects.value = data.data
  } catch (error) {
    console.error(error)
    // TODO: Add errorhandling
  }
  if (!withoutLoading) {
    isLoading.value = false
  }
}
function next() {
  if (page.value >= pagination.value.last_page) {
    return
  }
  page.value += 1
  // this.fetchProjects()
}
function previous() {
  if (page.value <= 1) {
    return
  }
  page.value -= 1
  // this.fetchProjects()
}
function gotoPage(newPage = 1) {
  if (newPage < 1) {
    page.value = 1
  } else if (newPage > pagination.value.last_page) {
    page.value = pagination.value.last_page
  } else {
    page.value = newPage
  }
  // this.fetchProjects()
}

function onUploadSuccessfully(data) {
  client.value = data
  client.value.tl = {
    image: null
  }
}

async function deleteImage() {
  isLoading.value = true
  showImageDeleteModal.value = false
  const image_id = client.value.image_id
  if (!image_id) {
    client.value.image_id = null
    if (client.value?.tl?.image) {
      client.value.tl.image = null
    } else {
      client.value.tl = {
        image: null
      }
    }
    return
  }
  try {
    let imageCacheId = null
    try {
      let respImage = await axios.get(
        import.meta.env.VITE_API_URL + '/api/v1/img/' + client.value.image_id,
        { validateStatus: false }
      )
      imageCacheId = respImage.id
    } catch (error) {
      //
    }
    let response = await apiService.delete(
      import.meta.env.VITE_API_URL + '/api/v1/img',
      client.value.image_id
    )
    if (response.success) {
      alertsStore.success($t('successfully.deleted_image'))
      client.value.image_id = null
      if (client.value?.tl?.image) {
        client.value.tl.image = null
      } else {
        client.value.tl = {
          image: null
        }
      }
      if (imageCacheId) {
        axios.storage.remove(imageCacheId)
      }
      clientsStore.addOrUpdate({
        id: client.value.id,
        image_id: client.value.image_id,
        updated_at: datetime.iso(Date.now())
      })
    } else {
      alertsStore.error($t('errors.ups_delete'))
    }
  } catch (error) {
    if (error?.response?.status == 404) {
      alertsStore.success($t('successfully.deleted_image'))
      client.value.image_id = null
      if (client.value?.tl?.image) {
        client.value.tl.image = null
      } else {
        client.value.tl = {
          image: null
        }
      }
      if (imageCacheId) {
        axios.storage.remove(imageCacheId)
      }
      clientsStore.addOrUpdate({
        id: client.value.id,
        image_id: client.value.image_id,
        updated_at: datetime.iso(Date.now())
      })
    } else if (apiService.checkIfServerError(error)) {
      //
    } else {
      alertsStore.error($t('errors.ups'))
      console.log(error)
      captureException(error)
    }
  }
  isLoading.value = false
}
</script>
