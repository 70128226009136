<template>
  <div
    class="top-0 text-center bg-mossgray/30 flex items-center justify-center"
    :class="{
      'left-0 fixed w-screen h-screen': fullPage,
      'absolute left-0 right-0 top-0 bottom-0': !fullPage,
      'z-30': !overAll,
      'z-50': overAll
    }"
    v-show="showLoading"
  >
    <div class="flex items-center space-x-4 text-3xl bg-ivory rounded-full p-4 px-6" v-if="!test">
      <font-awesome-icon
        :icon="['fa-kit', 'tl-loader']"
        size="lg"
        spin
        style="--fa-animation-duration: 2.5s"
      />
      <img class="max-h-10" :src="img" />
    </div>
    <div v-else class="flex items-center space-x-6 text-3xl bg-ivory rounded-full p-4 px-6">
      <img :src="animatedLogo" class="w-32" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import img from '@/assets/logo_timelink.png'
import animatedLogo from './../assets/timelink_signet_5fRGB_animated.svg'
import timelinkStoresService from '@/services/timelink-stores.service'

const test = ref(false)
const model = defineModel({ default: false, type: Boolean })
const props = defineProps({
  fullPage: { type: Boolean, default: false },
  overAll: { type: Boolean, default: false },
  delay: { type: Number, default: 500 }
})
// const visible =
const timeoutHandler = ref(null)
const runningDelay = ref(true)
watch(
  () => model.value,
  (newVal) => {
    if (newVal) {
      runningDelay.value = true
      timeoutHandler.value = setTimeout(() => {
        runningDelay.value = false
      }, props.delay ?? 500)
    } else {
      clearTimeout(timeoutHandler.value)
      runningDelay.value = true
    }
  }
)
const showLoading = computed(() => {
  return model.value && !runningDelay.value
})
</script>
