<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
    <div class="py-3 mb-3 flex w-full justify-between items-center">
      <h1 class="text-2xl font-bold">
        {{ $t('edit_user') }}
      </h1>
    </div>

    <div class="space-y-8 divide-y divide-gray-900/10 pb-16">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7">
            {{ $t('user_edit_header') }}
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            {{ $t('user_edit_description') }}
          </p>
        </div>

        <form
          class="bg-white ring-0 ring-mossgray-200 sm:rounded-3xl md:col-span-2"
          @submit.prevent="save"
        >
          <div class="px-4 py-6 sm:p-8">
            <div
              class="rounded-lg bg-mossgray-100 p-4 mb-5"
              v-if="authUserStore.user.id == this.$route.params.user_id"
            >
              <div class="flex align-center">
                <div class="flex-shrink-0">
                  <font-awesome-icon :icon="['fa-kit', 'tl-info']" class="text-mossgray" />
                </div>
                <div class="ml-3">
                  <p class="text-mossgray leading-0">{{ $t('errors.edit_own_user') }}</p>
                </div>
              </div>
            </div>

            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label
                  for="first_name"
                  class="block text-sm font-medium leading-6 text-gray-900 label-required"
                  >{{ $t('first_name') }}</label
                >
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="text"
                    name="first_name"
                    id="first_name"
                    autocomplete="first_name"
                    v-model="user.first_name"
                    class="input w-full"
                    required
                  />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="last_name"
                  class="block text-sm font-medium leading-6 text-gray-900 label-required"
                  >{{ $t('last_name') }}</label
                >
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="text"
                    name="last_name"
                    id="last_name"
                    autocomplete="last_name"
                    v-model="user.last_name"
                    required
                    class="input w-full"
                  />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900 label-required"
                  >{{ $t('email') }}</label
                >
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="email"
                    name="email"
                    id="email"
                    autocomplete="email"
                    v-model="user.email"
                    required
                    class="input w-full"
                  />
                </div>
              </div>

              <div class="sm:col-span-3 sm:col-start-1">
                <label for="active" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('active')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="checkbox"
                    name="active"
                    id="active"
                    autocomplete="Off"
                    v-model="user.active"
                    class="checkbox"
                  />
                </div>
              </div>
              <div class="sm:col-span-3 sm:col-end-8">
                <label for="admin" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('admin')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="checkbox"
                    name="admin"
                    id="admin"
                    autocomplete="Off"
                    v-model="isAdmin"
                    class="checkbox"
                  />
                </div>
              </div>

              <div class="sm:col-span-3" v-if="!companyStore.company.force_oauth">
                <label for="password" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('password')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="password"
                    name="password"
                    id="password"
                    autocomplete="new-password"
                    v-model="user.password"
                    class="input w-full"
                  />
                </div>
              </div>
              <div class="sm:col-span-3" v-if="!companyStore.company.force_oauth">
                <label
                  for="password_confirmation"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t('password_confirmation') }}</label
                >
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    autocomplete="new-password"
                    v-model="user.password_confirmation"
                    class="input w-full"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-6"
            :class="{
              'justify-end': companyStore.company.pull_provider,
              'justify-between': !companyStore.company.pull_provider
            }"
          >
            <BaseButton
              class="button-red"
              type="button"
              @click="showDeleteModal = true"
              v-if="!companyStore.company.pull_provider"
              :disabled="!canEdit"
            >
              {{ $t('delete') }}
            </BaseButton>
            <button
              v-if="!companyStore.company.pull_provider"
              type="submit"
              class="button-mossgray"
              :disabled="!canEdit"
            >
              {{ $t('save') }}
            </button>
            <div v-else>
              {{ $t('managed_through') }}
              <span class="font-medium">{{ companyStore.company.pull_provider }}</span
              >.
            </div>
          </div>
        </form>
      </div>
    </div>
    <BaseModal
      v-model="showDeleteModal"
      @close-modal="showDeleteModal = false"
      v-model:loading="deleteIsLoading"
    >
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.user') }}
        </span>
      </div>

      <template #footer>
        <div class="w-full flex justify-end gap-4">
          <BaseButton
            class="button-gray"
            type="button"
            @click="showDeleteModal = false"
            ref="cancelDeleteModal"
          >
            {{ $t('cancel') }}</BaseButton
          >
          <BaseButton
            class="button-red"
            :disabled="!timer || timer.seconds > 0"
            @click="this.delete"
            ><span class="w-6" v-if="timer && timer.seconds > 0"> ({{ timer.seconds }}) </span
            >{{ $t('delete') }}</BaseButton
          >
        </div>
      </template>
    </BaseModal>
  </TemplateComponent>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import apiService from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import BaseModal from '@/components/modals/BaseModal.vue'
import { useTimer } from 'vue-timer-hook'
import logger from '@/services/logger.service'
import { AxiosError } from 'axios'
import { captureException, captureMessage } from '@sentry/vue'
import { useAlertsStore } from '@/stores/alerts'
import TemplateComponent from '@/components/settings/TemplateComponent.vue'

export default {
  components: {
    BaseButton,
    BaseModal,
    LoadingSpinner,
    TemplateComponent
  },
  setup() {
    const timelinkService = timelinkStoresService
    const companyStore = useCompanyStore()
    const authUserStore = useAuthUserStore()
    return { timelinkService, companyStore, authUserStore }
  },
  data() {
    return {
      canEdit: false,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        active: true,
        password: null,
        password_confirmation: null
      },
      isLoading: true,
      showDeleteModal: false,
      timer: null,
      deleteIsLoading: false
    }
  },
  mounted() {
    this.canEdit =
      useAuthUserStore().user.admin >= 9 &&
      !useCompanyStore().company.pull_provider &&
      useAuthUserStore().user.id != this.$route.params.user_id
    this.fetch()
  },
  watch: {
    showDeleteModal(newVal) {
      if (newVal) {
        this.timer = this.timer = useTimer(new Date(Date.now() + 2 * 1000), true)
        this.$nextTick(() => {
          this.$refs.cancelDeleteModal?.focus()
        })
      }
    }
  },
  computed: {
    isAdmin: {
      get() {
        return this.user.admin == 9
      },
      set(value) {
        if (value) {
          this.user.admin = 9
        } else {
          this.user.admin = 0
        }
      }
    }
  },
  methods: {
    async fetch() {
      this.isLoading = true
      try {
        let data = await apiService.fetchId(
          import.meta.env.VITE_API_URL + '/api/v1/users',
          this.$route.params.user_id,
          {}
        )
        this.user = data.data
      } catch (error) {
        if (error?.response?.status == 404) {
          useAlertsStore().error(this.$t('errors.user.not_found'))
          this.$router.push({ name: 'UsersList' })
          return
        } else if (error?.response?.status == 403) {
          useAlertsStore().error(this.$t('errors.no_permissions'))
          this.$router.push({ name: 'UsersList' })
          return
        } else {
          logger.error(error)
        }
        // captureException(error)
      }
      this.isLoading = false
    },
    async save() {
      if (this.companyStore.company.pull_provider || useAuthUserStore().user.admin < 9) {
        useAlertsStore().error(this.$t('errors.no_permissions'))
        return
      }
      this.isLoading = true
      try {
        let response = await apiService.update(
          import.meta.env.VITE_API_URL + '/api/v1/users',
          this.$route.params.user_id,
          {
            ...this.user
          }
        )
        if (response.success) {
          useAlertsStore().successfullySaved()
          this.user = { ...response.data }
        } else {
          useAlertsStore().error(this.$t('errors.ups'))
          captureMessage('Save user was not successfully.', [response])
        }
      } catch (error) {
        if (
          error?.response?.status == 403 &&
          error.response.data.message == 'You are not subscribed!'
        ) {
          // TODO: Add better error handling!
          useAlertsStore().error(this.$t('errors.no_subscription'))
        } else if (apiService.checkIfServerError(error)) {
          //
        } else {
          useAlertsStore().error(this.$t('errors.ups'))
          captureException(error)
        }
      }
      this.isLoading = false
    },
    async delete() {
      if (useCompanyStore().company.pull_provider || useAuthUserStore().user.admin < 9) {
        useAlertsStore().error(this.$t('errors.no_permissions'))
        return
      }
      this.deleteIsLoading = true
      try {
        let data = await apiService.delete(
          import.meta.env.VITE_API_URL + '/api/v1/users',
          this.$route.params.user_id,
          {},
          () => {
            // TODO: Add better handling
          }
        )
        if (data.success) {
          this.$router.push({
            name: 'UsersList'
          })
        } else {
          useAlertsStore().error(this.$t('errors.ups'))
        }
      } catch (error) {
        if (
          error.response.status == 403 &&
          error.response.data.message == this.$t('errors.no_subscription')
        ) {
          // TODO: Add better error handling!
          alert(this.$t('errors.no_subscription'))
          this.deleteIsLoading = false
        }
      }
    }
  }
}
</script>
