import { defineStore, acceptHMRUpdate } from 'pinia'
import { useTimeEntryStore } from './timeEntry'
import { useAuthUserStore } from './auth-user'
import { useProjectsStore } from './projects'
import timelinkStoresService from '@/services/timelink-stores.service'
import idleService from '@/services/idle.service'

export const usePanelStore = defineStore('panel', {
  /**
   *
   * @returns {{
   * view: ?('lock'|'unlock'|'start'|'selectProject'|'selectClient'|'selectService'|'recording'),
   * page: ?number,
   * wizardFinished: boolean,
   * lastChange, ?number,
   * previous: {
   *    view: ?('lock'|'unlock'|'start'|'selectProject'|'selectClient'|'selectService'|'recording'),
   *    page: ?number,
   *    wizardFinished: boolean,
   * }
   * }}
   */
  state: () => ({
    view: 'start',
    page: 0,
    wizardFinished: true,
    lastChange: null,
    previous: {
      view: null,
      page: null,
      wizardFinished: null
    }
  }),
  getters: {},
  actions: {
    /**
     *
     * @param {?('lock'|'unlock'|'start'|'selectProject'|'selectClient'|'selectService'|'recording')} view
     * @param {?number} page
     * @param {?boolean} finished
     * @returns
     */
    async updateView(view, page = null, finished = undefined) {
      if (typeof finished == 'undefined') {
        finished = this.wizardFinished
      }
      if (this.view == 'lock' && view != 'unlock') {
        this.savePrevious(view, page, finished)
      }
      if (view == 'lock') {
        this.savePrevious()
        this.$patch({
          view: 'lock',
          page: 0,
          wizardFinished: true,
          lastChange: Date.now()
        })
      } else if (view == 'unlock') {
        this.updateView(this.previous.view, this.previous.page, this.previous.wizardFinished)
        return
      } else if (view == 'recording') {
        page = useTimeEntryStore().activeTimeEntry
        let activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
        // console.log(
        //   'panel #1',
        //   activeTimeEntry,
        //   typeof useTimeEntryStore().activeTimeEntry,
        //   useTimeEntryStore().activeTimeEntry,
        //   useTimeEntryStore().getActiveTimeEntry
        // )
        // console.log('stop')
        if (activeTimeEntry == null) {
          activeTimeEntry = await new Promise((resolve) =>
            timelinkStoresService.setOrRenewTimeout(
              'panelStore',
              'resolveActiveTimeEntry',
              () => {
                return resolve(useTimeEntryStore().getActiveTimeEntry)
              },
              200
            )
          )
          // console.log(
          //   'panel #2',
          //   activeTimeEntry,
          //   useTimeEntryStore().activeTimeEntry,
          //   useTimeEntryStore().getActiveTimeEntry
          // )
        }
        if (!activeTimeEntry?.client_id) {
          if (this.view == 'selectClient') {
            return
          }
          let page = useAuthUserStore().lastUsed('clients').length == 0 ? 0 : null
          this.$patch({
            view: 'selectClient',
            page: page,
            wizardFinished: finished,
            lastChange: Date.now()
          })
        } else if (
          !activeTimeEntry?.project_id &&
          (!activeTimeEntry?.service_id ||
            useTimeEntryStore().requiredFields.includes('project_id'))
        ) {
          if (this.view == 'selectProject') {
            return
          }
          let page = useAuthUserStore().lastUsed('projects').length == 0 ? 0 : null

          this.$patch({
            view: 'selectProject',
            page: page,
            wizardFinished: finished,
            lastChange: Date.now()
          })
        } else if (!activeTimeEntry?.service_id) {
          if (this.view == 'selectService') {
            return
          }
          let page = useAuthUserStore().lastUsed('services').length == 0 ? 0 : null

          this.$patch({
            view: 'selectService',
            page: page,
            wizardFinished: finished,
            lastChange: Date.now()
          })
        } else {
          this.$patch({
            view: 'recording',
            page: page,
            wizardFinished: finished,
            lastChange: Date.now()
          })
        }
      } else if (view == 'start' && useTimeEntryStore().getActiveTimeEntry) {
        await this.updateView('recording')
        return
      } else if (view == 'selectClient') {
        if (!useTimeEntryStore().getActiveTimeEntry) {
          this.$patch({
            view: 'start',
            page: null,
            wizardFinished: true,
            lastChange: Date.now()
          })
        } else {
          this.$patch({
            view: view,
            page: useAuthUserStore().lastUsed('clients').length == 0 && page == null ? 0 : page,
            wizardFinished: finished,
            lastChange: Date.now()
          })
        }
      } else if (view == 'selectProject') {
        if (!useTimeEntryStore().getActiveTimeEntry) {
          this.$patch({
            view: 'start',
            page: null,
            lastChange: Date.now()
          })
        } else {
          this.$patch({
            view: view,
            page:
              (useAuthUserStore().lastUsed('projects').length == 0 ||
                useProjectsStore().getLastUsed(useTimeEntryStore().getActiveTimeEntry.client_id)
                  .length == 0) &&
              page == null
                ? 0
                : page,
            wizardFinished: finished,
            lastChange: Date.now()
          })
        }
      } else if (view == 'selectService') {
        if (!useTimeEntryStore().getActiveTimeEntry) {
          this.$patch({
            view: 'start',
            page: null,
            wizardFinished: true,
            lastChange: Date.now()
          })
        } else {
          this.$patch({
            view: view,
            page: useAuthUserStore().lastUsed('services').length == 0 && page == null ? 0 : page,
            wizardFinished: finished,
            lastChange: Date.now()
          })
        }
      } else {
        this.$patch({
          view: view,
          page: page,
          wizardFinished: finished,
          lastChange: Date.now()
        })
      }
    },
    updatePage(page) {
      if (page == undefined || page == null || page >= 0) {
        this.$patch({
          page: page,
          lastChange: Date.now()
        })
      } else if (page < 0) {
        this.$patch({
          page: 0,
          lastChange: Date.now()
        })
      }
    },
    updateWizard(finished = false) {
      this.$patch({
        finished: finished,
        lastChange: Date.now()
      })
    },
    savePrevious(view, page, wizardFinished) {
      this.previous = {
        view: view ?? this.view,
        page: page ?? this.page,
        wizardFinished: wizardFinished ?? this.wizardFinished
      }
    },
    lockIfUnlocked(manual = false) {
      if (!manual && !idleService.isStarted()) {
        return false
      }
      if (!manual && !(useAuthUserStore()?.user?.settings?.autoLockPanel ?? true)) {
        return false
      }
      if (!manual && idleService.screenState() == 'unlocked') {
        return false
      }
      if (this.view != 'lock') {
        this.updateView('lock')
        return true
      }
      return false
    },
    unlockIfLocked(manual = false) {
      if (!manual && !idleService.isStarted()) {
        return false
      }
      // disabled for savety reasons.
      // if (!manual && !(useAuthUserStore()?.user?.settings?.autoLockPanel ?? true)) {
      //   return false
      // }
      if (!manual && idleService.screenState() == 'locked') {
        return false
      }
      if (this.view == 'lock') {
        this.updateView('unlock')
        return true
      }
      return false
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePanelStore, import.meta.hot))
}
