import { acceptHMRUpdate, defineStore } from 'pinia'

export const useApiStore = defineStore('api', {
  /**
   *
   * @returns {{
   * connectionError: boolean,
   * needQueue: boolean,
   * createEntry: {
   * timeEntries: Array<string>,
   * clients: Array<string>,
   * users: Array<string>,
   * projects: Array<string>,
   * services: Array<string>,
   * },
   * updateEntry: {
   * timeEntries: Array<string>,
   * clients: Array<string>,
   * users: Array<string>,
   * projects: Array<string>,
   * services: Array<string>,
   * notifications: Array<string>,
   * }
   * deleteEntry: {
   * timeEntries: Array<string>,
   * clients: Array<string>,
   * users: Array<string>,
   * projects: Array<string>,
   * services: Array<string>,
   * notifications: Array<string>,
   * }
   * }
   */
  state: () => {
    return {
      connectionError: false,
      needQueue: false,
      createEntry: {
        timeEntries: [],
        clients: [],
        users: [],
        projects: [],
        services: []
      },
      updateEntry: {
        timeEntries: [],
        clients: [],
        users: [],
        projects: [],
        services: [],
        notifications: []
      },
      deleteEntry: {
        timeEntries: [],
        clients: [],
        users: [],
        projects: [],
        services: [],
        notifications: []
      }
    }
  },
  persist: true,
  actions: {
    addIdToCreate(type, id) {
      this.removeIdFromCreate(type, id)
      this.createEntry[type].push(id)
    },
    addIdToUpdate(type, id) {
      this.removeIdFromUpdate(type, id)
      this.updateEntry[type].push(id)
    },
    addIdToDelete(type, id) {
      this.removeIdFromDelete(type, id)
      this.deleteEntry[type].push(id)
    },
    removeIdFromCreate(type, id) {
      this.createEntry[type] = this.createEntry[type].filter((item) => item != id)
    },
    removeIdFromUpdate(type, id) {
      this.updateEntry[type] = this.updateEntry[type].filter((item) => item != id)
    },
    removeIdFromDelete(type, id) {
      this.deleteEntry[type] = this.deleteEntry[type].filter((item) => item != id)
    },
    removeIdEverywhere(type, id) {
      this.removeIdFromCreate(type, id)
      this.removeIdFromUpdate(type, id)
      this.removeIdFromDelete(type, id)
    },
    removeAllIds() {
      this.createEntry = {
        timeEntries: [],
        clients: [],
        users: [],
        projects: [],
        services: []
      }
      this.updateEntry = {
        timeEntries: [],
        clients: [],
        users: [],
        projects: [],
        services: [],
        notifications: []
      }
      this.deleteEntry = {
        timeEntries: [],
        clients: [],
        users: [],
        projects: [],
        services: [],
        notifications: []
      }
    },
    hasId(type, id) {
      return (
        this.createEntry[type].includes(id) ||
        this.updateEntry[type].includes(id) ||
        this.deleteEntry[type].includes(id)
      )
    },
    createHasId(type, id) {
      return this.createEntry[type].includes(id)
    },
    updateHasId(type, id) {
      return this.updateEntry[type].includes(id)
    },
    deleteHasId(type, id) {
      return this.deleteEntry[type].includes(id)
    },
    checkIfQueueIsNeeded() {
      let someItems = false
      Object.entries(this.updateEntry).forEach((item) => {
        if (this.updateEntry[item[0]].length > 0) {
          someItems = true
        }
      })
      if (someItems) {
        this.needQueue = someItems
        return
      }
      Object.entries(this.createEntry).forEach((item) => {
        if (this.createEntry[item[0]].length > 0) {
          someItems = true
        }
      })
      if (someItems) {
        this.needQueue = someItems
        return
      }
      Object.entries(this.deleteEntry).forEach((item) => {
        if (this.deleteEntry[item[0]].length > 0) {
          someItems = true
        }
      })
      this.needQueue = someItems
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApiStore, import.meta.hot))
}
