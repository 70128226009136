<template>
  <div class="py-3 mx-6 mb-2 border-b border-mossgray-200 flex justify-end space-x-2">
    <ActiveTrackingComponent />
    <BaseButton class="button-apricot" @click="createTimeEntryModal = true">
      <font-awesome-icon :icon="['fa-kit', 'tl-plus']" class="text-lg mr-2" />
      {{ $t('calendar.new_entry') }}
    </BaseButton>
    <div class="relative">
      <BaseButton
        type="button"
        class="button-white !px-2 aspect-square justify-center"
        @click="
          () => {
            return
          }
        "
        v-tippy="{ content: $t('tracking.button.description.calender_help') }"
      >
        <font-awesome-icon :icon="['fa-kit', 'tl-question']" class="text-lg" />
      </BaseButton>
    </div>
    <NotificationCenter />
    <slot></slot>
  </div>
  <TimeEntryModalComponent v-model="createTimeEntryModal" v-model:item="timeEntryModalModel" />
</template>

<script setup>
import { ref, watch } from 'vue'
import ActiveTrackingComponent from './ActiveTrackingComponent.vue'
import TimeEntryModalComponent from '@/components/modals/TimeEntryModalComponent.vue'
import BaseButton from '@/components/general/BaseButton.vue'
import NotificationCenter from '@/components/NotificationCenter.vue'
import { useTimeEntryStore } from '@/stores/timeEntry'

const timeEntryStore = useTimeEntryStore()

const createTimeEntryModal = ref(false)
const timeEntryModalModel = ref(timeEntryStore.newEntry())
watch(timeEntryModalModel, (newVal) => {
  if (newVal == null) {
    timeEntryModalModel.value = timeEntryStore.newEntry()
  }
})
</script>
