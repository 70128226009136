import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import deckService from '@/services/deck.service'
import { usePanelStore } from '@/stores/panel'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} page
 */
function viewLock(panel, arr) {
  const dims = deckService.getDimensions()

  let id = dims.columns + Math.floor(dims.columns / 2)
  for (let i = 0; i < dims.rows * dims.columns; i++) {
    if (id == i) {
      continue
    }
    panel.setId(arr, i, {
      type: 'text',
      text: '',
      bgColor: '#333333',
      deckBgColor: deckService.isTimepanel() ? '#333333' : '#000000'
    })
  }
  panel.setId(arr, id, {
    type: 'image',
    text: 'Gesperrt',
    bgColor: '#333333',
    deckBgColor: deckService.isTimepanel() ? '#333333' : '#000000',
    color: '#fff',
    image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-lock' })),
    callback: () => {
      return () => {
        usePanelStore().unlockIfLocked()
      }
    }
  })
}

export default viewLock
