import axios from 'axios'

class UserService {
  async search(text) {
    let response = await axios.get(import.meta.env.VITE_API_URL + '/api/v1/users', { search: text })
    return response.data.data
  }
}

export default new UserService()
