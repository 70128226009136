import { captureException } from '@sentry/vue'
import axios from 'axios'

class AuthService {
  async login(user) {
    let request = await axios.post(import.meta.env.VITE_API_URL + '/api/v1/login', {
      email: user.username,
      password: user.password
    })

    return request.data
  }
  async checkLoginStatus() {
    try {
      let result = await axios.get(import.meta.env.VITE_API_URL + '/api/v1/me', {
        'Content-Type': 'application/json',
        validateStatus: () => {
          return true
        }
      })
      if (result?.data?.message == 'Unauthorized' || result?.data?.status == 401) {
        return { status: false, data: null, error: false }
      }
      return { status: true, data: result.data, error: false }
    } catch (error) {
      captureException(error)
      if (
        Object.hasOwn(error, 'response') &&
        Object.hasOwn(error.response, 'data') &&
        error.response.status == 401
      ) {
        return { status: false, data: null, error: false }
      }
      return { status: false, data: null, error: true }
    }
  }
}

export default new AuthService()
