<template>
  <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
  <div class="flex flex-col pb-6 px-6 site-background min-h-screen h-full basis-full">
    <div class="py-3 mb-3 flex w-full items-center">
      <h1 class="text-2xl font-bold mr-auto">
        {{ $t('user', 10) }}
      </h1>
      <div class="flex space-x-2">
        <input
          type="text"
          id="search"
          name="search"
          v-model="search"
          class="input"
          :placeholder="$t('search')"
        />
      </div>
      <div class="flex flex-row space-x-4 pl-3">
        <span
          v-if="saveComplete"
          class="text-green-500 pb-1 border-b-4 border-green-500 text-lg font-bold md:ml-4"
          >{{ $t('success_message') }}</span
        >
        <BaseButton
          class="button-mossgray"
          @click="showCreateModal = true"
          :disabled="companyStore.company.pull_provider"
        >
          <font-awesome-icon :icon="['fa-kit', 'tl-plus']" class="mr-2 text-lg" /> {{ $t('new_user') }}
        </BaseButton>
      </div>
    </div>

    <div class="overflow-x-auto">
      <div class="flex flex-row space-x-4 justify-end pt-1 pr-1 mb-2">
        <div class="flex flex-row space-x-2 ">
          <div class="flex items-center">
            <BaseSwitch
              v-model="hiddeDisabled"
              :class="[
                hiddeDisabled ? 'bg-lime' : 'bg-gray-200',
                'relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-mossgray focus:ring-offset-2'
              ]"
            >
              <span
                aria-hidden="true"
                :class="[
                  hiddeDisabled ? 'translate-x-4' : 'translate-x-0',
                  'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                ]"
              />
            </BaseSwitch>
          </div>
          <label for="hiddeDisabled"
            >{{ $t('Deaktivierte Benutzer ausblenden') }}
          </label>
        </div>
      </div>

      <!-- TODO: Translation -->
      <Alert v-if="users.length == 0">Keine Benutzer vorhanden.</Alert>

      <div class="border border-mossgray-300 rounded-lg" v-if="users.length > 0">
        <div class="divide-y divide-mossray-300 bg-white rounded-t-lg">
          <template v-for="user in users" :key="user.id">
            <UsersListElement :user="user" />
          </template>
        </div>
      
          <div
            class="flex items-center justify-between border-t border-mossgray-200 bg-white px-4 py-3 sm:px-6 rounded-b-lg"
          >
            <div class="flex flex-1 justify-between sm:hidden">
              <a
                href="#"
                class="button button-gray"
                >{{ $t('previous') }}</a
              >
              <a
                href="#"
                class="button button-gray"
                >{{ $t('next') }}</a
              >
            </div>
            <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p class="text-sm text-gray-700">
                  {{ $t('pagination_showing') }}
                  {{ ' ' }}
                  <span class="font-medium">{{ pagination.from }}</span>
                  {{ ' ' }}
                  {{ $t('pagination_to') }}
                  {{ ' ' }}
                  <span class="font-medium">{{ pagination.to }}</span>
                  {{ ' ' }}
                  {{ $t('pagination_of') }}
                  {{ ' ' }}
                  <span class="font-medium">{{ pagination.total }}</span>
                  {{ ' ' }}
                  {{ $t('pagination_total') }}
                </p>
              </div>
              <div>
                <input
                  type="number"
                  class="input"
                  min="1"
                  :max="this.pagination.last_page"
                  v-model="this.page"
                  @change="fetch"
                />
              </div>
              <div>
                <nav
                  class="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    type="button"
                    @click="previous"
                    class="relative inline-flex items-center rounded-l-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span class="sr-only">{{ $t('previous') }}</span>
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-left']" fixed-width />
                  </button>

                  <template v-for="page in this.pagination.links" :key="page.label">
                    <button
                      :class="{
                        'relative z-10 inline-flex items-center bg-mossgray px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mossgray':
                          this.page == page.label,
                        'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0':
                          this.page != page.label
                      }"
                      type="button"
                      :disabled="page.label == '...'"
                      @click="gotoPage(parseInt(page.label))"
                    >
                      {{ page.label }}
                    </button>
                  </template>

                  <button
                    type="button"
                    @click="next"
                    class="relative inline-flex items-center rounded-r-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span class="sr-only">{{ $t('next') }}</span>
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-right']" fixed-width />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
    </div>
    <UsersCreate v-model="showCreateModal" />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import apiUser from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import timelinkStoresUser from '@/services/timelink-stores.service'
import UsersCreate from '@/components/settings/UsersCreate.vue'
import { useCompanyStore } from '@/stores/company'
import UsersListElement from '@/components/settings/UsersListElement.vue'
import { useAuthUserStore } from '@/stores/auth-user'
import Alert from '@/components/general/AlertComponent.vue'
import { Switch as BaseSwitch } from '@headlessui/vue'

export default {
  // props: ['page'],
  components: {
    BaseSwitch,
    UsersCreate,
    BaseButton,
    LoadingSpinner,
    UsersListElement,
    Alert
  },
  setup() {
    const timelinkUser = timelinkStoresUser
    const companyStore = useCompanyStore()
    return { timelinkUser, companyStore }
  },
  data() {
    return {
      isLoading: true,
      users: [],
      saveComplete: null,
      // page: 1,
      limit: 10,
      pagination: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [],
        path: '',
        per_page: 10,
        to: 1,
        total: 1
      },
      showCreateModal: false,
      search: '',
      hiddeDisabled: true
    }
  },
  created() {},
  mounted() {
    this.fetch()
    this.$echo
      .private('company.' + useCompanyStore().company.id + '.admin')
      .listen('UserCreated', () => {
        this.fetch(true)
      })
      .listen('.user.created', () => {
        this.fetch(true)
      })
  },
  beforeUnmount() {
    this.unregisterEcho()
    this.$echo
      .private('company.' + useCompanyStore().company.id + '.admin')
      .stopListening('UserCreated')
      .stopListening('.user.created')
  },
  watch: {
    search() {
      this.timelinkUser.setOrRenewTimeout(
        'usersList',
        'search',
        () => {
          if (this.page != 1) {
            this.page = 1
          } else {
            this.fetch()
          }
        },
        300
      )
    },
    $route() {
      this.fetch()
    },
    showCreateModal(newVal) {
      if (!newVal) {
        this.fetch(true)
      }
    },
    hiddeDisabled() {
      this.fetch()
    }
  },
  computed: {
    page: {
      get() {
        return parseInt(this.$route.query.page ?? 1)
      },
      set(value) {
        value = parseInt(value)
        this.$router.replace({ name: 'UsersList', query: { ...this.$route.query, page: value } })
      }
    }
  },
  methods: {
    async fetch(withoutLoading = false) {
      if (!withoutLoading) {
        this.isLoading = true
      }
      this.unregisterEcho()
      try {
        let data = await apiUser.fetch(import.meta.env.VITE_API_URL + '/api/v1/users', {
          limit: this.limit,
          page: this.page,
          search: this.search,
          hiddeDisabled: this.hiddeDisabled ? 1 : 0,
          orders: [
            {
              column: 'first_name',
              direction: 'asc'
            },
            {
              column: 'last_name',
              direction: 'asc'
            },
            {
              column: 'email',
              direction: 'asc'
            }
          ]
        })
        this.pagination = { ...data.meta }
        this.pagination.links = this.pagination.links.filter(
          (item) => !item.label.includes('Previous') && !item.label.includes('Next')
        )
        this.users = data.data
        if (!withoutLoading) {
          this.isLoading = false
        }
        this.registerEcho()
      } catch (error) {
        if (error?.response?.status == 403) {
          this.$router.push({
            name: 'General'
          })
        } else {
          throw error
        }
      }
    },
    unregisterEcho() {
      if (useAuthUserStore().user.admin < 9) {
        return
      }
      this.$echo
        .private('company.' + useCompanyStore().company.id + '.admin')
        .stopListening('.user.deleted')
      // this.users.forEach((item) => {
      //   this.$echo.leave('user.' + item.id)
      // })
    },
    registerEcho() {
      if (useAuthUserStore().user.admin < 9) {
        return
      }
      this.$echo
        .private('company.' + useCompanyStore().company.id + '.admin')
        .listen('.user.deleted', (payload) => {
          if (this.users.find((item) => item.id == payload?.userId)) {
            this.fetch()
          }
        })
      // this.users.forEach((item) => {
      //   this.$echo
      //     .private('user.' + item.id)
      //     .listen('UserDeleted', () => {
      //       this.fetch()
      //     })
      //     .listen('.user.deleted', () => {
      //       this.fetch()
      //     })
      // })
    },
    next() {
      if (this.page >= this.pagination.last_page) {
        return
      }
      this.page += 1
      // this.fetch()
    },
    previous() {
      if (this.page <= 1) {
        return
      }
      this.page -= 1
      // this.fetch()
    },
    gotoPage(page = 1) {
      if (page < 1) {
        this.page = 1
        // this.$router.replace({ name: 'UsersList', query: { ...this.$route.query, page: 1 } })
      } else if (page > this.pagination.last_page) {
        this.page = this.pagination.last_page
        // this.$router.replace({
        //   name: 'UsersList',
        //   query: { ...this.$route.query, page: this.pagination.last_page }
        // })
      } else {
        this.page = page
        // this.$router.replace({ name: 'UsersList', query: { ...this.$route.query, page: page } })
      }
      // this.fetch()
    }
  }
}
</script>
