<template>
  <label for="at_date" class="input-label" v-if="!noLabel">
    <slot name="label">
      {{ $t('timeEntries.start') }}
    </slot>
  </label>

  <div
    class="flex w-full max-w-sm rounded-full ring-1 ring-mossgray-200 divide-x divide-mossgray-200 focus-within:ring-2 focus-within:ring-mossgray"
  >
    <input
      v-if="!withoutDate"
      type="date"
      id="at_date"
      name="at_date"
      v-model="at_date"
      class="pl-4 basis-5/12 rounded-l-full border-0 focus:outline-none focus:ring-0 text-sm leading-none"
      :class="{
        'basis-5/12': $slots.button && !withoutTime,
        'basis-10/12': $slots.button && withoutTime,
        'basis-6/12': !$slots.button
      }"
      ref="date_input"
    />
    <input
      v-if="!withoutTime"
      id="at_time"
      name="at_time"
      v-model="at_time"
      autocomplete="off"
      type="text"
      maxlength="8"
      @focus="focusTimeInput = true"
      @blur="focusTimeInput = false"
      class="basis-5/12 min-w-16 rounded-none border-0 focus:outline-none focus:ring-0 text-sm leading-none"
      :class="{
        'rounded-none min-w-16 basis-5/12': $slots.button,
        'rounded-r-full min-w-16 basis-6/12': !$slots.button
      }"
      ref="time_input"
    />
    <slot
      name="button"
      :classes="'basis-2/12 rounded-r-full border-0 cursor-pointer focus:outline-none focus:ring-0 focus:bg-mossgray focus:text-white disabled:text-mossgray-500 disabled:cursor-auto'"
    ></slot>
  </div>
  <div class="text-sm text-red-500 leading-4">
    <span v-show="!atTimeInputValid">
      {{ $t('timeEntries.wrong_input') }}
    </span>
  </div>
</template>

<script>
import datetime from '@/lib/datetime'

export default {
  components: {},
  props: ['modelValue', 'noLabel', 'allowNullable', 'withoutTime', 'withoutDate'],
  setup() {
    return {}
  },
  data() {
    return {
      focusTimeInput: false,
      atTimeInput: null,
      atTimeInputValid: true
    }
  },
  mounted() {
    this.updateTimeInputs()
  },
  computed: {
    at_date: {
      get() {
        if (this.allowNullable && this.modelValue == null) {
          return null
        }
        return datetime.getDate(this.modelValue)
      },
      set(newVal) {
        if (newVal.split('-')[0] < 2000) {
          return
        }
        this.$emit('update:modelValue', datetime.setDate(this.modelValue, newVal))
      }
    },
    at_time: {
      get() {
        if (this.withoutTime) {
          return '00:00:00'
        }
        // return '00:00:00'
        if (this.focusTimeInput || !this.atTimeInputValid) {
          return this.atTimeInput
        }
        if (this.allowNullable && this.modelValue == null) {
          return null
        }
        return datetime.getTime(this.modelValue)
      },
      set(newVal) {
        if (newVal == '') {
          newVal = null
        }
        if (this.focusTimeInput) {
          this.atTimeInput = newVal
          if (newVal == null && this.allowNullable) {
            this.atTimeInputValid = true
          } else {
            this.atTimeInputValid = datetime.validateTimeInput(newVal)
          }
        } else {
          if (this.atTimeInputValid) {
            if (newVal == null && this.allowNullable) {
              this.atTimeInput = null
            } else {
              this.atTimeInput = datetime.setTime(this.modelValue, newVal)
            }
          }
        }
        if (this.atTimeInputValid) {
          this.$emit('update:modelValue', datetime.setTime(this.modelValue, newVal))
        }
      }
    }
  },
  watch: {
    focusTimeInput(newVal) {
      if (!newVal) {
        this.updateTimeInputs()
      }
    },
    modelValue() {
      this.updateTimeInputs()
    }
  },
  methods: {
    updateTimeInputs() {
      this.atTimeInput = this.at_time
    },
    focus() {
      this.focusDate()
    },
    focusDate() {
      this.$refs.date_input?.focus()
    },
    focusTime() {
      this.$refs.time_input?.focus()
    }
  }
}
</script>
