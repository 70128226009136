<template>
  <form @submit.prevent="save" ref="form">
    <BaseModal v-model="showModal" @close-modal="closeModal">
      <template v-slot:header> {{ $t('create_service') }} </template>
      <div class="px-4 py-6 sm:p-8">
        <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('name')
            }}</label>
            <div class="mt-2">
              <input :disabled="!canEdit" type="text" name="name" id="name" autocomplete="name" v-model="service.name"
                class="w-full input" ref="name_input" />
            </div>
          </div>

          <div class="sm:col-span-6">
            <label for="info" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('info')
            }}</label>
            <div class="mt-2">
              <textarea :disabled="!canEdit" type="text" name="info" id="info" autocomplete="Off" v-model="service.info"
                class="w-full textarea"></textarea>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="active" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('active')
            }}</label>
            <div class="mt-2">
              <input :disabled="!canEdit" type="checkbox" name="active" id="active" autocomplete="Off"
                v-model="service.active" class="checkbox" />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="color" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('color')
            }}</label>
            <div class="mt-2">
              <input :disabled="!canEdit" type="color" name="color" id="color" autocomplete="Off"
                v-model="service.color" class="input" />
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="flex items-center justify-end w-full space-x-4">
          <BaseButton class="button-gray" @click="closeModal" type="button">{{
            $t('cancel')
          }}</BaseButton>

          <BaseButton class="button-mossgray" type="submit" @click="save">
            {{ $t('create') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script setup>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import apiService from '@/services/api.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useAlertsStore } from '@/stores/alerts'
import { captureException } from '@sentry/vue'
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { $t } from '@/config/i18n'
import { useServicesStore } from '@/stores/services'

const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const alertsStore = useAlertsStore()
const servicesStore = useServicesStore()
const route = useRoute()
const isLoading = ref(false)

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const name_input = ref(null)
watch(
  () => showModal.value,
  async (newVal) => {
    if (newVal) {
      await nextTick()
      name_input.value?.focus()
    }
  }
)

function closeModal() {
  showModal.value = false
  service.value = {
    name: null,
    info: null,
    active: true,
    color: '#183F6A'
  }
}

// TODO: Add validation errors
const errors = ref({})

const service = ref({
  name: null,
  info: null,
  active: true,
  color: '#183F6A'
})
const canEdit = ref(true)

//TODO: Update to work with edit + escape key

async function save() {
  if (companyStore.company.pull_provider || authUserStore.user.admin < 5) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  isLoading.value = true
  try {
    let response = await apiService.create(
      import.meta.env.VITE_API_URL + '/api/v1/services',
      service.value
    )
    if (response.success) {
      if (response.data) {
        servicesStore.addOrUpdate(response.data)
      }
      showModal.value = false
      alertsStore.success($t('successfully.created_service', { serviceName: service.value.name }))
      service.value = {
        name: null,
        info: null,
        active: true,
        color: '#183F6A'
      }
    } else {
      alertsStore.error($t('errors.ups_create'))
    }
  } catch (error) {
    if (error.response.status == 403 && error.response.data.message == 'You are not subscribed!') {
      // TODO: Add better error handling!
      alertsStore.error($t('errors.no_subscription'))
    } else if (apiService.checkIfServerError(error)) {
      //
    } else if (error?.response?.status == 422) {
      errors.value = apiService.convertValidationErrors(error)
    } else {
      captureException(error)
      alertsStore.error($t('errors.ups'))
    }
  }
  isLoading.value = false
}
</script>
