import { usePanelStore } from '@/stores/panel'
import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from '@/services/timelink-stores.service'
import { nextTick, watch } from 'vue'
import { useStopwatch } from 'vue-timer-hook'

import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import desktopService from '@/services/desktop.service'
import router from '@/router/index'
import { $t } from '@/config/i18n'
// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { setActiveClient } from '../helper/clients'
import { setActiveProject } from '../helper/projects'
import { setActiveService } from '../helper/services'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} page
 */
function viewRecording(panel, arr) {
  const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

  // stop recording
  panel.setId(arr, 11, {
    type: 'image',
    text: $t('panel.stop_recoding'),
    image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-stop' })),
    callback: (item, panelArr, type) => {
      return () => {
        useTimeEntryStore().stopActiveEntry(null, false, true)
        usePanelStore().updateView('start')
      }
    },
    timeout: () => {
      return () => {}
    },
    interval: () => {
      return () => {}
    }
  })
  const stopwatch = useStopwatch(
    (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
    true
  )
  stopwatch.start()
  let daysText = ''
  if (stopwatch.days.value != 0) {
    daysText = stopwatch.days.value.toString().padStart(2, '0').toString() + ' d'
  }

  // <--- Start Time --->
  // Display days
  panel.setId(arr, 5, {
    type: 'text',
    test: stopwatch.days.value,
    text: daysText,
    callback: () => {
      return null
    },
    interval: (item) => {
      let watcher = null
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeInterval('panel', panel.getHandlerName(item))
            return
          }

          const stopwatch = useStopwatch(
            (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
            true
          )
          stopwatch.start()
          let newText = ''
          if (stopwatch.days.value != 0) {
            newText = stopwatch.days.value.toString().padStart(2, '0').toString() + ' d'
          }

          if (newText != item.text) {
            item.text = newText
          }

          timelinkStoresService.removeInterval('panel', panel.getHandlerName(item))
          timelinkStoresService.setOrRenewInterval('panel', panel.getHandlerName(item), () => {
            let newText = ''
            if (stopwatch.days.value != 0) {
              newText = stopwatch.days.value.toString().padStart(2, '0').toString() + ' d'
            }
            if (newText != item.text) {
              item.text = newText
            }
          })
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            panel.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(item),
        watcher(activeTimeEntry)
      )
      return () => {
        let newText = ''
        if (stopwatch.days.value != 0) {
          newText = stopwatch.days.value.toString().padStart(2, '0').toString() + ' d'
        }
        if (newText != item.text) {
          item.text = newText
        }
      }
    },
    intervalTime: 4000
  })

  panel.setId(arr, 6, {
    type: 'text',
    text: stopwatch.hours.value.toString().padStart(2, '0') + ' h',
    callback: () => {
      return null
    },
    interval: (item) => {
      let watcher = null
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeInterval('panel', panel.getHandlerName(item))
            return
          }

          const stopwatch = useStopwatch(
            (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
            true
          )
          stopwatch.start()
          item.text = stopwatch.hours.value.toString().padStart(2, '0').toString() + ' h'
          timelinkStoresService.removeInterval('panel', panel.getHandlerName(item))
          timelinkStoresService.setOrRenewInterval('panel', panel.getHandlerName(item), () => {
            item.text = stopwatch.hours.value.toString().padStart(2, '0').toString() + ' h'
          })
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            panel.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(item),
        watcher(activeTimeEntry)
      )
      return () => {
        item.text = stopwatch.hours.value.toString().padStart(2, '0').toString() + ' h'
      }
    }
  })
  panel.setId(arr, 7, {
    type: 'text',
    text: stopwatch.minutes.value.toString().padStart(2, '0') + ' m',
    callback: () => {
      return null
    },
    interval: (item) => {
      let watcher = null
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeInterval('panel', panel.getHandlerName(item))
            return
          }

          const stopwatch = useStopwatch(
            (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
            true
          )
          stopwatch.start()
          item.text = stopwatch.minutes.value.toString().padStart(2, '0').toString() + ' m'
          timelinkStoresService.removeInterval('panel', panel.getHandlerName(item))
          timelinkStoresService.setOrRenewInterval('panel', panel.getHandlerName(item), () => {
            item.text = stopwatch.minutes.value.toString().padStart(2, '0').toString() + ' m'
          })
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            panel.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(item),
        watcher(activeTimeEntry)
      )
      return () => {
        item.text = stopwatch.minutes.value.toString().padStart(2, '0').toString() + ' m'
      }
    }
  })
  panel.setId(arr, 8, {
    type: 'text',
    text: stopwatch.seconds.value.toString().padStart(2, '0') + ' s',
    callback: () => {
      return null
    },
    interval: (item) => {
      let watcher = null
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeInterval('panel', panel.getHandlerName(item))
            return
          }

          const stopwatch = useStopwatch(
            (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
            true
          )
          stopwatch.start()
          item.text = stopwatch.seconds.value.toString().padStart(2, '0').toString() + ' s'
          timelinkStoresService.removeInterval('panel', panel.getHandlerName(item))
          timelinkStoresService.setOrRenewInterval('panel', panel.getHandlerName(item), () => {
            item.text = stopwatch.seconds.value.toString().padStart(2, '0').toString() + ' s'
          })
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            panel.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(item),
        watcher(activeTimeEntry)
      )
      return () => {
        item.text = stopwatch.seconds.value.toString().padStart(2, '0').toString() + ' s'
      }
    }
  })

  panel.setId(arr, 9, {
    type: 'image',
    text: $t('duration'),
    image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-stopwatch' }))
  })
  // <--- End Time --->
  // <--- last Row --->
  // panel.setId(arr, 10, {
  //   type: 'image',
  //   text: $t('panel.start_new_tracking_with_same_data'),
  //   image: panel.getIcon(
  //     findIconDefinition({ prefix: 'fa-kit', iconName: 'light-arrows-rotate-circle-plus' })
  //   ),
  //   callback: (item, panelArr, type) => {
  //     return async () => {
  //       await useTimeEntryStore().stopAndCloneActiveTimeEntry(true)
  //       panel.reloadActualPanelView()
  //     }
  //   }
  // })
  panel.setId(arr, 12, {
    type: 'image',
    text: $t('panel.edit_description'),
    image:
      activeTimeEntry?.description == null ||
      activeTimeEntry?.description.trim() == '' ||
      activeTimeEntry?.description == ''
        ? panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-message-plus' }))
        : panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-message-pen' })),
    color:
      (activeTimeEntry?.description == null || activeTimeEntry?.description.trim() == '') &&
      useTimeEntryStore().requiredFields.includes('description')
        ? '#f00'
        : null,
    helper:
      activeTimeEntry?.description == null ||
      activeTimeEntry?.description.trim() == '' ||
      activeTimeEntry?.description == ''
        ? 'plus'
        : 'edit',
    init: (item) => {
      let watcher = null
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
            return
          }

          if (
            activeTimeEntry?.description == null ||
            activeTimeEntry?.description.trim() == '' ||
            activeTimeEntry?.description == ''
          ) {
            if (item.helper == 'edit') {
              item.image = panel.getIcon(
                findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-message-plus' })
              )
              item.helper = 'plus'
              item.color =
                (activeTimeEntry?.description == null ||
                  activeTimeEntry?.description.trim() == '') &&
                useTimeEntryStore().requiredFields.includes('description')
                  ? '#f00'
                  : null
            }
          } else {
            if (item.helper == 'plus') {
              item.image = panel.getIcon(
                findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-message-pen' })
              )
              item.helper = 'edit'
              item.color =
                (activeTimeEntry?.description == null ||
                  activeTimeEntry?.description.trim() == '') &&
                useTimeEntryStore().requiredFields.includes('description')
                  ? '#f00'
                  : null
            }
          }
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            panel.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(item),
        watcher(activeTimeEntry)
      )
    },
    callback: () => {
      return () => {
        if (desktopService.isDesktop()) {
          desktopService.setStatus('overlayWindowActive', true)
        } else {
          let event = new Event('open-active-time-entry-modal-panel')
          window.dispatchEvent(event)
        }
      }
    }
  })
  panel.setId(arr, 13, {
    type: 'image',
    text: useTimeEntryStore().interruptedId
      ? $t('panel.end_interruption')
      : $t('panel.interrupt_for_new_recording'),
    image: useTimeEntryStore().interruptedId
      ? panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-bolt-slash' }))
      : panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-bolt' })),
    helper: useTimeEntryStore().interruptedId != null,
    init: (item) => {
      let sub = useTimeEntryStore().$subscribe((mutation, state) => {
        if (item.helper && !state.interruptedId) {
          item.image = panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-bolt' }))
          item.helper = false
        } else if (!item.helper && state.interruptedId) {
          item.image = panel.getIcon(
            findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-bolt-slash' })
          )
          item.helper = false
        }
      })
      timelinkStoresService.setOrRenewWatcher('panel', panel.getHandlerName(item), sub)
    },
    callback: (item, panelArr, type) => {
      return async () => {
        if (useTimeEntryStore().interruptedId) {
          await useTimeEntryStore().stopAndGoBackToInterruptedEntry(true)
          usePanelStore().updateView('recording')
        } else {
          await useTimeEntryStore().stopAndSaveAsInterruptedWithNewEntry(true)
          usePanelStore().updateView('recording')
        }
      }
    }
  })
  // <--- End last row --->
  // <--- First row --->
  if (!activeTimeEntry?.client_id) {
    panel.setId(arr, 1, {
      type: 'text',
      text: $t('panel.select_customer'),
      image: null,
      callback: () => {
        return () => {
          usePanelStore().updateView('selectClient', null)
        }
      }
    })
  } else {
    setActiveClient(panel, arr, 1, activeTimeEntry)
  }
  if (!activeTimeEntry?.project_id) {
    panel.setId(arr, 2, {
      type: 'text',
      text: $t('panel.no_projects'),
      callback: () => {
        return () => {
          usePanelStore().updateView('selectProject', null)
        }
      },
      init: () => {
        watch(
          () => activeTimeEntry.project_id,
          async () => {
            await nextTick()
            setActiveProject(panel, arr, 2, activeTimeEntry)
          }
        )
      }
    })
  } else {
    setActiveProject(panel, arr, 2, activeTimeEntry)
  }
  if (!activeTimeEntry?.service_id) {
    panel.setId(arr, 3, {
      type: 'text',
      text: $t('panel.select_service'),
      callback: () => {
        return () => {
          usePanelStore().updateView('selectService', null)
        }
      }
    })
  } else {
    setActiveService(panel, arr, 3, activeTimeEntry)
  }
}

export default viewRecording
