import { defineStore, acceptHMRUpdate } from 'pinia'
import { useTimeEntryStore } from './timeEntry'
import { useAuthUserStore } from './auth-user'

export const useControlPanelStore = defineStore('control-panel', {
  state: () => ({
    actualView: 'index',
    selectedClient: null,
    selectedProject: null,
    selectedService: null,
    activeTimeEntry: null
  }),
  getters: {},
  actions: {
    update() {
      const authUserStore = useAuthUserStore()
      if (authUserStore.isAuthenticated) {
        const timeEntryStore = useTimeEntryStore()
        // timeEntryStore.loadLatest()
        this.activeTimeEntry = timeEntryStore.getActiveEntry
      } else {
        this.$reset()
      }
    }
  },
  watch: {
    activeTimeEntry(newVal) {
      if (newVal.ended_at) {
        this.update()
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useControlPanelStore, import.meta.hot))
}
