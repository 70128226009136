<template>
  <BaseModal v-model="showModal" @close-modal="closeModal" :stop-auto-focus="true">
    <template v-slot:header>
      <span v-show="timeEntry && timeEntry.id == 'tempNewEntry'">{{ $t('create_entry') }}</span><span
        v-show="timeEntry && timeEntry.id != 'tempNewEntry' && !isActiveTimeEntry">{{
          $t('edit_entry')
        }}</span>
      <span v-show="timeEntry && timeEntry.id != 'tempNewEntry' && isActiveTimeEntry">
        {{ $t('edit_recording') }}
      </span>
      <span v-if="isDev"> ( {{ this.timeEntry?.id }} ) </span>
    </template>
    <form @submit.prevent="" ref="timeEntryForm">
      <div class="px-4 py-6 sm:p-6 sm:pt-4">
        <div class="" v-if="this.timeEntry">
          <div class="grid grid-cols-2 gap-x-8 gap-y-4">
            <div>
              <DateTimeInput v-model="timeEntry.started_at" ref="form_started_at">
                <template #button="vProps">
                  <button :class="vProps.classes" type="button" @click="setToPreviousEntry" :disabled="!previousEntry ||
                    Date.parse(previousEntry.ended_at) == Date.parse(timeEntry.started_at)
                    " v-tippy="{
                      allowHTML: true,
                      content: previousEntry
                        ? '<div class=\'text-center\'>' +
                        $t('timeEntry.follow_up_on_previous') +
                        '<br>' +
                        (new Date(Date.parse(timeEntry.started_at)).toLocaleDateString() !=
                          new Date(Date.parse(previousEntry.ended_at)).toLocaleDateString()
                          ? new Date(Date.parse(previousEntry.ended_at)).toLocaleDateString() +
                          ' - '
                          : '') +
                        new Date(Date.parse(previousEntry.ended_at)).toLocaleTimeString() +
                        '</div>'
                        : ''
                    }">
                    <div class="px-3 pr-4">
                      <font-awesome-icon :icon="['fa-kit', 'tl-arrow-line-up']" size="lg" />
                    </div>
                  </button>
                </template>
              </DateTimeInput>
            </div>

            <div>
              <template v-if="!isActiveTimeEntry">
                <DateTimeInput v-model="timeEntry.ended_at" ref="form_ended_at">
                  <template #label>
                    {{ $t('timeEntries.end') }}
                  </template>
                  <template #button="vProps">
                    <button :class="vProps.classes" type="button" @click="setToFollowingEntry" :disabled="!followingEntry ||
                      Date.parse(followingEntry.started_at) == Date.parse(timeEntry.ended_at)
                      " v-tippy="{
                        content: followingEntry
                          ? '<div class=\'text-center\'>' +
                          $t('timeEntry.follow_up_on_next') +
                          '<br>' +
                          (new Date(Date.parse(timeEntry.ended_at)).toLocaleDateString() !=
                            new Date(Date.parse(followingEntry.started_at)).toLocaleDateString()
                            ? new Date(
                              Date.parse(followingEntry.started_at)
                            ).toLocaleDateString() + ' - '
                            : '') +
                          new Date(Date.parse(followingEntry.started_at)).toLocaleTimeString() +
                          '</div>'
                          : '',
                        allowHTML: true
                      }">
                      <div class="px-3 pr-4">
                        <font-awesome-icon :icon="['fa-kit', 'tl-arrow-line-down']" size="lg" />
                      </div>
                    </button>
                  </template>
                </DateTimeInput>
              </template>
            </div>

            <div>
              <div>
                <label for="duration" class="input-label">{{ $t('duration') }}</label>
              </div>

              <div class="relative inline-flex w-full">
                <template v-if="!isActiveTimeEntry">
                  <input type="text" id="duration" name="duration" :value="duration"
                    class="w-full input disabled:bg-mossgray-100 disabled:text-mossgray-800" />
                </template>
                <template v-else>
                  <div class="w-full leading-4 border-gray-200 input" v-if="stopwatch != null">
                    <span v-if="stopwatch.days > 0"><span
                        v-text="stopwatch.days.toString().padStart(2, '0')"></span>:</span>
                    <span v-text="stopwatch.hours.toString().padStart(2, '0')"></span>:<span
                      v-text="stopwatch.minutes.toString().padStart(2, '0')"></span>:<span
                      v-text="stopwatch.seconds.toString().padStart(2, '0')"></span>
                  </div>
                </template>
                <div class="absolute top-0 flex items-center h-full right-4">
                  <font-awesome-icon :icon="['fa-kit', 'tl-duration']" class="my-auto" />
                </div>
              </div>

              <span v-show="timeEntry && Date.parse(timeEntry?.started_at) >= Date.parse(timeEntry?.ended_at)
                " class="font-semibold leading-10 text-red-500">{{ $t('validation.error.end_before_start') }}</span>
            </div>

            <div class="col-start-1">
              <Combobox as="div" v-model="this.selectedProject" :nullable="!timeEntryStore.requiredFields.includes('project_id') &&
                !(timeEntry.billable ?? false)
                " immediate v-slot="{ open }">
                <ComboboxLabel class="input-label" :class="{
                  'label-required': timeEntryStore.requiredFields.includes('project_id')
                }">{{ $t('project', 1) }}</ComboboxLabel>
                <div class="relative">
                  <ComboboxInput
                    class="w-full pl-4 border-0 rounded-full ring-1 ring-inset ring-mossgray-200 focus:ring-2 focus:ring-inset focus:outline-none focus:ring-mossgray sm:text-sm"
                    @change="queryProject = $event.target.value" :displayValue="(project) => project?.name"
                    autocomplete="off" :required="timeEntryStore.requiredFields.includes('project_id')"
                    :placeholder="$t('search_dots')" @blur="resetQueryProject(open)" ref="form_project_id" />
                  <button type="button" @click="this.timeEntry.project_id = null"
                    class="absolute inset-y-0 flex items-center px-2 right-9 ring-inset focus:outline-none focus:ring-mossgray focus:ring-2"
                    v-show="this.timeEntry?.project_id">
                    <font-awesome-icon :icon="['fa-kit', 'tl-cross']" />
                  </button>
                  <ComboboxButton class="absolute inset-y-0 flex items-center px-2 right-2 rounded-r-md">
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-up-down']" />
                  </ComboboxButton>

                  <ComboboxOptions
                    class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-lg shadow-lg max-h-56 ring-1 ring-mossgray-200 ring-opacity-20 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="item in filteredProjects" :key="item.id" :value="item"
                      :disabled="!item.active" as="template" v-slot="{ active, selected }">
                      <ComboBoxListItem :active="active" :selected="selected" :item="item" />
                    </ComboboxOption>
                    <li class="relative py-2 pl-3 cursor-default select-none pr-9">
                      <div class="flex items-center text-center">
                        <span class="ml-3 truncate"> {{ $t('search_by_entering') }} </span>
                      </div>

                      <!-- TODO: can be removed? -->
                      <span class="absolute inset-y-0 right-0 flex items-center pr-4">
                        <!-- <CheckIcon class="w-5 h-5" aria-hidden="true" /> -->
                      </span>
                    </li>
                  </ComboboxOptions>
                </div>
              </Combobox>
            </div>
            <div>
              <Combobox as="div" v-model="this.selectedClient" :nullable="!timeEntryStore.requiredFields.includes('client_id') &&
                !(timeEntry.billable ?? false)
                " immediate v-slot="{ open }">
                <ComboboxLabel class="input-label" :class="{
                  'label-required':
                    timeEntryStore.requiredFields.includes('client_id') ||
                    (timeEntry.billable ?? false)
                }">{{ $t('client', 1) }}</ComboboxLabel>
                <div class="relative">
                  <ComboboxInput
                    class="w-full pl-4 bg-white border-0 rounded-full shadow-sm textmossgray ring-1 ring-inset ring-mossgray-200 focus:ring-2 focus:ring-inset focus:outline-none focus:ring-mossgray sm:text-sm"
                    @change="queryClient = $event.target.value" :display-value="(client) => client?.name"
                    autocomplete="off" :required="timeEntryStore.requiredFields.includes('client_id') ||
                      (timeEntry.billable ?? false)
                      " :placeholder="$t('search_dots')" @blur="resetQueryClient(open)" ref="form_client_id" />
                  <button type="button" @click="this.timeEntry.client_id = null"
                    class="absolute inset-y-0 flex items-center px-2 right-9 ring-inset focus:outline-none focus:ring-mossgray focus:ring-2"
                    v-show="this.timeEntry?.client_id">
                    <font-awesome-icon :icon="['fa-kit', 'tl-cross']" />
                  </button>
                  <ComboboxButton
                    class="absolute inset-y-0 flex items-center px-2 right-2 rounded-r-md focus:outline-none">
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-up-down']" />
                    <!-- <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" /> -->
                  </ComboboxButton>

                  <ComboboxOptions
                    class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-lg shadow-lg max-h-56 ring-1 ring-mossgray-200 ring-opacity-20 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="item in filteredClients" :key="item.id" :value="item"
                      :disabled="!item.active" as="template" v-slot="{ active, selected }">
                      <ComboBoxListItem :active="active" :selected="selected" :item="item" />
                    </ComboboxOption>
                    <li class="relative py-2 pl-3 cursor-default select-none pr-9">
                      <div class="flex items-center text-center">
                        <span class="ml-3 truncate"> {{ $t('search_by_entering') }} </span>
                      </div>

                      <!-- TODO: Can be removed? -->
                      <span class="absolute inset-y-0 right-0 flex items-center pr-4">
                        <!-- <CheckIcon class="w-5 h-5" aria-hidden="true" /> -->
                      </span>
                    </li>
                  </ComboboxOptions>
                </div>
              </Combobox>
            </div>

            <div>
              <Combobox as="div" v-model="this.selectedService" immediate :nullable="!timeEntryStore.requiredFields.includes('service_id') &&
                !(timeEntry.billable ?? false)
                " v-slot="{ open }">
                <ComboboxLabel class="input-label" :class="{
                  'label-required':
                    timeEntryStore.requiredFields.includes('service_id') ||
                    (timeEntry.billable ?? false)
                }">{{ $t('service', 1) }}</ComboboxLabel>
                <div class="relative">
                  <ComboboxInput
                    class="w-full pl-4 pr-12 text-gray-900 bg-white border-0 rounded-full ring-1 ring-inset ring-mossgray-200 focus:ring-2 focus:ring-inset focus:ring-mossgray focus:outline-none sm:text-sm"
                    @change="queryService = $event.target.value" :display-value="(service) => service?.name"
                    autocomplete="off" :required="timeEntryStore.requiredFields.includes('service_id') ||
                      (timeEntry.billable ?? false)
                      " :placeholder="$t('search_dots')" @blur="resetQueryService(open)" ref="form_service_id" />
                  <button type="button" @click="this.timeEntry.service_id = null"
                    class="absolute inset-y-0 flex items-center px-2 right-9 ring-inset focus:outline-none focus:ring-mossgray focus:ring-2"
                    v-show="this.timeEntry?.service_id">
                    <font-awesome-icon :icon="['fa-kit', 'tl-cross']" />
                  </button>
                  <ComboboxButton
                    class="absolute inset-y-0 flex items-center px-2 right-2 rounded-r-md focus:outline-none">
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-up-down']" />
                    <!-- <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" /> -->
                  </ComboboxButton>

                  <ComboboxOptions
                    class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-lg shadow-lg max-h-56 ring-1 ring-mossgray-200 ring-opacity-20 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="item in filteredServices" :key="item.id" :value="item"
                      :disabled="!item.active" as="template" v-slot="{ active, selected }">
                      <ComboBoxListItem :active="active" :selected="selected" :item="item" />
                    </ComboboxOption>
                    <li class="relative py-2 pl-3 cursor-default select-none pr-9">
                      <div class="flex items-center text-center">
                        <!-- <img
                                  :src="project.imageUrl"
                                  alt=""
                                  class="flex-shrink-0 w-6 h-6 rounded-full"
                                /> -->
                        <span class="ml-3 truncate"> {{ $t('search_by_entering') }} </span>
                      </div>

                      <span class="absolute inset-y-0 right-0 flex items-center pr-4">
                        <!-- <CheckIcon class="w-5 h-5" aria-hidden="true" /> -->
                      </span>
                    </li>
                  </ComboboxOptions>
                </div>
              </Combobox>
            </div>

            <div>
              <label for="paid" class="input-label" :class="{
                'label-required': timeEntryStore.requiredFields.includes('billable')
              }">{{ $t('billable') }}
              </label>
              <div class="mt-1">
                <BaseSwitch v-model="timeEntry.billable" :class="[
                  timeEntry.billable ? 'bg-lime' : 'bg-mossgray-300',
                  'relative inline-flex h-8 w-16 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-mossgray focus:ring-offset-2'
                ]">
                  <span class="sr-only">{{ $t('billable') }}</span>
                  <span aria-hidden="true" :class="[
                    timeEntry.billable ? 'translate-x-8' : 'translate-x-0',
                    'pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  ]" />
                </BaseSwitch>
              </div>
            </div>

            <div class="col-span-2">
              <label for="description" class="input-label" :class="{
                'label-required': timeEntryStore.requiredFields.includes('description')
              }">{{ $t('description') }}
              </label>
              <textarea ref="form_description" maxlength="1000" v-model="timeEntry.description"
                :required="timeEntryStore.requiredFields.includes('description')"
                class="block w-full textarea !max-w-full !rounded-lg min-h-32"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div v-if="datetime.diff(timeEntry.started_at, timeEntry.ended_at) > (1000 * 60 * 60 * 6)" class="alert-info">
        Die Dauer des Zeiteintrages übersteigt 6 Stunden.
      </div>
      <div class="" v-if="
        this.timeEntry && !this.timeEntry.id.startsWith('temp-') && this.timeEntry.push_state >= 5
      ">
        <div class="alert-danger">
          <strong> {{ $t('errors.sync_provider') }}: </strong>
          <ul class="pl-2 list-disc list-inside">
            <template v-for="(error, index) in this.timeEntry.push_errors" :key="index">
              <li class="font-semibold text-red-500" v-text="error"></li>
            </template>
          </ul>
        </div>
      </div>
      <div class="alert-danger" v-if="this.timeEntry?.tl?.error ?? null">
        <span v-text="$t(this.timeEntry?.tl.error)"> </span>
      </div>

      <div class="px-4 py-2 mt-4 border-l-4 border-lime-600 bg-lime-200 text-mossgray-900"
        v-if="isDev && this.timeEntry?.last_push">
        {{ $t('timeEntry.last_synchronisation') }}:
        {{ new Date(Date.parse(this.timeEntry?.last_push)).toLocaleString() }}
      </div>
    </form>

    <template v-slot:footer>
      <div class="flex w-full space-x-4" :class="{
        'justify-end': this.timeEntry?.id == 'tempNewEntry',
        'justify-between': this.timeEntry?.id != 'tempNewEntry'
      }">
        <div class="flex justify-start space-x-4" v-if="this.timeEntry?.id != 'tempNewEntry'">
          <BaseButton type="button" class="button-red" @click="openDeleteModal()">
            <font-awesome-icon :icon="['fa-kit', 'tl-trash']" class="pr-2 text-lg" />
            {{ $t('delete') }}
          </BaseButton>
        </div>

        <div class="flex items-center justify-end space-x-4">
          <BaseButton type="button" class="button-gray" @click="closeModal()">
            {{ $t('cancel') }}
          </BaseButton>
          <BaseButton type="button" @click="updateTimeEntry" class="inline-flex button-mossgray" :disabled="timeEntry &&
            Date.parse(timeEntry.started_at) >
            (timeEntry.ended_at ? Date.parse(timeEntry.ended_at) : Date.now())
            ">
            <!-- <kbd class="text-xs font-bold text-gray-400"
              ><kbd v-if="platform.includes('Mac')">⌘</kbd><kbd v-else>CTRL</kbd> <span>+</span>
              <kbd>Enter</kbd></kbd
            > -->
            <span v-if="this.timeEntry && this.timeEntry.id.startsWith('tempNewEntry')">{{
              $t('create')
            }}</span><span v-if="this.timeEntry && !this.timeEntry.id.startsWith('tempNewEntry')">{{
                $t('save')
              }}</span>
          </BaseButton>
          <BaseButton v-if="isActiveTimeEntry" type="button" @click="updateAndStop"
            class="inline-flex space-x-4 button-mossgray" :disabled="timeEntry &&
              Date.parse(timeEntry.started_at) >
              (timeEntry.ended_at ? Date.parse(timeEntry.ended_at) : Date.now() - 60_000)
              ">
            <!-- <kbd class="text-xs font-bold text-gray-400"
              ><kbd v-if="platform.includes('Mac')">⌘</kbd><kbd v-else>CTRL</kbd> <span>+</span>
              <kbd>Enter</kbd></kbd
            > -->
            <span>{{ $t('save_and_stop') }}</span>
          </BaseButton>
        </div>
      </div>
    </template>
    <LoadingSpinner v-model="isLoading" :overAll="true" />
  </BaseModal>
  <BaseModal v-model="showDeleteModal" :loading="isLoadingDelete" @close-modal="closeDeleteModal">
    <template #header> {{ $t('confirm_deletion') }} </template>

    <div class="p-6">
      <span>
        {{ $t('confirm_deletion_descriptions.timeEntry') }}
      </span>
    </div>

    <template #footer>
      <div class="flex justify-end w-full gap-4">
        <BaseButton class="button-gray" type="button" @click="closeDeleteModal">
          {{ $t('cancel') }}</BaseButton>
        <BaseButton class="button-red" @click="deleteEntry">{{ $t('delete') }}</BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { useClientsStore } from '@/stores/clients'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { useTimeEntryStore } from '@/stores/timeEntry'
import {
  Switch as BaseSwitch,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import BaseButton from '@/components/general/BaseButton.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useAuthUserStore } from '@/stores/auth-user'
import ComboBoxListItem from '@/components/general/ComboBoxListItem.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useCompanyStore } from '@/stores/company'
import { captureException } from '@sentry/vue'
import datetime from '@/lib/datetime'
import DateTimeInput from '@/components/general/DateTimeInput.vue'
import { useStopwatch, useTimer } from 'vue-timer-hook'
import { $t } from '@/config/i18n'

export default {
  components: {
    BaseModal,
    BaseButton,
    BaseSwitch,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    ComboBoxListItem,
    LoadingSpinner,
    DateTimeInput
  },
  setup() {
    const timeEntryStore = useTimeEntryStore()
    const companyStore = useCompanyStore()
    const dt = datetime
    return {
      timeEntryStore,
      companyStore,
      datetime: dt
    }
  },
  props: {
    modelValue: [null, Object, Boolean],
    item: Object,
    isActiveTimeEntry: {
      type: Boolean,
      default: false
    },
    stopActiveEntry: {
      type: Boolean,
      default: false
    },
    focusOn: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue', 'update:item'],
  data: () => ({
    createModal: false,
    // timeEntry: null,
    modalHandler: null,
    timeEntryHandler: null,
    clients: null,
    services: null,
    projects: null,
    queryProject: '',
    queryProjectHandler: null,
    queryClient: '',
    queryClientHandler: null,
    queryService: '',
    queryServiceHandler: null,
    waitingForAction: false,
    keyListener: false,
    isDev: false,
    testOpen: false,
    isLoading: false,
    showDeleteModal: false,
    isLoadingDelete: false,
    platform: '',
    stopwatch: null
  }),
  mounted() {
    this.isDev = import.meta.env.DEV
    this.platform = navigator.platform

    // this.createModal = useAppStore().showModalState
    // this.modalHandler = useAppStore().$subscribe((mutation) => {
    //   if (typeof mutation.payload == 'object' && mutation.payload) {
    //     if (Object.hasOwn(mutation.payload, 'showModalState')) {
    //       if (
    //         !this.timeEntry ||
    //         (this.timeEntry && useAppStore().selectedTimeEntryId != this.timeEntry.id)
    //       ) {
    //         this.timeEntry = useAppStore().selectedTimeEntryId
    //           ? useTimeEntryStore().getId(useAppStore().selectedTimeEntryId)
    //           : null
    //       }
    //       this.createModal = useAppStore().showModalState
    //     }
    //   }
    // })
    this.timeEntryHandler = useTimeEntryStore().$subscribe((mutation, state) => {
      if (this.waitingForAction) {
        if (this.timeEntry) {
          if (state.createdIds.length > 0) {
            if (
              this.timeEntry?.id?.startsWith('temp-') &&
              state.createdIds.find((item) => item.new == this.timeEntry?.id)
            ) {
              this.hideModal()
            }
          }
          if (state.changedIds.length > 0) {
            if (state.changedIds.find((item) => item == this.timeEntry.id)) {
              this.hideModal()
            }
          }
          if (state.deletedIds.length > 0) {
            if (state.deletedIds.find((item) => item == this.timeEntry.id)) {
              this.timeEntry = this.timeEntryStore.newEntry()
              this.hideModal()
            }
          }
        }
      }
    })
    this.clients = useClientsStore().clients
    this.services = useServicesStore().services
    this.projects = useProjectsStore().projects
    useClientsStore().$subscribe(() => {
      this.clients = useClientsStore().clients
    })
    useServicesStore().$subscribe(() => {
      this.services = useServicesStore().services
    })
    useProjectsStore().$subscribe(() => {
      this.projects = useProjectsStore().projects
    })
    useAuthUserStore().$subscribe(() => {
      this.clients = useClientsStore().clients
      this.services = useServicesStore().services
      this.projects = useProjectsStore().projects
    })
  },
  beforeUnmount() {
    if (this.modalHandler) {
      this.modalHandler()
    }
    if (this.keyListener) {
      window.removeEventListener('keyup', this.onKeyUp)
      window.removeEventListener('keydown', this.onKeyDown)
      this.keyListener = false
    }
  },

  computed: {
    showModal: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    timeEntry: {
      get() {
        if (!this.item) {
          return this.timeEntryStore.newEntry()
        }
        return this.item
      },
      set(value) {
        this.$emit('update:item', value)
      }
    },
    duration() {
      if (this.timeEntry && this.timeEntry.started_at && this.timeEntry.ended_at) {
        return datetime.showDiffInColon(this.timeEntry.started_at, this.timeEntry.ended_at)
      }
      return '00:00:00'
    },
    selectProjects() {
      this.timeEntry.id
      if (this.timeEntry.client_id ?? null) {
        return this.projects
          .filter((item) => item.client_id == this.timeEntry.client_id)
          .filter((item) => item.active)
      }
      return this.projects.filter((item) => item.active)
    },
    filteredProjects() {
      this.timeEntry.id
      let projects = null
      if (this.timeEntry.client_id ?? null) {
        projects = this.projects
          .filter((item) => item.client_id == this.timeEntry.client_id)
          .filter((item) => item.active)
      } else {
        projects = this.projects.filter((item) => item.active && (item?.tl?.clientActive ?? true))
      }
      if (this.queryProject == null || this.queryProject == undefined || this.queryProject == '') {
        return projects.slice(0, 10)
      }
      return projects
        .filter(
          (item) =>
            item.name.toLowerCase().includes(this.queryProject.toLowerCase()) ||
            (item.info != null &&
              item.info != undefined &&
              item.info.toLowerCase().includes(this.queryProject.toLowerCase())) ||
            (item.ext_tool_id != null &&
              item.ext_tool_id != undefined &&
              item.ext_tool_id.toLowerCase().includes(this.queryProject.toLowerCase()))
        )
        .slice(0, 10)
    },
    selectClients() {
      this.timeEntry.id

      return this.clients.filter((item) => item.active)
    },
    filteredClients() {
      this.timeEntry.id

      if (this.queryClient == null || this.queryClient == undefined) {
        return this.selectClients.slice(0, 10)
      }
      return this.selectClients
        .filter(
          (item) =>
            item.name.toLowerCase().includes(this.queryClient.toLowerCase()) ||
            (item.info != null &&
              item.info != undefined &&
              item.info.toLowerCase().includes(this.queryClient.toLowerCase())) ||
            (item.ext_tool_id != null &&
              item.ext_tool_id != undefined &&
              item.ext_tool_id.toLowerCase().includes(this.queryClient.toLowerCase()))
        )
        .slice(0, 10)
    },
    selectServices() {
      this.timeEntry.id

      return this.services.filter((item) => item.active)
    },
    filteredServices() {
      this.timeEntry.id

      if (this.queryService == null || this.queryService == undefined || this.queryService == '') {
        return this.selectServices
        // .slice(0, 10)
      }
      return this.selectServices.filter(
        (item) =>
          item.name.toLowerCase().includes(this.queryService.toLowerCase()) ||
          (item.info != null &&
            item.info != undefined &&
            item.info.toLowerCase().includes(this.queryService.toLowerCase())) ||
          (item.ext_tool_id != null &&
            item.ext_tool_id != undefined &&
            item.ext_tool_id.toLowerCase().includes(this.queryService.toLowerCase()))
      )
      // .slice(0, 10)
    },
    selectedProject: {
      get() {
        return useProjectsStore().getId(this.timeEntry.project_id) ?? null
      },
      set(newVal) {
        this.timeEntry.project_id = newVal?.id
        this.resetQueryProject()
        if (this.timeEntry.project_id) {
          this.timeEntry.client_id = newVal.client_id
        }
      }
    },
    selectedClient: {
      get() {
        return useClientsStore().getId(this.timeEntry.client_id) ?? null
      },
      set(newVal) {
        this.timeEntry.client_id = newVal?.id
        this.resetQueryClient()
        if (this.timeEntry.project_id) {
          const project = useProjectsStore().getId(this.timeEntry.project_id)
          if (project?.client_id != newVal?.id) {
            this.timeEntry.project_id = null
            this.$forceUpdate()
          }
        }
      }
    },
    selectedService: {
      get() {
        return useServicesStore().getId(this.timeEntry.service_id) ?? null
      },
      set(newVal) {
        this.timeEntry.service_id = newVal?.id
        this.resetQueryService()
      }
    },
    previousEntry() {
      if (!this.timeEntry) {
        return null
      }
      let timeEntries = useTimeEntryStore().timeEntries.filter(
        (item) =>
          item.id != this.timeEntry.id &&
          item.ended_at &&
          Date.parse(item.ended_at) >=
          Date.parse(this.timeEntry.started_at) - 24 * 60 * 60 * 1000 &&
          Date.parse(item.ended_at) <=
          Date.parse(this.isActiveTimeEntry ? this.timeEntry.started_at : this.timeEntry.ended_at)
      )
      let max = null
      let maxId = null
      timeEntries.forEach((item) => {
        if (!item.ended_at) {
          return
        }
        let start = Date.parse(item.ended_at)
        if (start > max) {
          max = start
          maxId = item.id
        }
      })
      return useTimeEntryStore().getId(maxId)
    },
    followingEntry() {
      if (!this.timeEntry) {
        return null
      }
      let timeEntries = useTimeEntryStore().timeEntries.filter(
        (item) =>
          item.id != this.timeEntry.id &&
          item.started_at &&
          Date.parse(item.started_at) <=
          Date.parse(this.timeEntry.ended_at) + 24 * 60 * 60 * 1000 &&
          Date.parse(item.started_at) >= Date.parse(this.timeEntry.started_at)
      )
      let min = null
      let minId = null
      timeEntries.forEach((item) => {
        let end = Date.parse(item.started_at)
        if (min == null) {
          min = end
          minId = item.id
        }
        if (end < min) {
          min = end
          minId = item.id
        }
      })

      if (
        min > Date.now() ||
        (min == null && Date.parse(this.timeEntry.ended_at) >= Date.now() - 24 * 60 * 60 * 1000)
      ) {
        min = Date.now()
        minId = null
        return {
          id: 'now',
          started_at: new Date(Date.now()).toISOString(),
          ended_at: new Date(Date.now()).toISOString()
        }
      }

      return useTimeEntryStore().getId(minId)
    }
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.$forceUpdate()
        timelinkStoresService.removeTimeout('timeEntries', 'clearSearch')
        timelinkStoresService.removeTimeout('timeEntryModal', 'resetSyncMessage')
      }
      if (newVal && !this.keyListener) {
        window.addEventListener('keyup', this.onKeyUp)
        window.addEventListener('keydown', this.onKeyDown)
        this.keyListener = true
      }
      if (!newVal && this.keyListener) {
        window.removeEventListener('keyup', this.onKeyUp)
        window.removeEventListener('keydown', this.onKeyDown)
        this.keyListener = false
      }
      if (newVal && !this.timeEntry) {
        this.timeEntry = useTimeEntryStore().newEntry()
      }
      if (newVal && this.isActiveTimeEntry) {
        if (this.stopActiveEntry) {
          if (this.focusOn) {
            this.$nextTick(() => {
              this.$refs['form_' + this.focusOn]?.focus()
            })
          } else {
            let validate = useTimeEntryStore().validate(this.timeEntry)
            if (validate.length > 0) {
              this.$nextTick(() => {
                // this.$refs.form_started_at?.focusTime()
                if (validate.includes('project_id')) {
                  this.$refs.form_project_id.$el.focus()
                } else if (validate.includes('client_id')) {
                  this.$refs.form_client_id.$el.focus()
                } else if (validate.includes('service_id')) {
                  this.$refs.form_service_id.$el.focus()
                } else if (validate.includes('description')) {
                  this.$refs.form_description?.focus()
                } else {
                  this.$refs.form_started_at?.focusTime()
                }
              })
            } else {
              this.$nextTick(() => {
                this.$refs.form_started_at?.focusTime()
              })
            }
          }
        } else {
          if (this.focusOn) {
            this.$nextTick(() => {
              this.$refs['form_' + this.focusOn]?.focus()
            })
          } else {
            this.$nextTick(() => {
              this.$refs.form_started_at?.focusTime()
            })
          }
        }
        this.stopwatch = useStopwatch(
          (Date.now() - Date.parse(this.timeEntry.started_at)) / 1000,
          true
        )
      } else if (newVal && !this.isActiveTimeEntry) {
        if (this.focusOn) {
          this.$nextTick(() => {
            this.$refs['form_' + this.focusOn]?.focus()
          })
        } else {
          this.$nextTick(() => {
            this.$refs.form_started_at?.focusTime()
          })
        }
      } else {
        this.stopwatch = null
      }
    },
    'timeEntry.started_at'(newVal) {
      if (newVal && this.isActiveTimeEntry) {
        if (Date.parse(newVal) > Date.now()) {
          this.stopwatch = null
          this.timeEntry.started_at = datetime.iso(Date.now())
          return
        }
        try {
          this.stopwatch = useStopwatch(
            (Date.now() - Date.parse(this.timeEntry.started_at)) / 1000,
            true
          )
        } catch (error) {
          //
        }
      }
    },
    queryProject(newVal) {
      if (newVal != null && newVal != undefined && newVal != '') {
        this.callProjectSearch()
      }
    },
    queryClient(newVal) {
      if (newVal != null && newVal != undefined && newVal != '') {
        this.callClientSearch()
      }
    },
    queryService(newVal) {
      if (newVal != null && newVal != undefined && newVal != '') {
        this.callServiceSearch()
      }
    }
    // 'timeEntry.id'(newVal, oldVal) {
    //   if (newVal && oldVal) {
    //     //
    //   }
    // }
  },
  methods: {
    onKeyUp(event) {
      if (event.which === 27) {
        this.closeModal()
        return
      }
    },

    onKeyDown(event) {
      this.saveKeyEvent(event)
    },

    getDate(entry) {
      return datetime.getDate(entry)
    },
    getTime(entry, addHours = 0, addMinutes = 0, addSeconds = 0, fixToZeroSeconds = false) {
      return datetime.getTime(entry, addHours, addMinutes, addSeconds, fixToZeroSeconds)
    },
    setDate(entry, value) {
      return datetime.setDate(entry, value)
    },
    setTime(entry, value) {
      return datetime.setTime(entry, value)
    },
    closeModal() {
      if (this.showDeleteModal) {
        return
      }
      if (this.timeEntry) {
        useTimeEntryStore().resetEntry(this.timeEntry.id)
        // useAppStore().$patch({ selectedTimeEntryId: false})
      }
      this.hideModal()
    },
    hideModal() {
      this.showModal = false
      // logger.info('Hide Modal')
      this.waitingForAction = false
      this.timeEntry = useTimeEntryStore().newEntry()
      this.queryService = ''
      this.queryProject = ''
      this.queryClient = ''
      timelinkStoresService.setOrRenewTimeout(
        'timeEntries',
        'clearSearch',
        () => {
          useTimeEntryStore().clearSearch()
        },
        2000
      )
    },
    openDeleteModal() {
      this.showDeleteModal = true
      this.isLoadingDelete = false
    },
    async deleteEntry() {
      this.isLoadingDelete = true
      this.waitingForAction = true

      await useTimeEntryStore().deleteId(this.timeEntry?.id)
      this.closeDeleteModal()
      this.showModal = false
    },
    closeDeleteModal() {
      this.showDeleteModal = false
      this.isLoadingDelete = false
    },
    async updateTimeEntry() {
      // TODO: Add alerts if possible
      if (!this.isActiveTimeEntry) {
        if (!this.$refs.timeEntryForm.reportValidity()) {
          return
        }
      }

      this.waitingForAction = true
      this.isLoading = true
      try {
        if (this.timeEntry.id == 'tempNewEntry') {
          useTimeEntryStore().tempNewEntry = this.timeEntry
        }
        this.timeEntry = await useTimeEntryStore().createOrUpdateEntry(this.timeEntry.id)
      } catch (error) {
        captureException(error)
        console.log(error)
        // logger.error('There was an error while trying to update or create an Entry', ex)
      }
      this.isLoading = false
      // TODO: Add vue notification
      if (!this.timeEntry?.tl.isDirty && this.timeEntry.id.toString() != 'tempNewEntry') {
        this.hideModal()
      }
    },
    async updateAndStop() {
      if (!this.$refs.timeEntryForm.reportValidity()) {
        return
      }
      this.waitingForAction = true
      this.isLoading = true
      try {
        let response = await useTimeEntryStore().stopActiveEntry()
        if (response != null) {
          this.isLoading = false

          this.hideModal()
        }
      } catch (error) {
        captureException(error)
        console.log(error)
      }
      this.isLoading = false
    },
    resetQueryProject(isOpen = false) {
      if (isOpen) {
        return
      }
      timelinkStoresService.setOrRenewTimeout(
        'timeEntryModal',
        'queryProject',
        () => {
          this.queryProject = ''
        },
        120
      )
    },
    resetQueryClient(isOpen = false) {
      if (isOpen) {
        return
      }
      timelinkStoresService.setOrRenewTimeout(
        'timeEntryModal',
        'queryClient',
        () => {
          this.queryClient = ''
        },
        120
      )
    },
    resetQueryService(isOpen = false) {
      if (isOpen) {
        return
      }
      timelinkStoresService.setOrRenewTimeout(
        'timeEntryModal',
        'queryService',
        () => {
          this.queryService = ''
        },
        120
      )
    },
    callProjectSearch() {
      timelinkStoresService.setOrRenewTimeout(
        'timeEntryModal',
        'queryProjectHandler',
        () => {
          useProjectsStore().search(this.queryProject, this.timeEntry?.client_id ?? null, {
            active: true
          })
        },
        250
      )
    },
    callClientSearch() {
      timelinkStoresService.setOrRenewTimeout(
        'timeEntryModal',
        'queryClientHandler',
        () => {
          useClientsStore().search(this.queryClient, {
            active: true
          })
        },
        250
      )
    },
    callServiceSearch() {
      timelinkStoresService.setOrRenewTimeout(
        'timeEntryModal',
        'queryServiceHandler',
        () => {
          useServicesStore().search(this.queryService, {
            active: true
          })
        },
        250
      )
    },
    setToPreviousEntry() {
      this.timeEntry.started_at = new Date(Date.parse(this.previousEntry.ended_at)).toISOString()
    },
    setToFollowingEntry() {
      if (this.followingEntry.id == 'now') {
        this.timeEntry.ended_at = datetime.removeMillisecondsFromISO(new Date(Date.now()))
        return
      }
      this.timeEntry.ended_at = new Date(Date.parse(this.followingEntry.started_at)).toISOString()
    },

    saveKeyEvent(event) {
      if (
        (event.metaKey || event.ctrlKey) &&
        (event.key == 'Enter' || event.code == 'Enter' || event.code == 'NumpadEnter')
      ) {
        if (this.timeEntry) {
          this.updateTimeEntry()
        } else {
          return false
        }
        return false
      }
    }
  }
}
</script>
