<template>
    <BaseModal v-model="showImageDeleteModal" @close-modal="showImageDeleteModal = false">
        <template #header> {{ $t('confirm_deletion') }} </template>

        <div class="p-6">
            <span>
                {{ $t('confirm_deletion_descriptions.image') }}
            </span>
        </div>

        <template #footer>
            <div class="flex justify-end w-full gap-4">
                <BaseButton class="button-gray" type="button" @click="showImageDeleteModal = false"
                    ref="cancelImageDeleteModalButton">
                    {{ $t('cancel') }}</BaseButton>
                <BaseButton class="button-red" @click="deleteImage">{{ $t('delete') }}</BaseButton>
            </div>
        </template>
    </BaseModal>
</template>

<script setup>
import BaseModal from './BaseModal.vue';
import BaseButton from '../general/BaseButton.vue';
import { $t } from '@/config/i18n';

const showImageDeleteModal = defineModel({ type: Boolean, default: false })
const emit = defineEmits(['confirmed'])

function deleteImage() {
    emit('confirmed')
}
</script>