<template>
  <div class="flex flex-col space-y-2 py-2 mt-4">
    <template v-for="(timeEntry, index) in timeEntries" :key="timeEntry.id">
      <div>
        <div
          v-if="
            index == 0 ||
            new Date(Date.parse(timeEntry.started_at)).getDate() !=
              new Date(Date.parse(timeEntries[index - 1].started_at)).getDate()
          "
          class="my-2"
        >
          <h1 class="text-xl font-bold inline-block pb-0 ml-0">
            {{
              new Date(Date.parse(timeEntry.started_at)).toLocaleDateString(
                authUserStore?.user?.language ?? 'de',
                {
                  weekday: 'long'
                }
              )
            }}
            {{
              new Date(Date.parse(timeEntry.started_at)).toLocaleDateString(
                authUserStore?.user?.language ?? 'de'
              )
            }}
            <span class="text-sm font-mono pl-3">{{
              calcDay(new Date(Date.parse(timeEntry.started_at)))
            }}</span>
          </h1>
        </div>
        <div class="flex flex-row relative group bg-white border rounded-2xl">
          <div
            :style="{
              backgroundColor: this.timeEntryStore.getColor(timeEntry).concat('', 'a6')
            }"
            class="min-w-6 h-hull relative min-h-16 rounded-l-2xl overflow-visible mr-10 m-0"
          >
            <div class="absolute h-full flex items-center w-full justify-center">
              <div
                class="w-10 absolute left-2 m-0 bg-gray-200 text-center overflow-hidden aspect-square flex justify-center items-center squircle-clip"
                :style="{
                  color:
                    timeEntry.client_id && !clientsStore.getId(timeEntry.client_id)?.image_id
                      ? timelinkService.generateForegroundColor(
                          clientsStore.getId(timeEntry.client_id)?.color
                            ? clientsStore.getId(timeEntry.client_id)?.color
                            : timelinkService.generateBackgroundColor(
                                clientsStore.getId(timeEntry.client_id)
                              )
                        )
                      : null,
                  backgroundColor:
                    timeEntry.client_id && !clientsStore.getId(timeEntry.client_id)?.image_id
                      ? clientsStore.getId(timeEntry.client_id)?.color
                        ? clientsStore.getId(timeEntry.client_id)?.color
                        : timelinkService.generateBackgroundColor(
                            clientsStore.getId(timeEntry.client_id)
                          )
                      : null
                }"
              >
                <img
                  v-if="clientsStore.getId(timeEntry.client_id)?.image_id"
                  :src="timelinkService.getImageFor(clientsStore.getId(timeEntry.client_id))"
                  class="object-scale-down object-center"
                />
                <span
                  class="font-bold"
                  :class="{
                    'text-lg':
                      timelinkService.acronymOrShortName(clientsStore.getId(timeEntry.client_id))
                        .length < 3,
                    'text-base':
                      timelinkService.acronymOrShortName(clientsStore.getId(timeEntry.client_id))
                        .length == 3,
                    'text-sm':
                      timelinkService.acronymOrShortName(clientsStore.getId(timeEntry.client_id))
                        .length >= 4
                  }"
                  v-else
                  v-text="
                    timelinkService.acronymOrShortName(clientsStore.getId(timeEntry.client_id))
                  "
                >
                </span>
              </div>
            </div>
          </div>
          <div
            class="pl-2 pr-4 grid grid-cols-1 grid-rows-2 lg:grid-rows-1 md:grid-cols-12 w-full gap-2 h-full min-h-16 items-center grid-flow-col"
          >
            <div class="col-span-11 lg:col-span-5">
              <div class="font-bold text-lg">
                {{
                  servicesStore.getId(timeEntry.service_id)?.name
                    ? servicesStore.getId(timeEntry.service_id).name
                    : '&nbsp;'
                }}
              </div>
              <div class="text-sm text-mossgray-700" v-show="timeEntry.client_id">
                <span>
                  {{
                    clientsStore.getId(timeEntry.client_id)?.name ?? $t('tracking.no_client_name')
                  }} </span
                ><span v-show="timeEntry.project_id">
                  > {{ projectsStore.getId(timeEntry.project_id)?.name }}</span
                >
              </div>
              <div class="text-sm" v-show="!timeEntry.client_id">
                {{ $t('tracking.no_client_selected') }}
              </div>
            </div>
            <div class="flex col-span-4 lg:col-span-3 gap-2 items-baseline justify-end">
              <span class="font-mono">
                {{ datetime.getTime(timeEntry.started_at ?? null, 0, 0, true) }}
                -
                {{ datetime.getTime(timeEntry.ended_at ?? null, 0, 0, true) }}
              </span>
              <!-- <span class="font-grotesk text-sm">{{ $t('o_clock') }}</span> -->
            </div>
            <div
              class=""
              :class="{
                'col-span-5 lg:col-span-4': !showActionButtons,
                'col-span-4 lg:col-span-2': showActionButtons
              }"
            >
              <span
                class="flex items-center justify-end space-x-2 font-mono"
                v-tippy="{ content: $t('duration'), followCursor: 'horizontal' }"
              >
                <span>{{ this.showDiff(timeEntry.started_at, timeEntry.ended_at) }}</span>
                <font-awesome-icon :icon="['fa-kit', 'tl-clock']" />
              </span>
            </div>
            <div
              class="flex col-span-2 items-center pr-2 md:pr-4 lg:pr-6 xl:pr-8 justify-end space-x-4 row-span-2 lg:row-span-1"
              v-if="showActionButtons"
            >
              <button type="button" @click="editTimeEntry(timeEntry)">
                <font-awesome-icon :icon="['fa-kit', 'tl-calendar-edit']" size="xl" />
              </button>
              <button type="button" @click="showDeleteModalForId(timeEntry)">
                <font-awesome-icon :icon="['fa-kit', 'tl-trash']" class="text-red-500" size="xl" />
              </button>
            </div>
          </div>
        </div>

        <!-- PAUSE -->
        <div
          v-if="
            showBreaks &&
            timeEntries.length > index + 1 &&
            new Date(Date.parse(timeEntry.ended_at)).getDate() ==
              new Date(Date.parse(timeEntries[index + 1].started_at)).getDate() &&
            new Date(Date.parse(timeEntry.ended_at)).toISOString() !=
              new Date(Date.parse(timeEntries[index + 1].started_at)).toISOString()
          "
          class="mt-2 px-3 py-2 border rounded-2xl flex justify-center items-center relative group time-entries-break"
        >
          <div class="text-sm font-semibold">
            {{
              datetime.readableAbsoluteDiff(
                Date.parse(timeEntry.ended_at),
                Date.parse(timeEntries[index + 1].started_at)
              ) + ' Pause'
            }}
            <!-- TODO: Translation -->
          </div>
          <!-- END PAUSE -->
        </div>
      </div>
    </template>
  </div>

  <!-- START DELETE MODAL -->
  <BaseModal v-model="showDeleteModal" :loading="isLoadingDelete" @close-modal="closeDeleteModal">
    <template #header> {{ $t('confirm_deletion') }} </template>

    <div class="p-6">
      <span>
        {{ $t('confirm_deletion_descriptions.timeEntry') }}
      </span>
    </div>

    <template #footer>
      <div class="w-full flex justify-end gap-4">
        <BaseButton
          class="button-gray"
          type="button"
          @click="closeDeleteModal"
          ref="closeDeleteModalButton"
        >
          {{ $t('cancel') }}</BaseButton
        >
        <BaseButton class="button-red" @click="deleteItem" ref="deleteModalButton">{{
          $t('delete')
        }}</BaseButton>
      </div>
    </template>
  </BaseModal>
  <!-- END DELETE MODAL -->

  <TimeEntryModalComponent
    v-model="showTimeEntryModal"
    v-model:item="timeEntryModalModel"
    :is-active-time-entry="timeEntryIsActiveEntry"
  />
  <SquirclePath></SquirclePath>
</template>

<script>
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useAuthUserStore } from '@/stores/auth-user'
import { useClientsStore } from '@/stores/clients'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import timelinkStoresService from '@/services/timelink-stores.service'
import datetime from '@/lib/datetime'
import SquirclePath from '@/components/general/SquirclePath.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import BaseButton from '@/components/general/BaseButton.vue'
import TimeEntryModalComponent from '@/components/modals/TimeEntryModalComponent.vue'

export default {
  components: {
    SquirclePath,
    BaseModal,
    BaseButton,
    TimeEntryModalComponent
  },
  setup() {
    const timeEntryStore = useTimeEntryStore()
    const clientsStore = useClientsStore()
    const projectsStore = useProjectsStore()
    const servicesStore = useServicesStore()
    const authUserStore = useAuthUserStore()
    const timelinkService = timelinkStoresService
    const dt = datetime
    return {
      timeEntryStore: timeEntryStore,
      clientsStore,
      projectsStore,
      servicesStore,
      authUserStore,
      timelinkService,
      datetime: dt
    }
  },
  data: () => ({
    showDeleteModal: false,
    isLoadingDelete: false,
    showTimeEntryModal: false,
    // timeEntryModalId: null,
    timeEntryModalModel: null,
    timeEntryIsActiveEntry: false
  }),
  props: {
    timeEntries: {
      type: Array
    },
    showBreaks: {
      type: Boolean,
      default: true
    },
    showActionButtons: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.timeEntryModalModel = useTimeEntryStore().newEntry()
  },
  watch: {
    timeEntryModalModel(newVal) {
      if (!newVal) {
        this.timeEntryModalModel = useTimeEntryStore().newEntry()
      }
    }
  },
  methods: {
    calcDay(day) {
      return datetime.calcDay(day, this.timeEntries)
    },
    showDiff(start, end) {
      // TODO: Move duration calculation to TimeEntryModel?
      let diff = Date.parse(end) - Date.parse(start)
      return datetime.convertDateNumberToString(diff)
    },
    editTimeEntry(timeEntry) {
      this.timeEntryIsActiveEntry = false
      this.timeEntryModalId = timeEntry.id
      this.timeEntryModalModel = timeEntry
      if (timeEntry.id == useTimeEntryStore().activeTimeEntry) {
        this.timeEntryIsActiveEntry = true
      }
      this.showTimeEntryModal = true
    },
    async deleteItem() {
      let id = this.selectedDeleteId
      if (!id) {
        return
      }
      this.isLoadingDelete = true
      useTimeEntryStore().deleteId(id)
      this.selectedDeleteId = null
      this.showDeleteModal = false
      this.isLoadingDelete = false
    },
    showDeleteModalForId(timeEntry) {
      this.selectedDeleteId = timeEntry.id
      this.showDeleteModal = true
      this.$nextTick(() => {
        this.$refs.deleteModalButton?.focus()
      })
      this.isLoadingDelete = false
    },
    closeDeleteModal() {
      this.selectedDeleteId = null
      this.showDeleteModal = false
      this.isLoadingDelete = false
    }
  }
}
</script>
