<template>
  <!-- See: https://dev.to/georgedoescode/codepen-soften-up-your-designs-with-a-squircle-3nd3 -->
  <!-- <svg width="0" height="0">
    <defs>
      <clipPath id="squircle" clipPathUnits="objectBoundingBox">
        <path d="M .5,0 C .1,0 0,.1 0,.5 0,.9 .1,1 .5,1 .9,1 1,.9 1,.5 1,.1 .9,0 .5,0 Z"/>
      </clipPath>
    </defs>
  </svg> -->
  <!-- ClipPath Generation see: https://www.dgmyspace.dumgal.ac.uk/eportfolio/rumbler/2021/03/24/css-svg-clippaths/ -->
  <svg width="0" height="0">
    <defs>
        <clipPath id="squircle" clipPathUnits="objectBoundingBox">
            <path d="m.892.892h0c-.082.086-.237.106-.392.108-.155-.002-.31-.022-.392-.108q-.055-.058,0,0c-.086-.082-.106-.237-.108-.392C.002.345.022.19.108.108Q.166.053.108.108C.19.022.345.002.5,0c.155.002.31.022.392.108q.055.058,0,0c.086.082.106.237.108.392-.002.155-.022.31-.108.392Z"/>
        </clipPath>
    </defs>
  </svg>
</template>


<style>
  .squircle-clip {
    clip-path: url(#squircle);
    -webkit-clip-path: url("#squircle"); 
  }
</style>

<script>
    export default {
        props: ['disabled']
    }
</script>