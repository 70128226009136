<template>
  <button class="button" :disabled="disabled" ref="button">
    <slot></slot>
  </button>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({ disabled: { type: Boolean, default: false } })
const button = ref(null)

function focus() {
  button.value?.focus()
}

defineExpose({ focus })
</script>
