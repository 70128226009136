<template>
  <div class="w-full flex flex-row min-h-screen overflow-hidden">
    <settings-menu />
    <div class="flex flex-grow min-h-screen h-fit overflow-y-auto">
      <router-view />
    </div>
  </div>
</template>

<script>
import SettingsMenu from '@/components/sidebars/SettingsMenu.vue'

export default {
  components: { SettingsMenu }
}
</script>
