<template>
  <div class="min-w-56 basis-56 bg-ivory border-r border-mossgray-200 min-h-screen">
    <div
      class="flex w-full flex-col divide-y divide-mossgray-200 border-b border-mossgray-200"
    >
      <router-link
        class="w-full p-2 px-4"
        active-class="bg-white font-semibold"
        :to="{ name: 'General' }"
      >
        {{ $t('menu.settings_.general') }}
      </router-link>
      <router-link
        class="w-full p-2 px-4"
        active-class="bg-timelink-ivory font-semibold"
        :to="{ name: 'NotificationSettings' }"
      >
        {{ $t('menu.settings_.notifications') }}
      </router-link>
      <router-link
        class="w-full p-2 px-4"
        active-class="bg-white font-semibold"
        :to="{
          name: 'ClientsList',
          query: {
            page: 1
          }
        }"
      >
        {{ $t('menu.settings_.clients_and_projects') }}
      </router-link>
      <router-link
        class="w-full p-2 px-4"
        active-class="bg-white font-semibold"
        to="/settings/services"
      >
        {{ $t('menu.settings_.services') }}
      </router-link>
      <router-link
        v-if="authUserStore?.user?.admin >= 9"
        class="w-full p-2 px-4"
        active-class="bg-white font-semibold"
        to="/settings/users"
      >
        {{ $t('menu.settings_.users') }}
      </router-link>
      <router-link
        v-if="authUserStore?.user?.admin >= 9"
        class="w-full p-2 px-4"
        active-class="bg-white font-semibold"
        to="/settings/subscription"
      >
        {{ $t('menu.settings_.subscription') }}
      </router-link>
      <router-link
        v-if="authUserStore?.user?.admin >= 9"
        class="w-full p-2 px-4"
        active-class="bg-white font-semibold"
        :to="{ name: 'Integrations' }"
      >
        {{ $t('menu.settings_.integrations') }}
      </router-link>
    </div>
  </div>
</template>

<script>
// vue default export
import { useAuthUserStore } from '@/stores/auth-user'
export default {
  components: {},
  setup() {
    const authUserStore = useAuthUserStore()
    return { authUserStore }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
