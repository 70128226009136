import axios from 'axios'

class TimeEntryService {
  /**
   *
   * @param {*} params
   * @throws
   */
  async export(params = {}) {
    let file = await axios.get(import.meta.env.VITE_API_URL + '/api/v1/timeEntries/export', {
      params: { ...params },
      responseType: 'blob',
      cache: false
    })
    const temp = window.URL.createObjectURL(new Blob([file.data]))
    const link = document.createElement('a')
    link.href = temp
    link.setAttribute('download', 'timeEntries.xlsx')
    link.click()
    link.remove()
    window.URL.revokeObjectURL(temp)
  }
}
export default new TimeEntryService()
