<template>
  <div class="min-h-screen w-full flex flex-col flex-grow items-center justify-center">
    <LoadingSpinner v-model="showSpinner" :fullPage="true"></LoadingSpinner>
    <div class="max-w-xl w-full space-y-8 bg-white rounded-2xl border border-mossgray-400">
      <div class="w-full space-y-8 p-12 pt-0">
        <div>
          <div class="flex justify-center mt-6 -mb-2">
            <img :src="img" />
          </div>
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            <!-- TODO: Translation -->
            Firmenanmeldung (SSO)
          </h2>
        </div>
        <form class="space-y-4" action="#" method="POST">
          <input type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <!-- TODO: Translation -->
              <!-- TODO: harmonise input (classes) -->
              <label for="email-address" class="sr-only">Firmendomäne</label>
              <input
                v-model="domain_input"
                id="company-domain"
                name="domain"
                type="text"
                autocomplete="company-domain"
                required
                class="appearance-none relative block w-full px-3 py-2 border border-mossgray-200 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-mossgray focus:border-mossgray focus:z-10 sm:text-sm"
                placeholder="Firmendomaene.de"
              />
            </div>
          </div>
          <div class="text-red-500 text-sm" v-text="messageSso"></div>

          <div>
            <button
              type="submit"
              @click.prevent="handleSso"
              class="group relative w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white bg-mossgray hover:bg-white hover:text-mossgray ring-2 ring-mossgray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mossgray"
            >
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- TODO: FontAwesome Icon -->
                <!-- Heroicon name: solid/lock-closed -->
                <svg
                  class="h-5 w-5 text-white group-hover:text-mossgray"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <!-- TODO: Translation -->
              Weiter
            </button>
          </div>
        </form>

        <router-link
          to="/login"
          class="flex flex-col divide-y divide-mossgray-200 ring-1 ring-inset ring-mossgray-200 hover:ring-mossgray rounded-md mb-2"
        >
          <!-- TODO: Translation -->
          <span class="text-sm font-semibold p-3">Zurück zum Login</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DesktopService from '@/services/desktop.service'
import { useAuthUserStore } from '@/stores/auth-user'
import axios from 'axios'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import img from '@/assets/timelink_primary_logo_5f_moosgrauRGB.svg'

export default {
  name: 'SSO',
  components: {
    LoadingSpinner
  },
  setup() {
    const desktopService = DesktopService
    const authUserStore = useAuthUserStore()
    return {
      desktop: desktopService,
      authUserStore: authUserStore,
      img
    }
  },
  data() {
    let api_domain = import.meta.env.VITE_API_URL
    return {
      loading: false,
      message: '',
      messageSso: '',
      domain: null,
      set_callback: false,
      microsoft_oauth: api_domain + '/oauth/microsoft/',
      redirect_url: '',
      showSpinner: false
    }
  },
  created() {
    if (this.authUserStore.salt == null) {
      this.authUserStore.salt = Math.random().toString(20).substring(2, 6)
    }
    if (this.loggedIn) {
      this.$router.push('/profile')
    }
  },
  mounted() {
    if (this.desktop.getConfig('ssoDomain')) {
      this.domain = this.desktop.getConfig('ssoDomain')
    }
    if (this.authUserStore.salt == null) {
      this.authUserStore.salt = Math.random().toString(20).substring(2, 6)
    }
    this.showSpinner = false
  },
  computed: {
    loggedIn() {
      return this.authUserStore.isAuthenticated
    },
    domain_input: {
      get() {
        if (typeof this.domain == 'string' && this.domain.includes('@')) {
          return this.domain.split('@')[1]
        }
        return this.domain
      },
      set(value) {
        if (typeof value == 'string' && value.includes('@')) {
          this.domain = value.split('@')[1]
        } else {
          this.domain = value
        }
      }
    }
  },
  methods: {
    handleSso() {
      this.messageSso = ''
      this.showSpinner = true
      axios
        .post(import.meta.env.VITE_API_URL + '/api/v1/sso', {
          domain: this.domain
        })
        .then((response) => {
          this.redirect_url = window.location.toString()
          this.redirect_url = this.redirect_url.substring(0, this.redirect_url.indexOf('/sso'))
          this.redirect_url = this.redirect_url + '/login'
          // this.showSsoScreen = false
          // this.showLoginScreen = true
          let redirect_to = response.data.url
          redirect_to =
            redirect_to +
            '/' +
            this.authUserStore.salt +
            '?redirect=' +
            encodeURIComponent(this.redirect_url)

          window.location.href = redirect_to
        })
        .catch((error) => {
          this.showSpinner = false
          if (error.response.status == 404) {
            this.messageSso = 'Konnte Eintrag nicht finden.'
          }
        })
    }
  }
}
</script>
