<template>
  <form @submit.prevent="downloadExport" ref="form">
    <BaseModal v-model="showModal" @close-modal="closeModal" :stop-auto-focus="true">
      <template v-slot:header> {{ $t('settings.general.export_time_entries') }} </template>
      <div class="px-4 py-6 sm:p-8 min-h-96">
        <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div class="flex flex-col sm:col-span-6">
            <label class="input-label">Zeitraum</label>
            <select class="input" v-model="timeInterval">
              <template v-for="interval in intervals" :key="interval">
                <option :value="interval">{{ $t('exports.intervals.' + interval) }}</option>
              </template>
            </select>
          </div>

          <div class="sm:col-span-3" v-if="timeInterval == 'custom'">
            <DateTimeInput v-model="start" :allow-nullable="true" :withoutTime="true">
              <template #label>
                {{ $t('timeEntries.start') }}
              </template>
              <template #button="vProps">
                <button :class="vProps.classes" @click="start = null" v-tippy="'Löschen'">
                  <div class="px-3 pr-4">
                    <font-awesome-icon :icon="['fa-kit', 'tl-cross']" size="lg" />
                  </div>
                </button>
              </template>
            </DateTimeInput>
          </div>

          <div class="sm:col-span-3" v-if="timeInterval == 'custom'">
            <DateTimeInput v-model="end" :allow-nullable="true" :withoutTime="true">
              <template #label>
                {{ $t('timeEntries.end') }}
              </template>
              <template #button="vProps">
                <button :class="vProps.classes" @click="start = null" v-tippy="'Löschen'">
                  <div class="px-3 pr-4">
                    <font-awesome-icon :icon="['fa-kit', 'tl-cross']" size="lg" />
                  </div>
                </button>
              </template>
            </DateTimeInput>
          </div>

          <div class="sm:col-span-3">
            <div class="relative mt-2">
              <div>
                <Combobox as="div" v-model="selectedUser" immediate v-slot="{ open }">
                  <ComboboxLabel class="input-label">{{ $t('user', 10) }}</ComboboxLabel>
                  <div class="relative mt-2">
                    <!-- TODO: move css classes as default to component - make it globally the same -->
                    <ComboboxInput
                      class="w-full rounded-full border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-mossgray-800 sm:text-sm sm:leading-6 disabled:bg-slate-100"
                      :displayValue="(user) => user?.full_name" @change="queryUser = $event.target.value"
                      autocomplete="off" :placeholder="$t('search_dots')" @blur="resetQueryUser(open)"
                      :disabled="authUserStore.user.admin < 9" />

                    <ComboboxButton
                      class="absolute inset-y-0 flex items-center px-2 right-2 rounded-r-md focus:outline-none"
                      :disabled="authUserStore.user.admin < 9"><font-awesome-icon
                        :icon="['fa-kit', 'tl-arrow-up-down']" />
                    </ComboboxButton>

                    <ComboboxOptions
                      class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ComboboxOption v-for="item in filteredUsers" :key="item.id" :value="item"
                        :disabled="!item.active" as="template" v-slot="{ active, selected }">
                        <li :class="[
                          'relative cursor-default select-none py-2 pl-3 pr-9 border-b-2 border-gray-200 group',
                          active ? 'bg-mossgray ' : ''
                        ]">
                          <div class="flex flex-col items-start space-y">
                            <span :class="[
                              'ml-3 truncate   text-sm font-semibold',
                              active ? ' text-white' : 'text-black'
                            ]">
                              {{ item.full_name }}
                            </span>
                            <span :class="[
                              '  text-xs ml-3 truncate',
                              active ? 'text-gray-300' : 'text-gray-500'
                            ]">
                              {{ item?.email }}
                            </span>
                          </div>

                          <span v-if="selected" :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-mossgray'
                          ]">
                            <font-awesome-icon :icon="['fa-kit', 'tl-check']" />
                          </span>
                        </li>
                      </ComboboxOption>
                      <li class="relative py-2 pl-3 font-semibold text-gray-500 cursor-default select-none pr-9">
                        <div class="flex items-center text-center">
                          <span class="ml-3 truncate"> {{ $t('search_by_entering') }} </span>
                        </div>

                        <!-- TODO: what is that span used for? -->
                        <span class="absolute inset-y-0 right-0 flex items-center pr-4"> </span>
                      </li>
                    </ComboboxOptions>
                  </div>
                </Combobox>
              </div>
            </div>
          </div>
          <div class="sm:col-span-6" v-if="errorMessage">
            <div class="alert-danger" v-text="errorMessage"></div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="flex items-center justify-end w-full space-x-4">
          <BaseButton class="button-gray" @click="closeModal" type="button">{{
            $t('cancel')
            }}</BaseButton>

          <BaseButton class="button-mossgray" type="submit" @click="downloadExport" ref="focus_object">
            {{ $t('export') }}
          </BaseButton>
        </div>
      </template>
      <LoadingSpinner v-model="isLoading" :overAll="true"></LoadingSpinner>
    </BaseModal>
  </form>
</template>

<script>
import timeEntryService from '@/services/timeEntry.service'
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import DateTimeInput from '@/components/general/DateTimeInput.vue'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import userService from '@/services/user.service'
import { useAuthUserStore } from '@/stores/auth-user'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { captureException } from '@sentry/vue'
import date from 'date-and-time'
import { useAlertsStore } from '@/stores/alerts'
import apiService from '@/services/api.service'

export default {
  components: {
    BaseButton,
    BaseModal,
    DateTimeInput,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    LoadingSpinner
  },
  props: ['modelValue', 'project_id'],
  setup() {
    let authUserStore = useAuthUserStore()
    return { authUserStore }
  },
  data() {
    return {
      isLoading: false,
      intervals: [
        'complete', // gesamt
        'custom',
        'last_30_days',
        'last_month',
        'last_three_months',
        'last_half_year',
        'last_year',
        'this_year'
      ],
      timeInterval: 'complete',
      start: null,
      end: null,
      selectedUser: null,
      users: [
        {
          id: 'all',
          full_name: 'Alle',
          email: '',
          active: true
        }
      ],
      focusTimeInput: null,
      queryUser: '',
      queryUserHandler: null,
      errors: {}
    }
  },
  mounted() {
    let user = useAuthUserStore().user
    this.users.push({
      id: user.id,
      full_name: user.full_name,
      email: user.email,
      active: true
    })
    if (user.admin >= 9) {
      this.selectedUser = this.users[0]
    } else {
      this.selectedUser = this.users[1]
    }
  },
  computed: {
    showModal: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    filteredUsers() {
      let users = this.users
      if (this.queryUser == null || this.queryUser == undefined || this.queryUser == '') {
        return users.slice(0, 50)
        // .slice(0, 10)
      }
      return users
        .filter(
          (item) =>
            (this.selectedUser && this.selectedUser.id == item.id) ||
            item?.full_name?.toLowerCase().includes(this.queryUser.toLowerCase()) ||
            item?.email?.toLowerCase().includes(this.queryUser.toLowerCase())
        )
        .slice(0, 50)
    },
    selectedTime() {
      let now = new Date(Date.now())
      let start = null
      let end = null

      switch (this.timeInterval) {
        case 'custom':
          var tempStart = new Date(Date.parse(this.start))
          var tempEnd = new Date(Date.parse(this.end))
          start = new Date(tempStart.getFullYear(), tempStart.getMonth(), tempStart.getDate())
          end = new Date(tempEnd.getFullYear(), tempEnd.getMonth(), tempEnd.getDate())
          break
        case 'last_30_days':
          end = now
          start = date.addDays(now, -30)
          break
        case 'last_month':
          end = new Date(now.getFullYear(), now.getMonth(), 1)
          start = date.addMonths(end, -1)
          break
        case 'last_three_months':
          end = new Date(now.getFullYear(), now.getMonth(), 1)
          start = date.addMonths(end, -3)
          break
        case 'last_half_year':
          end = new Date(now.getFullYear(), now.getMonth(), 1)
          start = date.addMonths(end, -6)
          break
        case 'last_year':
          end = new Date(now.getFullYear(), 0, 1)
          start = date.addYears(end, -1)
          break
        case 'this_year':
          start = new Date(now.getFullYear(), 0, 1)
          end = date.addYears(start, 1)
          break
        default:
          break
      }
      if (start && start instanceof Date) {
        start = start.toISOString()
      }
      if (end && end instanceof Date) {
        end = end.toISOString()
      }
      return { start: start, end: end }
    }
  },
  watch: {
    queryUser(newVal) {
      if (newVal != undefined && newVal != null && newVal != '') {
        this.callUserSearch()
      }
    },
    showModal(newVal) {
      if (!newVal) {
        this.resetModal()
      } else {
        this.$nextTick(() => {
          this.$refs.focus_object?.focus()
        })
      }
    }
  },
  methods: {
    async downloadExport() {
      this.isLoading = true
      try {
        let infoId = useAlertsStore().info(this.$t('exports.download.start'))
        await timeEntryService.export({
          start: this.selectedTime.start,
          end: this.selectedTime.end,
          project_id: this.project_id,
          user_ids:
            this.selectedUser && this.selectedUser.id != 'all' ? [this.selectedUser.id] : null,
          all_users: this.selectedUser && this.selectedUser.id == 'all'
        })
        useAlertsStore().removeId(infoId)
        useAlertsStore().success(this.$t('exports.download.success'))
      } catch (error) {
        if (error?.response?.status) {
          if (error.response.status == 403) {
            useAlertsStore().error(this.$t('errors.no_permissions'))
          } else if (error.response.status == 422) {
            this.errors = apiService.convertValidationErrors(error)
            captureException(error)
          } else if (error.response.status == 500) {
            useAlertsStore().error(this.$t('errors.ups'))
          }
        } else {
          captureException(error)
          console.error(error)
          useAlertsStore().error(this.$t('errors.ups'))
        }
      }
      this.isLoading = false
    },
    closeModal() {
      this.showModal = false
    },
    resetModal() {
      this.selectedUser = null
      this.start = null
      this.end = null
      if (this.authUserStore?.user?.admin >= 9) {
        this.selectedUser = this.users.find((item) => item.id == 'all')
      } else {
        this.selectedUser = this.users.find((item) => item.id == this.authUserStore.user?.id)
      }
    },
    resetQueryUser(isOpen = false) {
      if (isOpen) {
        return
      }
      timelinkStoresService.setOrRenewTimeout(
        'ExportModal',
        'queryUser',
        () => {
          this.queryUser = ''
        },
        120
      )
    },
    addOrUpdate(entry) {
      let o_entry = null
      if ((o_entry = this.users.find((item) => entry.id === item.id))) {
        Object.entries(entry).forEach((item) => {
          o_entry[item[0]] = item[1]
        })
      } else {
        this.users.push(entry)
      }
    },
    callUserSearch() {
      if (this.authUserStore.user.admin < 9) {
        return
      }
      timelinkStoresService.setOrRenewTimeout(
        'ExportModal',
        'callSearch',
        async () => {
          let data = (await userService.search(this.queryUser)) ?? []
          data.forEach((item) => {
            this.addOrUpdate(item)
          })
        },
        250
      )
    },
    removeFromList(user) {
      this.selectedUser = this.selectedUser.filter((item) => item.id != user.id)
    }
  }
}
</script>
