<template>
  <div class="w-full h-full flex items-center justify-center">
    <span class="text-4xl text-red-500 font-bold"> {{ $t('errors.404') }} </span>
  </div>
</template>

<script>
// vue default export
export default {
  components: {},
  setup() {
    return {}
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
