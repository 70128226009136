class Desktop {
  isDesktop() {
    if (window.timelinkApi == undefined || window.timelinkApi == null) {
      return false
    }
    return true
  }
  getConfig(key, defaultReturn = undefined) {
    if (!this.isDesktop()) {
      return defaultReturn
    }
    try {
      return window.timelinkApi.config.get(key) != undefined
        ? window.timelinkApi.config.get(key)
        : defaultReturn
    } catch (error) {
      return defaultReturn
    }
  }
  setConfig(key, value) {
    if (!this.isDesktop()) {
      return
    }
    try {
      window.timelinkApi.config.set(key, value)
    } catch (error) {
      return
    }
  }
  getStatus(key, defaultReturn = undefined) {
    if (!this.isDesktop()) {
      return undefined
    }
    try {
      return window.timelinkApi.status.get(key) != undefined
        ? window.timelinkApi.status.get(key)
        : defaultReturn
    } catch (error) {
      return defaultReturn
    }
  }
  setStatus(key, value) {
    if (!this.isDesktop()) {
      return
    }
    try {
      window.timelinkApi.status.set(key, value)
    } catch (error) {
      return
    }
  }
  setTrackingActive() {
    if (!this.isDesktop()) {
      return
    }
    try {
      this.setStatus('trackingActive', true)
    } catch (error) {
      return
    }
  }

  setTrackingInactive() {
    if (!this.isDesktop()) {
      return
    }
    try {
      this.setStatus('trackingActive', false)
    } catch (error) {
      return
    }
  }
  setLoggedIn() {
    if (!this.isDesktop()) {
      return
    }
    try {
      this.setStatus('isLoggedIn', true)
    } catch (error) {
      return
    }
  }
  setLoggedOut() {
    if (!this.isDesktop()) {
      return
    }
    try {
      this.setStatus('isLoggedIn', false)
    } catch (error) {
      return
    }
  }
}

export default new Desktop()
