import { captureException } from '@sentry/vue'
import { defineStore, acceptHMRUpdate } from 'pinia'

export const useVersionsStore = defineStore('versions', {
  state: () => ({
    versions: []
  }),
  getters: {},
  actions: {
    addActualVersion() {
      try {
        // eslint-disable-next-line no-undef
        let actualVersion = APP_VERSION
        this.addVersion(actualVersion)
      } catch (error) {
        captureException(error)
      }
    },
    addVersion(version) {
      let found = this.versions.find((item) => item.version == version)
      if (!found) {
        this.versions.push({
          version: version,
          timestamp: Date.now(),
          date: new Date(Date.now()).toISOString()
        })
      }
    }
  },
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVersionsStore, import.meta.hot))
}
