<template>
  <div
    x-show=""
    class="fixed bottom-12 mx-auto min-w-30 max-w-lg left-0 right-0 flex justify-center z-50"
  >
    <div class="flex flex-col space-y-2 max-h-36 overflow-y-auto w-full max-w-lg">
      <TransitionGroup>
        <template v-for="alert in alertsStore.alerts" :key="alert.id">
          <div
            class="drop-shadow rounded-full flex space-x-4 w-full justify-between"
            :class="{
              'bg-blue-500 text-white': alert.type == 'info',
              'bg-green-500 text-white': alert.type == 'success',
              'bg-red-500 text-white': alert.type == 'error',
              'bg-yellow-500 text-white': alert.type == 'warning',
              'bg-mossgray text-white': !types.includes(alert.type)
            }"
          >
            <div class="py-2 px-6">
              <span>{{ alert.message }}</span>
            </div>
            <div class="flex items-center pr-4 text-lg leading-3">
              <button
                type="button"
                :class="{
                  'text-white': alert.type == 'info',
                  'text-white': alert.type == 'success',
                  'text-white': alert.type == 'error',
                  'text-white': alert.type == 'warning',
                  'text-apricot': !types.includes(alert.type)
                }"
                @click="close(alert.id)"
              >
                <font-awesome-icon :icon="['fa-kit', 'tl-cross']" />
              </button>
            </div>
          </div>
        </template>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import { $t } from '@/config/i18n'
import { useAlertsStore } from '@/stores/alerts'
const alertsStore = useAlertsStore()
const types = ['success', 'info', 'warning', 'error']
// alertsStore.$subscribe((mutation, state) => {
//   console.log(mutation, state)
// })
function close(id) {
  alertsStore.removeId(id)
}
</script>
