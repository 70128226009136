<template>
  <div class="">
    <Popover v-slot="{ open }">
      <PopoverButton class="button-white !px-2 aspect-square" @click="toggleState(open)">
        <!-- TODO: Filter unread notifications only -->
        <font-awesome-icon :icon="['fa-kit', 'tl-bell']" size="lg" :class="{ 'fa-shake text-red-600':notificationsStore.getUnreadCount > 0 }" style="--fa-animation-duration: 1.5s;" />
      </PopoverButton>

      <PopoverPanel class="absolute z-10 w-96 right-5 top-20 -mt-2 bottom-20 drop-shadow">
        <div class="grid grid-cols-5 overflow-y-auto max-h-full gap-0 rounded-xl border border-mossgray-200 bg-white divide-y divide-mossgray-200 items-center">
          <div class="col-span-3 px-4 py-2 font-bold">
            Benachrichtigungen ({{  notificationsStore.notifications.length }})
          </div>
          <div v-if="notificationsStore.getUnreadCount > 1" class="col-end-6 col-span-1 text-end px-4 py-3">
            <button class="button button-mossgray" @click="notificationsStore.markAllAsRead" v-tippy="$t('notifications.markAllAsRead')">
              <font-awesome-icon :icon="['far', 'check-double']" size="sm" />
            </button>
          </div>          
          <template v-if="notificationsStore.notifications.length == 0">
            <div class="p-2 py-10 text-center w-full col-span-5">
              Keine Benachrichtigungen
            </div>
          </template>
          <!-- TODO: Notification counter overview X info, X error -->

          <template v-for="notification in notificationsStore.notifications" :key="notification.id">
            <div class="col-span-5">
              <div
                class="px-4 py-3 w-full"
                :class="{
                  'bg-mossgray-50': notification.read_at,
                  'bg-white': !notification.read_at
                }"
              >
                <div class="flex flex-col">
                  <div class="font-bold">
                    {{ $t('notifications.type.' + notification.type) }}
                    <div class="text-xs text-right float-right font-normal">
                      {{ new Date(Date.parse(notification.created_at)).toLocaleString("de-DE", {
                        hour: "numeric",
                        minute: "numeric",
                        year: "2-digit",
                        month: "numeric",
                        day: "numeric",
                      }) }}
                      <span v-if="false">
                        {{ notification.read_at ? 'und gelesen um' : '' }}
                      {{
                        notification.read_at
                          ? new Date(Date.parse(notification.read_at)).toLocaleString()
                          : ''
                      }}</span>
                    </div>
                  </div>
                  <span>{{ $t('notifications.text.' + notification.type, notification.data.minutes) }}</span>
                  <div class="place-self-end mt-1">
                  <button
                    @click="notification.severity.type == 'error' ? notificationsStore.markAsRead(notification.id) : notificationsStore.delete(notification.id)"
                    v-show="!notification.read_at"
                    class="button-mossgray !px-2.5 !py-1.5"
                    v-tippy="$t('notifications.markAsRead')"
                  >
                    <font-awesome-icon :icon="['fa-kit', 'tl-check']" class="text-lg" />
                  </button>
                  <button
                    @click="notificationsStore.delete(notification.id)"
                    v-show="notification.read_at"
                    class="button button-red !px-2.5 !py-1.5"
                    v-tippy="$t('delete')"
                  >
                    <font-awesome-icon :icon="['fa-kit', 'tl-trash']" class="text-lg"/>
                  </button>                  
                  <!-- <button
                    @click="notificationsStore.delete(notification.id)"
                    :class="{ 'row-span-2': notification.read_at }"
                  >
                    Delete
                  </button> -->
                </div>                  
                </div>

                <div v-if="false">
                  {{ notification }}
                  <router-link v-if="notification.action?.route" :to="notification.action?.route">{{
                    notification?.action?.i18n
                  }}</router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
      </PopoverPanel>
    </Popover>
  </div>
</template>

<script setup>
import { $t } from '@/config/i18n'
import { useNotificationsStore } from '@/stores/notifications'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ref } from 'vue'

const notificationCenter = ref(null)
const notificationsStore = useNotificationsStore()

function toggleState(open) {
  if(!open) {
    notificationsStore.notifications.forEach(item => {
      item.popupHidden = true
    })
  }
}

</script>
