<template>
  <!-- TODO: hidden div nötig? -->
  <div style="display: hidden"></div>

  <!-- TODO: do we still need this udpate info? -->
  <div v-if="updateAvailable && appStore.showUpdateMessage" v>
    <div class="fixed left-0 right-0 flex justify-center max-w-xl mx-auto bottom-12 min-w-96">
      <div class="flex p-2 px-8 space-x-4 text-white rounded-full shadow-sm bg-mossgray">
        <span>
          {{ $t('update_available') }}
        </span>
        <button type="button" class="text-apricot hover:underline" @click="updateFrontend">
          {{ $t('update_restart') }}
        </button>
      </div>
    </div>
  </div>

  <!-- TODO: do we still need this spinner? Discuss! -->
  <div v-if="feature.canUseFeature('retrySymbol', authUserStore.user)">
    <div class="fixed text-black bottom-4 right-52" v-if="retryTimer">
      <div class="relative">
        <div class="retrySpinner"></div>
        <div
          class="absolute w-12 h-12 bottom-0 left-0 text-center -mt-[15px] -ml-[15px] border-[8px] border-transparent font-bold flex justify-center items-center rounded-full">
          <span class="text-sm text-center">
            {{ retryTimer.seconds }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- TODO: move styles to css file -->
<style type="text/css">
.retrySpinner {
  @apply w-12 h-12 bottom-0 left-0 bg-white;
  content: '';
  margin: -15px auto auto -15px;
  position: absolute;
  border-width: 8px;
  border-style: solid;
  border-color: #333333 #ccc #ccc;
  border-radius: 100%;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
</style>

<script>
import { useControlPanelStore } from '@/stores/control-panel'
import { useAuthUserStore } from '@/stores/auth-user'
import { useClientsStore } from '@/stores/clients'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { useApiStore } from '@/stores/api'
import desktopService from '@/services/desktop.service'
import deckService from '@/services/deck.service'
import { usePanelStore } from '@/stores/panel'
import panelService from '@/services/panel.service'
import { watch } from 'vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useOverlayWindowStore } from '@/stores/overlay-window'
import featureFlagsService from '@/services/feature-flags.service'
import { useCompanyStore } from '@/stores/company'
import * as Sentry from '@sentry/vue'
import { useAppStore } from '@/stores/app'
import { useTimer } from 'vue-timer-hook'
import idleService from '@/services/idle.service'
import { useIdleStore } from '@/stores/idle'
import { useNotificationsStore } from '@/stores/notifications'

export default {
  components: {
  },
  setup() {
    const controlPanelStore = useControlPanelStore
    const authUserStore = useAuthUserStore()
    const overlayWindowStore = useOverlayWindowStore()
    const appStore = useAppStore()
    const feature = featureFlagsService
    // this.actualTimeEntry = controlPanelStore().activeTimeEntry
    return {
      feature,
      appStore,
      controlPanelStore,
      authUserStore,
      overlayWindowStore
    }
  },
  data() {
    this.controlPanelStore.actualView
    return {
      activeTimeEntryId: null,
      hasActiveTimeEntry: null,
      overlayWindowEvent: null,
      watcher: {},
      isInBackground: false,
      lastInitTime: null,
      lastIntervalTime: null,
      lastState: 'blur',
      lastStateChange: null,
      feedbackWidget: null,
      updateAvailable: true,
      retryTimer: null
    }
  },
  mounted() {
    this.lastState = 'focus'
    this.lastStateChange = Date.now()

    this.feedbackWidget = window.feedback.createWidget({
      enableScreenshot: false,
      useSentryUser: {
        name: 'fullName',
        email: 'email'
      },
      triggerLabel: this.$t('sentry.triggerLabel'),
      formTitle: this.$t('sentry.formTitle'),
      submitButtonLabel: this.$t('sentry.submitButtonLabel'),
      cancelButtonLabel: this.$t('sentry.cancelButtonLabel'),
      confirmButtonLabel: this.$t('sentry.confirmButtonLabel'),
      addScreenshotButtonLabel: this.$t('sentry.addScreenshotButtonLabel'),
      removeScreenshotButtonLabel: this.$t('sentry.removeScreenshotButtonLabel'),
      nameLabel: this.$t('sentry.nameLabel'),
      namePlaceholder: this.$t('sentry.namePlaceholder'),
      emailLabel: this.$t('sentry.emailLabel'),
      emailPlaceholder: this.$t('sentry.emailPlaceholder'),
      isRequiredLabel: this.$t('sentry.isRequiredLabel'),
      messageLabel: this.$t('sentry.messageLabel'),
      messagePlaceholder: this.$t('sentry.messagePlaceholder'),
      successMessageText: this.$t('sentry.successMessageText')
    })
    this.feedbackWidget.appendToDom()

    Sentry.setUser({
      fullName: useAuthUserStore().user.first_name + ' ' + useAuthUserStore().user.last_name,
      email: useAuthUserStore().user.email
    })
    this.updateAvailable = this.appStore.canUpdate()
    useAppStore().$subscribe(() => {
      this.updateAvailable = useAppStore().canUpdate()
    })

    useAuthUserStore().fetchUpdate()
    useApiStore().checkIfQueueIsNeeded()
    useAuthUserStore().fetch()
    useClientsStore().initFetch()
    useProjectsStore().initFetch()
    useServicesStore().initFetch()
    useTimeEntryStore().loadActive()
    useTimeEntryStore().initFetch()
    useCompanyStore().initFetch()
    useNotificationsStore().initFetch()
    useApiStore().$subscribe(() => {
      this.checkIfApiQueue()
      this.updateAvailable = useAppStore().canUpdate()
    })
    this.$echo
      .private('user.' + this.authUserStore.userId)
      .listen('TimeEntryCreated', (payload) => {
        useTimeEntryStore().resetIds()
        useTimeEntryStore().addOrUpdate(payload.timeEntry, true)
      })
      .listen('.timeEntry.created', (payload) => {
        useTimeEntryStore().resetIds()
        useTimeEntryStore().addOrUpdate(payload.timeEntry, true)
      })
      .listen('TimeEntryUpdated', (payload) => {
        useTimeEntryStore().resetIds()
        if (payload.timeEntry.id == useTimeEntryStore().activeTimeEntry) {
          timelinkStoresService.removeTimeout('control', 'safelyFetchActiveTimeEntryAfterOverlay')
        }
        useTimeEntryStore().addOrUpdate(payload.timeEntry, true)
      })
      .listen('.timeEntry.updated', (payload) => {
        useTimeEntryStore().resetIds()
        if (payload.timeEntry.id == useTimeEntryStore().activeTimeEntry) {
          timelinkStoresService.removeTimeout('control', 'safelyFetchActiveTimeEntryAfterOverlay')
        }
        useTimeEntryStore().addOrUpdate(payload.timeEntry, true)
      })
      .listen('TimeEntryDeleted', (payload) => {
        useTimeEntryStore().resetIds()
        useTimeEntryStore().removeId(payload.timeEntryId)
      })
      .listen('.timeEntry.deleted', (payload) => {
        useTimeEntryStore().resetIds()
        useTimeEntryStore().removeId(payload.timeEntryId)
      })
      .listen('UserUpdated', (payload) => {
        // useAuthUserStore().user = payload
        if (payload?.user?.id && useAuthUserStore()?.user?.id) {
          if (useAuthUserStore().user.id == payload.user.id) {
            useAuthUserStore().internalUpdateUserData(payload.user)
            this.$i18n.locale = payload.user.language ?? 'de'
          }
        } else {
          throw new Error(
            'Payload does not have user infos in it.' +
            payload.user.toString() +
            ' ' +
            useAuthUserStore()?.user?.id +
            ' ' +
            useAuthUserStore().userId
          )
        }
      })
      .listen('.user.updated', (payload) => {
        // useAuthUserStore().user = payload
        if (payload?.user?.id && useAuthUserStore()?.user?.id) {
          if (useAuthUserStore().user.id == payload.user.id) {
            useAuthUserStore().internalUpdateUserData(payload.user)
            this.$i18n.locale = payload.user.language ?? 'de'
          }
        } else {
          throw new Error(
            'Payload does not have user infos in it.' +
            payload.user.toString() +
            ' ' +
            useAuthUserStore()?.user?.id +
            ' ' +
            useAuthUserStore().userId
          )
        }
      })
      .listen('FrontendVersionUpdateForced', (payload) => {
        if (!payload?.version) {
          return
        }
        useAppStore().checkVersionDiff(payload.version)
        if (useAppStore().canUpdate()) {
          this.updateFrontend()
        }
      })
      .listen('.frontend.version.update.forced', (payload) => {
        if (!payload?.version) {
          return
        }
        useAppStore().checkVersionDiff(payload.version)
        if (useAppStore().canUpdate()) {
          this.updateFrontend()
        }
      })
      .listen('.notification.updated', (payload) => {
        useNotificationsStore().addOrUpdate(notification)
      })
      .listen('.notification.deleted', (payload) => {
        useNotificationsStore().removeId(payload.notificationId)
      })
      .notification((notification) => {
        useNotificationsStore().addOrUpdate(notification)
      })
    // .listen('')
    this.$echo
      .private('company.' + this.authUserStore.companyId)
      .listen('ClientUpdated', (payload) => {
        let exists = useClientsStore().updateIfExists(payload.client)
        if (exists) {
          useClientsStore().sortEntries()
        }
      })
      .listen('.client.updated', (payload) => {
        let exists = useClientsStore().updateIfExists(payload.client)
        if (exists) {
          useClientsStore().sortEntries()
        }
      })
      .listen('ClientDeleted', (payload) => {
        useClientsStore().removeId(payload.clientId)
        useClientsStore().sortEntries()
      })
      .listen('.client.deleted', (payload) => {
        useClientsStore().removeId(payload.clientId)
        useClientsStore().sortEntries()
      })
      .listen('ServiceCreated', (payload) => {
        useServicesStore().addOrUpdate(payload.service)
        useServicesStore().sortEntries()
      })
      .listen('.service.created', (payload) => {
        useServicesStore().addOrUpdate(payload.service)
        useServicesStore().sortEntries()
      })
      .listen('ServiceUpdated', (payload) => {
        useServicesStore().addOrUpdate(payload.service)
        useServicesStore().sortEntries()
      })
      .listen('.service.updated', (payload) => {
        useServicesStore().addOrUpdate(payload.service)
        useServicesStore().sortEntries()
      })
      .listen('ServiceDeleted', (payload) => {
        console.log('Service', payload)

        useServicesStore().removeId(payload.serviceId)
        useServicesStore().sortEntries()
      })
      .listen('.service.deleted', (payload) => {
        console.log('Service', payload)
        useServicesStore().removeId(payload.serviceId)
        useServicesStore().sortEntries()
      })
      .listen('ProjectUpdated', (payload) => {
        let exists = useProjectsStore().updateIfExists(payload.project)
        if (exists) {
          useProjectsStore().sortEntries()
        }
      })
      .listen('.project.updated', (payload) => {
        console.log(payload)
        let exists = useProjectsStore().updateIfExists(payload.project)
        if (exists) {
          useProjectsStore().sortEntries()
        }
      })
      .listen('ProjectDeleted', (payload) => {
        useProjectsStore().removeId(payload.projectId)
        useProjectsStore().sortEntries()
      })
      .listen('.project.deleted', (payload) => {
        useProjectsStore().removeId(payload.projectId)
        useProjectsStore().sortEntries()
      })
      .listen('CompanyUpdated', (payload) => {
        useCompanyStore().internalUpdate(payload.company)
      })
      .listen('.company.updated', (payload) => {
        useCompanyStore().internalUpdate(payload.company)
      })
    this.$echo
      .private('frontend')
      .listen('FrontendVersionUpdated', (payload) => {
        if (!payload?.version) {
          return
        }

        useAppStore().checkVersionDiff(payload.version)
      })
      .listen('.frontend.version.updated', (payload) => {
        if (!payload?.version) {
          return
        }

        useAppStore().checkVersionDiff(payload.version)
      })
      .listen('FrontendVersionUpdateForcedAll', (payload) => {
        if (!payload?.version) {
          return
        }
        useAppStore().checkVersionDiff(payload.version)
        if (useAppStore().canUpdate()) {
          this.updateFrontend()
        }
      })
      .listen('.frontend.version.update.force.all', (payload) => {
        if (!payload?.version) {
          return
        }
        useAppStore().checkVersionDiff(payload.version)
        if (useAppStore().canUpdate()) {
          this.updateFrontend()
        }
      })
    if (desktopService.isDesktop()) {
      window.addEventListener('storage', this.overlayStorageEvent)
    }
    this.checkIfApiQueue()
    // this.$log.info('Loading Page', Date.now())

    // window.timelinkApi.setTrackingActive() bzw. setTrackingInactive()
    useTimeEntryStore().$subscribe((mutation, state) => {
      if (
        !Object.hasOwn(mutation, 'payload') ||
        !Object.hasOwn(mutation.payload, 'activeTimeEntry')
      ) {
        return
      }
      if (
        mutation?.payload &&
        mutation.payload?.activeTimeEntry != null &&
        mutation?.payload?.activeTimeEntry == this.activeTimeEntryId
      ) {
        return
      }
      if (
        (mutation?.payload &&
          mutation.payload.activeTimeEntry &&
          mutation.payload.activeTimeEntry != null) ||
        (this.activeTimeEntryId != null &&
          this.activeTimeEntryId != mutation.payload.activeTimeEntry &&
          mutation.payload.activeTimeEntry != null) ||
        (this.activeTimeEntryId != null &&
          this.activeTimeEntryId != state.activeTimeEntry &&
          state.activeTimeEntry != null)
      ) {
        // if(usePanelStore().view == 'recording') {

        // }
        if (
          timelinkStoresService.isTempId(this.activeTimeEntryId) &&
          !timelinkStoresService.isTempId(mutation.payload.activeTimeEntry)
        ) {
          this.activeTimeEntryId = mutation.payload.activeTimeEntry
          return
        }
        if (!usePanelStore().wizardFinished) {
          usePanelStore().updateView('recording')
        } else {
          let activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
          if (
            activeTimeEntry &&
            (activeTimeEntry.client_id == null ||
              (activeTimeEntry.project_id == null && state.requiredFields.includes('project_id')) ||
              activeTimeEntry.service_id == null)
          ) {
            usePanelStore().updateView('recording')
          }
        }
        if (!this.hasActiveTimeEntry) {
          usePanelStore().updateView('recording')

          this.hasActiveTimeEntry = true
          this.activeTimeEntryId = state.activeTimeEntry
          desktopService.setTrackingActive()
          panelService.reloadActualPanelView()
        }
      } else {
        if (usePanelStore().view != 'start') {
          usePanelStore().updateView('start')
        }

        if (this.hasActiveTimeEntry) {
          this.hasActiveTimeEntry = false
          this.activeTimeEntryId = null
          desktopService.setTrackingInactive()
          panelService.reloadActualPanelView()
        }
      }
      // TODO: Change to payload
    })
    if (navigator.hid != undefined && navigator.hid != null) {
      navigator.hid.addEventListener('connect', (event) => {
        deckService.init(() => {
          timelinkStoresService.setOrRenewTimeout(
            'control',
            'deviceConnected',
            () => {
              const panel = panelService.loadActualPanelView()
              panel.forEach((item) => {
                deckService.setId(
                  item.id,
                  item.type == 'image' && item.image != null ? item.type : 'text',
                  item.type == 'image'
                    ? item.image != null
                      ? item.image
                      : item.text
                    : item.text,
                  {
                    color: item.deckColor ?? item.color ?? null,
                    bgColor: item.deckBgColor ?? item.bgColor ?? null
                  },
                  !item.callback ? null : item.callback(item, panel, 'deck')
                )
              })
            },
            500
          )
        })
      })

      navigator.hid.addEventListener('disconnect', () => {
        deckService.init()
      })
      this.$nextTick(() => {
        deckService.init(() => {
          timelinkStoresService.setOrRenewTimeout(
            'control',
            'deviceConnected',
            () => {
              const panel = panelService.loadActualPanelView()
              panel.forEach((item) => {
                deckService.setId(
                  item.id,
                  item.type == 'image' && item.image != null ? item.type : 'text',
                  item.type == 'image'
                    ? item.image != null
                      ? item.image
                      : item.text
                    : item.text,
                  {
                    color: item.deckColor ?? item.color ?? null,
                    bgColor: item.deckBgColor ?? item.bgColor ?? null
                  },
                  !item.callback ? null : item.callback(item, panel, 'deck')
                )
              })
            },
            500
          )
        }, true)
      })
    }

    deckService.clearAll()

    const panel = panelService.loadActualPanelView()
    panel.forEach((item) => {
      deckService.setId(
        item.id,
        item.type == 'image' && item.image != null ? item.type : 'text', // type
        item.type == 'image' ? (item.image != null ? item.image : item.text) : item.text, // data
        {
          color: item.deckColor ?? item.color ?? null,
          bgColor: item.deckBgColor ?? item.bgColor ?? null
        },
        !item.callback ? null : item.callback(item, panel, 'deck')
      )
      this.watcher[item.id] = watch(item, (newVal) => {
        deckService.setId(
          newVal.id,
          newVal.type == 'image' && newVal.image != null ? newVal.type : 'text',
          newVal.type == 'image'
            ? newVal.image != null
              ? newVal.image
              : newVal.text
            : newVal.text, // data
          {
            color: newVal.deckColor ?? newVal.color ?? null,
            bgColor: newVal.deckBgColor ?? newVal.bgColor ?? null
          },

          !newVal.callback ? null : newVal.callback(newVal, panel, 'deck')
        )
      })
    })
    usePanelStore().$subscribe(() => {
      this.$nextTick(() => {
        panelService.loadActualPanelView()
      })
    })
    timelinkStoresService.setOrRenewTimeout(
      'controlPanel',
      'clearDeck',
      () => {
        if (!useTimeEntryStore().activeTimeEntry) {
          usePanelStore().updateView('start')
        } else {
          usePanelStore().updateView('recording')
        }
      },
      200
    )


    window.addEventListener('beforeunload', async () => {
      deckService.resetToLogo()
    })
    this.lastInitTime = Date.now()
    this.lastIntervalTime = Date.now()
    try {
      if (document.hasFocus()) {
        this.focus()
      } else {
        this.blur()
      }
    } catch (error) {
      this.lastState = 'focus'
    }
    this.lastStateChange = Date.now()
    timelinkStoresService.setOrRenewInterval(
      'controlPanel',
      'backgroundCheck',
      this.checkIfIsBackground,
      2000
    )
    window.addEventListener('focus', this.focus)
    window.addEventListener('blur', this.blur)


    this.startIdleService()

    // TODO: MS, 14.10.2024: What is Test?
    // Test
    useNotificationsStore().fetchUnread()
  },

  beforeUnmount() {
    if (this.feedbackWidget) {
      this.feedbackWidget.removeFromDom()
    }
    if (this.retryHandler) {
      clearTimeout(this.retryHandler)
    }
    Object.values(this.watcher).forEach((item) => {
      item()
    })
    deckService.clearAll()
    this.activeTimeEntryId = null
    this.$echo.leave('user.' + this.authUserStore.userId)
    this.$echo.leave('company.' + this.authUserStore.companyId)
    if (desktopService.isDesktop()) {
      window.removeEventListener('storage', this.overlayStorageEvent)
    }
    timelinkStoresService.removeInterval('controlPanel', 'backgroundCheck')
    window.removeEventListener('focus', this.focus)
    window.removeEventListener('blur', this.blur)
  },
  methods: {
    checkIfApiQueue() {
      if (
        (!this.retryTimer || this.retryTimer.isExpired) &&
        (useApiStore().needQueue || useApiStore().connectionError)
      ) {
        const time = new Date()
        const seconds = 15
        time.setSeconds(time.getSeconds() + seconds)
        this.retryTimer = useTimer(time)
        timelinkStoresService.setOrRenewInterval(
          'controlPanel',
          'retryCalls',
          () => {
            this.retryTimer = null

            useApiStore().createEntry.timeEntries?.forEach((item) => {
              useTimeEntryStore().createEntry(item, true)
            })
            useApiStore().updateEntry.timeEntries?.forEach((item) => {
              useTimeEntryStore().updateId(item, true)
            })
            useApiStore().deleteEntry.timeEntries?.forEach((item) => {
              useTimeEntryStore().deleteId(item, true)
            })

            useApiStore().updateEntry.notifications?.forEach((item) => {
              useNotificationsStore().markAsRead(item, true)
            })
            useApiStore().deleteEntry.notifications?.forEach((item) => {
              useNotificationsStore().delete(item, true)
            })

            if (!useApiStore().connectionError && !useApiStore().needQueue) {
              timelinkStoresService.removeInterval('controlPanel', 'retryCalls')
            }
          },
          seconds * 1000
        )
      }
    },
    overlayStorageEvent(event) {
      if (event.key == useOverlayWindowStore().$id) {
        const newVal = JSON.parse(event.newValue).last_push
        if (newVal > useOverlayWindowStore().last_push) {
          useOverlayWindowStore().last_push = newVal
          timelinkStoresService.setOrRenewTimeout(
            'control',
            'safelyFetchActiveTimeEntryAfterOverlay',
            () => {
              useTimeEntryStore().loadActive()
            },
            3000
          )
        }
      }
    },

    /*
     * Refs: https://developer.chrome.com/blog/timer-throttling-in-chrome-88?hl=de
     * https://developer.mozilla.org/en-US/docs/Web/API/setInterval
     * https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#timeouts_in_inactive_tabs
     * The execution of timeouts and intervals will be reduced to 1 second and will be packed inside a special queue
     */
    checkIfIsBackground() {
      if (Date.now() - this.lastIntervalTime > 30 * 1000) {
        this.lastIntervalTime = Date.now()
        this.isInBackground = true
      } else if (this.isInBackground) {
        this.lastIntervalTime = Date.now()
        this.isInBackground = false
        this.updateAllStores()
      }
      this.lastIntervalTime = Date.now()
    },
    focus() {
      if (
        this.lastState == 'blur' &&
        (Date.now() - this.lastStateChange > 5 * 60 * 1000 || this.lastStateChange == null)
      ) {
        this.updateAllStores()
      }
      timelinkStoresService.removeTimeout('controlPanel', 'updateFrontendInBackground')
      this.lastState = 'focus'
      this.lastStateChange = Date.now()
    },
    blur() {
      this.lastState = 'blur'
      this.lastStateChange = Date.now()
      timelinkStoresService.setOrRenewTimeout(
        'controlPanel',
        'updateFrontendInBackground',
        () => {
          if (useAppStore().canUpdate() && !useAppStore().showModalState) {
            this.updateFrontend()
          }
        },
        60 * 1000
      )
    },
    updateAllStores() {
      timelinkStoresService.setOrRenewTimeout(
        'controlPanel',
        'refreshAllStores',
        () => {
          useTimeEntryStore().fetchUpdates()
          useTimeEntryStore().loadActive()
          useClientsStore().fetchUpdates()
          useProjectsStore().fetchUpdates()
          useServicesStore().fetchUpdates()
          useCompanyStore().fetch()
          useAuthUserStore().fetchUpdate()
        },
        100
      )
    },
    updateFrontend() {
      useAppStore().clientUpdateNeeded = false
      Sentry.close()
      useAppStore().updateFrontend()
      setTimeout(() => {
        window.location.reload()
      }, 20)
    },
    async startIdleService() {
      idleService.init()
      idleService.listen(() => {
        let userState = idleService.userState()
        let screenState = idleService.screenState()
        useIdleStore().addState(userState, screenState)
        if (screenState == 'locked') {
          usePanelStore().lockIfUnlocked()
        }
        if (screenState == 'unlocked') {
          usePanelStore().unlockIfLocked()
        }
      })
      await idleService.start()
    }
  }
}
</script>
