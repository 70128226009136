import { defineStore, acceptHMRUpdate } from 'pinia'

export const useOverlayWindowStore = defineStore('overlay-window', {
  state: () => ({
    last_push: 0
  }),
  getters: {},
  actions: {},
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOverlayWindowStore, import.meta.hot))
}
