<template>
  <div>
    <LoadingSpinner v-model="isLoading" :overAll="true" />
    <div v-if="isOverlayWindow && loggedIn">
      <div class="w-full h-full overflow-auto">
        <RouterView />
      </div>
    </div>
    <div class="relative flex flex-row z-0 text-mossgray" v-else>
      <div class="relativ z-40">
        <div class="relative flex h-screen z-40">
          <div
            class="flex flex-col flex-shrink-0 w-32 justify-between bg-ivory-200 text-mossgray border-r border-mossgray-200 text-center space-y-2"
            v-if="loggedIn"
          >
            <!-- TODO: Move navigation to own component? -->
            <div class="flex flex-col flex-shrink-0 w-32 text-center">
              <div class="flex justify-center items-center px-8 mt-8 mb-8">
                <img class="w-full drop-shadow-sm" :src="imgLogo" />
              </div>

              <router-link
                v-if="is_dev"
                to="/"
                class="py-4 hover:text-mossgray hover:bg-ivory"
                active-class="menu-active-background text-mossgray"
                ><font-awesome-icon :icon="['fa-kit', 'tl-home']" class="mb-1 text-4xl" fixed-width /><br />{{
                  $t('menu.dashboard')
                }}</router-link
              >

              <!-- TODO: Own component for Navigatiom Item? -->
              <router-link
                to="/calendar"
                class="py-4 hover:text-mossgray border-r border-r-mossgray-200 border-t border-b border-ivory-200 hover:bg-ivory-400 hover:border-ivory-400"
                active-class="menu-active-background text-mossgray border-r !border-r-ivory border-t border-t-mossgray-200 border-b border-b-mossgray-200"
                ><font-awesome-icon
                  :icon="['fa-kit', 'tl-calendar']"
                  class="mb-1 text-4xl"
                  fixed-width
                /><br />
                {{ $t('menu.calendar') }}</router-link
              >

              <router-link
                to="/reports"
                v-if="is_dev"
                class="py-4 hover:text-mossgray hover:bg-ivory"
                active-class="menu-active-background text-mossgray"
                ><font-awesome-icon :icon="['fa-kit', 'tl-chart-pie-1']" class="mb-1 text-4xl" fixed-width /><br />{{
                  $t('menu.reports')
                }}</router-link
              >
              
              <router-link
                to="/panel"
                class="py-4 hover:text-mossgray border-r border-r-mossgray-200 border-t border-b border-ivory-200 hover:bg-ivory-400 hover:border-ivory-400"
                active-class="menu-active-background text-mossgray border-r !border-r-ivory border-t border-t-mossgray-200 border-b border-b-mossgray-200"
                ><font-awesome-icon
                  :icon="['fa-kit', 'tl-panel']"
                  class="mb-1 text-4xl"
                /><br />{{ $t('menu.panel') }}</router-link
              >
              <div class="" v-if="loggedIn && isRouterReady && authUserStore.companyId != null">
                <ControlPanel />
              </div>
            </div>
            <div class="flex flex-col flex-shrink-0 w-32 pb-4 text-center">
              <router-link
                to="/myAccount"
                class="py-4 hover:text-mossgray border-r border-r-mossgray-200 border-t border-b border-ivory-200 hover:bg-ivory-400 hover:border-ivory-400"
                active-class="menu-active-background text-mossgray border-r !border-r-ivory border-t border-t-mossgray-200 border-b border-b-mossgray-200"
              >
                <font-awesome-icon :icon="['fa-kit', 'tl-profil']" class="mb-1 text-4xl" />
                <div>{{ $t('menu.myAccount') }}</div>
              </router-link>
              <router-link
                :to="{ name: 'Settings' }"
                class="py-4 hover:text-mossgray border-r border-r-mossgray-200 border-t border-b border-ivory-200 hover:bg-ivory-400 hover:border-ivory-400"
                active-class="menu-active-background-secondary text-mossgray border-r !border-r-ivory border-t border-t-mossgray-200 border-b border-b-mossgray-200"
              >
                <font-awesome-icon :icon="['fa-kit', 'tl-settings']" class="mb-1 text-4xl" />
                <div>{{ $t('menu.settings') }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col site-background w-full max-h-screen overflow-hidden ">
        <HeaderComponent
          v-if="
            loggedIn &&
            (currentRouteName == 'Dashboard' || currentRouteName == 'Tracking' || currentRouteName == 'Panel') &&
            authUserStore.companyId != null
          ">
        </HeaderComponent>

        <div class="flex-grow overflow-y-auto pt-2">
          <!-- <RouterView /> -->
          <router-view v-slot="{ Component }">
            <keep-alive :exclude="/LoginUser|SSO|ResetPassword/" :include="/TrackingComponent/">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
  </div>
  <AlertComponent />
  <NotificationPopup />
</template>

<script>
import { useAuthUserStore } from '@/stores/auth-user'
import ControlPanel from '@/components/ControlPanel.vue'
import TimeEntryModalComponent from '@/components/modals/TimeEntryModalComponent.vue'
import { useAppStore } from './stores/app'
import axios from 'axios'
import semver from 'semver'
import { useTimeEntryStore } from './stores/timeEntry'
import deck from '@/services/deck.service'
import { useRouter } from 'vue-router'
import featureFlagsService from '@/services/feature-flags.service'
import desktopService from './services/desktop.service'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useVersionsStore } from './stores/versions'
import BaseButton from '@/components/general/BaseButton.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import imgLogo from './assets/timelink_bookmark_signet_elfenbeinRGB.png'
import AlertComponent from './components/AlertComponent.vue'
import NotificationPopup from './components/NotificationPopup.vue'

import '@fontsource-variable/red-hat-display'
import '@fontsource-variable/space-grotesk'
import '@fontsource/space-mono/400.css';
import '@fontsource/space-mono/700.css';

export default {
  components: {
    ControlPanel,
    TimeEntryModalComponent,
    LoadingSpinner,
    BaseButton,
    HeaderComponent,
    AlertComponent,
    NotificationPopup
  },
  setup() {
    const authUserStore = useAuthUserStore()
    const feature = featureFlagsService
    const appStore = useAppStore()
    const timeEntryStore = useTimeEntryStore()
    const logo = imgLogo
    return {
      authUserStore,
      timeEntryStore,
      appStore,
      feature,
      imgLogo: logo
    }
  },

  data() {
    return {
      isRouterReady: false,
      createTimeEntryModal: true,
      is_dev: import.meta.env.DEV,
      checkVersionInterval: null,
      isOverlayWindow: false,
      lastLoginState: false,
      hasActiveTimeEntry: false,
      authSub: null,
      isLoading: false,
      stopwatch: null,
      isLoadingTimeEntry: false
    }
  },
  created() {
    useRouter()
      .isReady()
      .then(() => {
        this.isRouterReady = true
        this.updateIsOverlayWindow()
      })
  },
  mounted() {
    this.authSub = useAuthUserStore().$subscribe(() => {
      if (this.loggedIn != this.lastLoginState) {
        this.lastLoginState = this.loggedIn
        if (this.lastLoginState) {
          this.onLoggedIn()
        }
      }
    })
    if (this.loggedIn) {
      this.onLoggedIn()
    }
    setInterval(
      () => {
        if (this.is_dev) {
          return
        }
        try {
          axios.get('/version.json').then((response) => {
            if (semver.valid(response.data)) {
              // eslint-disable-next-line no-undef
              useAppStore().checkVersionDiff(response.data)
            }
          })
        } catch (error) {
          //
        }
      },
      5 * 60 * 1000
    )
    useTimeEntryStore().$subscribe((mutation, state) => {
      this.hasActiveTimeEntry = state.activeTimeEntry != undefined && state.activeTimeEntry != null
    })
    this.hasActiveTimeEntry =
      useTimeEntryStore().activeTimeEntry != undefined &&
      useTimeEntryStore().activeTimeEntry != null

    useVersionsStore().addActualVersion()
  },
  unmounted() {
    if (this.authSub) {
      this.authSub()
    }
  },
  computed: {
    loggedIn() {
      return this.authUserStore.isAuthenticated
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  methods: {
    updateIsOverlayWindow() {
      this.isOverlayWindow = this.$route.name == 'OverlayWindow'
    },

    onLoggedIn() {
      this.$i18n.locale = this.authUserStore.user?.language ?? 'de'
      desktopService.setLoggedIn()
    }
  }
}
</script>
