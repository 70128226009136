<template>
  <form @submit.prevent="save" ref="form">
    <BaseModal v-model="showModal" @close-modal="closeModal">
      <template v-slot:header> {{ $t('create_project') }} </template>
      <div class="px-4 py-6 sm:p-8">
        <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('name')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="text"
                name="name"
                id="name"
                autocomplete="name"
                v-model="project.name"
                class="input w-full"
                ref="name_input"
              />
            </div>
          </div>

          <div class="sm:col-span-6">
            <label for="info" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('info')
            }}</label>
            <div class="mt-2">
              <textarea
                :disabled="!canEdit"
                type="text"
                name="info"
                id="info"
                autocomplete="Off"
                v-model="project.info"
                class="textarea w-full"
              ></textarea>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="active" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('active')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="checkbox"
                name="active"
                id="active"
                autocomplete="Off"
                v-model="project.active"
                class="checkbox"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="billable" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('billable')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="checkbox"
                name="billable"
                id="billable"
                autocomplete="Off"
                v-model="project.billable"
                class="checkbox"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="color" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('color')
            }}</label>
            <div class="mt-2">
              <input
                :disabled="!canEdit"
                type="color"
                name="color"
                id="color"
                autocomplete="Off"
                v-model="project.color"
                class="input"
              />
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="w-full flex justify-end space-x-4 items-center">
          <BaseButton class="button-gray" @click="closeModal" type="button">{{
            $t('cancel')}}
          </BaseButton>

          <BaseButton class="button-mossgray" type="submit" @click="save">
            {{ $t('create') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script setup>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import apiService from '@/services/api.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { $t } from '@/config/i18n'
import { useAlertsStore } from '@/stores/alerts'
import { captureException } from '@sentry/vue'

const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const alertsStore = useAlertsStore()
const route = useRoute()
const isLoading = ref(false)

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
const name_input = ref(null)

watch(
  () => showModal.value,
  async (newVal) => {
    if (newVal) {
      await nextTick()
      name_input.value?.focus()
    }
  }
)

function closeModal() {
  showModal.value = false
  project.value = {
    name: null,
    info: null,
    active: true,
    billable: true,
    color: '#183F6A'
  }
}

// TODO: Add validation errors
const errors = ref({})
const project = ref({
  name: null,
  info: null,
  active: true,
  billable: true,
  color: '#183F6A'
})
const canEdit = ref(true)

async function save() {
  isLoading.value = true

  if (companyStore.company.pull_provider || authUserStore.user.admin < 5) {
    useAlertsStore().error(this.$t('errors.no_permissions'))
    return
  }
  try {
    let response = await apiService.create(import.meta.env.VITE_API_URL + '/api/v1/projects', {
      client_id: route.params.client_id,
      ...project.value
    })
    if (response.success) {
      showModal.value = false
      alertsStore.success($t('successfully.created_project', { projectName: project.value.name }))
      project.value = {
        name: null,
        info: null,
        active: true,
        billable: true,
        color: '#183F6A'
      }
    } else {
      alertsStore.error($t('errors.ups_create'))
    }
  } catch (error) {
    if (
      error?.response?.status == 403 &&
      error?.response?.data?.message == $t('errors.no_subscription')
    ) {
      // TODO: Add better error handling!
      alertsStore.error($t('errors.no_subscription'))
    } else if (apiService.checkIfServerError(error)) {
      //
    } else if (error?.response?.status == 422) {
      errors.value = apiService.convertValidationErrors(error)
    } else {
      captureException(error)
    }
    //
  }
  isLoading.value = false
}

//TODO: Update to work with edit + escape key
</script>
