import apiService from '@/services/api.service'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { useAuthUserStore } from './auth-user'
import axios from 'axios'

export const useCompanyStore = defineStore('company', {
  /**
   *
   * @returns {{id: ?string, company: ?{
   * id: string,
   * name: string,
   * address: ?string,
   * city: ?string,
   * zip: ?string,
   * country: ?string,
   * phone: ?string,
   * email: ?string,
   * invoice_email: ?string,
   * oauth: Object,
   * force_oauth: boolean,
   * oauth_provider: ?string,
   * push_provider: ?string,
   * pull_provider: ?string,
   * subscription: ?{
   *  status: ?string,
   * product: ?string,
   * quantity: ?number,
   * trial: boolean,
   * trial_ends_at: ?string,
   * ends_at: ?string
   *  }
   * }}
   * }
   */
  state: () => ({
    id: null,
    company: null
  }),
  persist: true,
  getters: {},
  actions: {
    initFetch() {
      if (this.id == null) {
        this.id = useAuthUserStore().companyId
      }
      this.fetch()
    },
    async fetch(callback) {
      apiService.fetchId(
        import.meta.env.VITE_API_URL + '/api/v1/companies',
        this.id,
        {},
        (data) => {
          this.internalUpdate(data.data)
          if (typeof callback == 'function') {
            callback()
          }
        },
        () => {}
      )
    },
    internalUpdate(entry) {
      this.id = entry.id
      if (this.company) {
        if (this.company.tl == undefined || this.company.tl == null) {
          this.company.tl = {
            isDirty: false,
            origin: null
          }
        }
        this.company.tl.origin = { ...entry }

        if (!this.company.tl.isDirty) {
          if (entry.updated_at != this.company.updated_at) {
            Object.entries(entry).forEach((item) => {
              this.company[item[0]] = item[1]
            })
          }
        }
      } else {
        this.company = {
          ...entry,
          tl: {
            origin: { ...entry },
            isDirty: false
          }
        }
      }
    },
    async updateData(data) {
      if (data) {
        Object.entries(data).forEach((item) => {
          this.company[item[0]] = item[1]
        })
      }
      return axios
        .patch(import.meta.env.VITE_API_URL + '/api/v1/companies/' + this.company.id, {
          name: this.company.name,
          address: this.company.address,
          email: this.company.email,
          invoice_email: this.company.invoice_email,
          city: this.company.city,
          zip: this.company.zip,
          phone: this.company.phone
        })
        .then((resp) => {
          if (resp.data.success) {
            this.company.tl.isDirty = false
            this.internalUpdate(resp.data.data)
          }
          return resp.data
        })
        .catch((error) => {
          if (
            Object.hasOwn(error, 'response') &&
            Object.hasOwn(error.response, 'status') &&
            (error.response.status == 401 || error.response.status == 419)
          ) {
            const authUserStore = useAuthUserStore()
            authUserStore.logout()
            window.location.reload()
          }
          throw error
        })
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyStore, import.meta.hot))
}
