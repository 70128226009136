import { ref } from 'vue'

/**
 * @property {ref<IdleDetector>} idleDetector
 * @property {ref<boolean>} needPermission
 * @property {ref<boolean>} started
 */
class IdleState {
  idleDetector = null
  needPermission = false
  started = false

  constructor() {
    this.idleDetector = ref(null)
    this.needPermission = ref(false)
    this.started = ref(false)
    this.init()
  }

  init() {
    if (window.IdleDetector == undefined) {
      return
    }
    this.idleDetector.value = new window.IdleDetector()
  }

  async start() {
    if (window.IdleDetector == undefined) {
      return
    }
    if (this.idleDetector == null) {
      this.init()
    }
    window.test = this.idleDetector
    try {
      await this.idleDetector.value.start({ threshold: 5 * 60 * 1000 })
      this.started.value = true
      this.needPermission.value = false
    } catch (error) {
      this.needPermission.value = true
    }
  }

  isStarted() {
    return this.started.value
  }

  /**
   *
   * @returns {?('locked'|'unlocked')}
   */
  screenState() {
    if (!this.started.value) {
      return ''
    }
    return this.idleDetector.value.screenState
  }

  /**
   *
   * @returns {?('active'|'idle')}
   */
  userState() {
    if (!this.started.value) {
      return ''
    }
    return this.idleDetector.value.userState
  }

  async requestPermission() {
    if (window.IdleDetector == undefined) {
      return
    }
    try {
      let res = await window.IdleDetector.requestPermission()
      if (res == 'granted') {
        this.start()
        return true
      } else if (res == 'denied') {
        return false
      }
    } catch (error) {
      //
    }
  }

  listen(callback) {
    if (this.idleDetector && this.idleDetector.value) {
      this.idleDetector.value.onchange = callback
    }
  }
}

export default new IdleState()
