import { usePanelStore } from '@/stores/panel'
import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from '@/services/timelink-stores.service'
import { nextTick, watch } from 'vue'
import { useClientsStore } from '@/stores/clients'

// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { useProjectsStore } from '@/stores/projects'
import { setCPS } from './cups'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} id
 * @param {*} item
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: panelCallback,
 *  interval: panelCallback,
 *  intervalTime: ?number,
 *  timeout: panelCallback,
 *  timeoutTime: ?number,
 *  init: panelCallback
 * }} _options
 */
function setClient(
  panel,
  arr,
  id,
  item,
  // eslint-disable-next-line no-unused-vars
  _options = {
    color: null,
    bgColor: null,
    deckColor: null,
    deckBgColor: null,
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  setCPS(panel, arr, id, item, _options)
}

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} id
 * @param {*} item
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: panelCallback,
 *  interval: panelCallback,
 *  intervalTime: ?number,
 *  timeout: panelCallback,
 *  timeoutTime: ?number,
 *  init: panelCallback
 * }} _options
 */
function setSelectableClient(
  panel,
  arr,
  id,
  item,
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  let dims = panel.getDimensions()
  panel.setIdForObjectWithAcronym(
    arr,
    id,
    {
      type: 'text',
      text: item.acronym ?? item.name ?? '',
      init: (panelItem) => {
        //   panel.getImageFor(item, panelItem)
        let watcher = null
        watcher = (client) => {
          return watch(client, () => {
            timelinkStoresService.removeWatcher('panel', panel.getHandlerName(panelItem))
            panel.updateIdForObjectWithAcronym(
              arr,
              id,
              { text: useClientsStore().getDisplayName(client.id) },
              item
            )
            timelinkStoresService.setOrRenewWatcher(
              'panel',
              panel.getHandlerName(panelItem),
              watcher(item)
            )
          })
        }
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          panel.getHandlerName(panelItem),
          watcher(item)
        )
      },
      callback: () => {
        return () => {
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
          if (activeTimeEntry) {
            if (activeTimeEntry.client_id != item.id) {
              activeTimeEntry.project_id = null
            }
            activeTimeEntry.client_id = item.id
            timelinkStoresService.updateTl(activeTimeEntry)
            useTimeEntryStore().delayUpdateId(activeTimeEntry.id, 1000, true)
            if (usePanelStore().wizardFinished) {
              if (activeTimeEntry.project_id == null) {
                usePanelStore().updateView('selectProject')
              } else {
                usePanelStore().updateView('recording')
              }
            } else {
              usePanelStore().updateView('selectProject')
            }
            useProjectsStore().fetchActiveCount(item, { client_id: item.id })
            useProjectsStore().fetch(
              {
                client_id: item.id,
                limit: dims.columns * dims.rows * 2,
                page: 1,
                orders: [
                  {
                    column: 'name',
                    direction: 'asc'
                  }
                ]
              },
              false,
              true
            )
          }
        }
      }
    },
    item
  )
}

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} id
 * @param {*} item
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: panelCallback,
 *  interval: panelCallback,
 *  intervalTime: ?number,
 *  timeout: panelCallback,
 *  timeoutTime: ?number,
 *  init: panelCallback
 * }} _options
 */
function setActiveClient(
  panel,
  arr,
  id,
  activeTimeEntry,
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  panel.setIdForObjectWithAcronym(
    arr,
    id,
    {
      type: 'image',
      text: useClientsStore().getDisplayName(activeTimeEntry.client_id),
      color: '#fff',
      bgColor: '#FC9854',
      init: (item) => {
        let watcher = null
        watcher = (timeEntry) => {
          let timeWatch = watch(timeEntry, async () => {
            timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
            const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

            if (!activeTimeEntry) {
              timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
              return
            }
            if (activeTimeEntry.client_id) {
              panel.updateIdForObjectWithAcronym(
                arr,
                id,
                {
                  text: useClientsStore().getDisplayName(activeTimeEntry.client_id),
                  color: '#fff',
                  bgColor: '#FC9854'
                },
                useClientsStore().getId(activeTimeEntry.client_id)
              )
            } else {
              await nextTick()
              if (usePanelStore().view != 'selectClient') {
                usePanelStore().updateView('selectClient', null)
              }
            }
            timelinkStoresService.setOrRenewWatcher(
              'panel',
              panel.getHandlerName(item),
              watcher(activeTimeEntry)
            )
          })
          let client = useClientsStore().getId(activeTimeEntry.client_id)
          let clientWatch = client
            ? watch(client, () => {
                timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
                panel.updateIdForObjectWithAcronym(
                  arr,
                  id,
                  {
                    text: useClientsStore().getDisplayName(activeTimeEntry.client_id),
                    color: '#fff',
                    bgColor: '#FC9854'
                  },
                  useClientsStore().getId(activeTimeEntry.client_id)
                )
                timelinkStoresService.setOrRenewWatcher(
                  'panel',
                  panel.getHandlerName(item),
                  watcher(activeTimeEntry)
                )
              })
            : () => {
                //
              }
          return () => {
            timeWatch()
            clientWatch()
          }
        }
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          panel.getHandlerName(item),
          watcher(activeTimeEntry)
        )
      },
      callback: () => {
        return () => {
          usePanelStore().updateView('selectClient', null)
        }
      }
    },
    useClientsStore().getId(activeTimeEntry.client_id)
  )
}

export { setClient, setActiveClient, setSelectableClient }
