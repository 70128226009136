<!-- TODO: Translate -->
<template>
  <div class="flex flex-col items-center justify-center flex-grow w-full min-h-screen">
    <LoadingSpinner v-model="showSpinner" :fullPage="true"></LoadingSpinner>
    <div class="w-full max-w-xl space-y-8 bg-white border rounded-2xl border-mossgray-200">
      <div class="w-full p-12 pt-0 space-y-8">
        <div>
          <div class="flex justify-center px-16 py-8 mt-6 -mb-2">
            <img :src="img" />
          </div>
          <h2 class="mt-6 text-3xl font-extrabold text-center">
            Passwort zurücksetzen
          </h2>
          <p class="mt-2 text-base leading-4 text-center">
            <router-link to="/login" class="text-sm font-medium hover:underline">
              Zurück zum Login
            </router-link>
          </p>
        </div>
        <div v-if="!success">
          <form class="space-y-4" @submit.prevent="sendForm">
            <input type="hidden" name="remember" value="true" />
            <div class="-space-y-px rounded-md shadow-sm">
              <div>
                <!-- TODO: Harmonise input -->
                <label for="email-address" class="sr-only">Email address</label>
                <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required
                  class="relative block w-full px-3 py-2 placeholder-gray-500 border rounded-md appearance-none border-mossgray-200 focus:outline-none focus:ring-mossgray focus:border-mossgray sm:text-sm"
                  placeholder="E-Mail Adresse" />
              </div>
            </div>

            <div class="text-sm text-red-500" v-text="message"></div>

            <div>
              <button type="submit"
                class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md group bg-mossgray hover:bg-white hover:text-mossgray ring-2 ring-mossgray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mossgray">
                <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                  <!-- TODO: Use FontAwesome Icon -->
                  <!-- Heroicon name: solid/lock-closed -->
                  <svg class="w-5 h-5 text-white group-hover:text-mossgray" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
                Anfordern
              </button>
            </div>
          </form>
        </div>
        <div v-else>
          <div class="flex items-center">
            <div class="p-4 basis-1/4">
              <font-awesome-icon :icon="['fa-kit', 'tl-envelope-check']" class="w-32 h-32 -ml-6 text-apricot" />
            </div>
            <div class="space-y-2 basis-3/4">
              <p>
                <span class="text-lg font-semibold">Deine Anfrage war erfolgreich.</span><br />
                Du erhälst uns eine E-Mail mit einem Link zum Zurücksetzen deines Passworts.
              </p>
              <p class="text-sm text-gray-500">
                Solltest Du innerhalb weniger Minuten keine E-Mail erhalten, so prüfe bitte deinen SPAM-Ordner.
              </p>
            </div>
          </div>
        </div>

        <!-- TODO: Can be removed? -->
        <div class=""></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { captureException } from '@sentry/vue'
import { $t } from '@/config/i18n'
import img from '@/assets/timelink_primary_logo_5f_moosgrauRGB.svg'
const route = useRoute()

const email = ref('')
const showSpinner = ref(false)
const success = ref(false)
const message = ref(null)

async function sendForm() {
  showSpinner.value = true
  try {
    let response = await axios.post(import.meta.env.VITE_API_URL + '/api/v1/forgotPassword', {
      email: email.value
    })
    if (response.data.success) {
      success.value = true
    } else {
      if (response.data.error_code) {
        if (response.data.error_code == 1) {
          message.value =
            $t('resetpassword.errors.force_oauth') +
            ' ' +
            (response.data.provider
              ? $t('login.errors.forced_oauth.' + response.data.provider)
              : '')
        } else if (response.data.error_code == 2) {
          message.value = $t('login.errors.user_not_active')
        }
      }
    }
  } catch (error) {
    if (error?.response?.status == 422) {
      errors.value = apiService.convertValidationErrors(error.response.data)
    } else if (error?.response?.status == 429) {
      message.value = $t('errors.429')
    } else {
      console.log(error)
      message.value = 'Es ist ein Fehler aufgetreten.'
    }
  }
  showSpinner.value = false
}
</script>
