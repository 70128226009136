<template>
  <div class="flex flex-col h-full min-h-screen px-6 pt-6 site-background basis-full">
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>

    <div class="flex items-center justify-between w-full mb-5">
      <h1 class="text-2xl font-bold">
        {{ $t('edit_service') }}
      </h1>
    </div>

    <div class="pb-16 space-y-8 divide-y divide-gray-900/10">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="w-full h-full col-span-1">
          <div class="flex flex-col items-center justify-center w-full h-full gap-2">
            <div
              class="flex items-center justify-center w-32 h-32 text-sm leading-6 text-center text-gray-600 aspect-square squircle-clip"
              :style="{
                color: !service.image_id
                  ? timelinkService.generateForegroundColor(
                    service.color ? service.color : timelinkService.generateBackgroundColor(service)
                  )
                  : null,
                backgroundColor: !service.image_id
                  ? service.color
                    ? service.color
                    : timelinkService.generateBackgroundColor(service)
                  : null
              }">
              <!-- {{ $t('service_edit_description') }} -->
              <img v-if="service.image_id" :src="timelinkService.getImageFor(service)" class="object-contain" />
              <span class="text-4xl font-bold" v-else v-text="timelinkService.acronymOrShortName(service)"></span>
            </div>
            <div class="flex flex-col mt-3 space-y-2">
              <BaseButton class="justify-center button button-lime" @click="showImageUpload = true">
                {{ $t('upload_image') }}
              </BaseButton>
              <BaseButton class="button button-raspberry" v-if="service.image_id" @click="showImageDeleteModal = true">
                {{ $t('delete') }}
              </BaseButton>
            </div>
          </div>
        </div>

        <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" @submit.prevent="save">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('name')
                }}</label>
                <div class="mt-2">
                  <input :disabled="!canEdit" type="text" name="name" id="name" autocomplete="name"
                    v-model="service.name" class="w-full input" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="info" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('info')
                }}</label>
                <div class="mt-2">
                  <textarea :disabled="!canEdit" type="text" name="info" id="info" autocomplete="Off"
                    v-model="service.info" class="w-full textarea"></textarea>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="active" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('active')
                }}</label>
                <div class="mt-2">
                  <input :disabled="!canEdit" type="checkbox" name="active" id="active" autocomplete="Off"
                    v-model="service.active" class="checkbox" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="color" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('color')
                }}</label>
                <div class="mt-2">
                  <input :disabled="!canEdit" type="color" name="color" id="color" autocomplete="Off"
                    v-model="service.color" class="input" />
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-center px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-6" :class="{
            'justify-end': companyStore.company.pull_provider,
            'justify-between': !companyStore.company.pull_provider
          }">
            <BaseButton class="button-red" type="button" @click="showDeleteModal = true"
              v-if="!companyStore.company.pull_provider" :disabled="!canEdit">
              {{ $t('delete') }}
            </BaseButton>

            <button v-if="!companyStore.company.pull_provider" :disabled="!canEdit" type="submit"
              class="button-mossgray">
              {{ $t('save') }}
            </button>
            <div v-else>
              {{ $t('managed_through') }}

              <span class="font-medium">{{ companyStore.company.pull_provider }}</span>.
            </div>
          </div>
        </form>
      </div>
    </div>
    <BaseModal v-model="showDeleteModal" @close-modal="showDeleteModal = false">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.service') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showDeleteModal = false" ref="cancelDeleteModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-red" :disabled="!timer || timer.seconds > 0" @click="deleteService"><span
              class="w-6" v-if="timer && timer.seconds > 0"> ({{ timer.seconds }}) </span>{{ $t('delete') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <ImageDeleteModal v-model="showImageDeleteModal" @confirmed="deleteImage"></ImageDeleteModal>
  </div>
  <SquirclePath></SquirclePath>
  <template>
    <ImageUploadModal v-model="showImageUpload" :uploadUrl="uploadUrl" @upload-successfully="onUploadSuccessfully" />
  </template>

</template>

<script setup>
import LoadingSpinner from '../LoadingSpinner.vue'
import apiService from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useServicesStore } from '@/stores/services'
import BaseModal from '@/components/modals/BaseModal.vue'
import { useTimer } from 'vue-timer-hook'
import { captureException } from '@sentry/vue'
import { useAlertsStore } from '@/stores/alerts'
import { nextTick, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { $t } from '@/config/i18n'
import SquirclePath from '@/components/general/SquirclePath.vue'
import ImageUploadModal from '@/components/modals/ImageUploadModal.vue'
import ImageDeleteModal from '@/components/modals/ImageDeleteModal.vue'
import datetime from '@/lib/datetime'

const timelinkService = timelinkStoresService
const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const servicesStore = useServicesStore()
const alertsStore = useAlertsStore()
const router = useRouter()
const route = useRoute()

const canEdit = ref(false)
const service = ref({
  name: null,
  info: null
})
const isLoading = ref(true)
const services = ref([])
const saveComplete = ref(null)
const showDeleteModal = ref(false)
const timer = ref(null)
const cancelDeleteModalButton = ref(null)

watch(() => showDeleteModal.value, async (newVal) => {
  if (newVal) {
    timer.value = timer.value = useTimer(new Date(Date.now() + 2 * 1000), true)
    await nextTick()
    cancelDeleteModalButton.value?.focus()
  }
})

const showImageDeleteModal = ref(false)
const cancelImageDeleteModalButton = ref(null)
const showImageUpload = ref(false)
const uploadUrl = ref(
  import.meta.env.VITE_API_URL + '/api/v1/services/' + route.params.service_id + '/image'
)

watch(
  () => showImageDeleteModal.value,
  async (newVal) => {
    if (newVal) {
      await nextTick()
      cancelImageDeleteModalButton.value?.focus()
    }
  }
)

onMounted(() => {
  canEdit.value = authUserStore.user.admin >= 9 && !companyStore.company.pull_provider
  fetch()
})


async function fetch() {
  isLoading.value = true
  try {
    let data = await apiService.fetchId(
      import.meta.env.VITE_API_URL + '/api/v1/services',
      route.params.service_id
    )
    service.value = data.data
  } catch (error) {
    if (error?.response?.status == 404) {
      router.push({ name: 'ServiceList' })
      alertsStore.error($t('errors.service.not_found'))
    } else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }
  isLoading.value = false
}
async function save() {
  if (companyStore.company.pull_provider || authUserStore.user.admin < 5) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }

  isLoading.value = true
  try {
    let response = await apiService.update(
      import.meta.env.VITE_API_URL + '/api/v1/services',
      route.params.service_id,
      {
        ...service.value
      }
    )
    if (response.success) {
      servicesStore.updateIfExists(response.data)
      alertsStore.successfullySaved()
    } else {
      console.error(response)
      alertsStore.error($t('errors.ups_save'))
    }
  } catch (error) {
    if (
      error?.response?.status == 403 &&
      error.response.data.message == 'You are not subscribed!'
    ) {
      // TODO: Add better error handling!
      alertsStore.error($t('errors.no_subscription'))
    } else if (apiService.checkIfServerError(error)) {
      //
    } else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
      console.log(error)
    }
  }
  isLoading.value = false
}

async function deleteService() {
  isLoading.value = true
  try {
    let response = await apiService.delete(
      import.meta.env.VITE_API_URL + '/api/v1/services',
      route.params.service_id
    )
    if (response.success) {
      alertsStore.success($t('settings.services.deleted_successfully', { name: service.value.name }))
      router.push({
        name: 'ServicesList'
      })
    }
    else {
      console.log(response)
      alertsStore.error($t('errors.ups_delete'))
    }
  }
  catch (error) {
    if (
      error?.response?.status == 403 &&
      error.response.data.message == 'You are not subscribed!'
    ) {
      // TODO: Add better error handling!
      alertsStore.error($t('errors.no_subscription'))
    } else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }
  isLoading.value = false

}

function onUploadSuccessfully(data) {
  service.value = data
  servicesStore.updateIfExists(data)
  service.value.tl = {
    image: null
  }

}

async function deleteImage() {
  isLoading.value = true
  showImageDeleteModal.value = false
  const image_id = service.value.image_id
  if (!image_id) {
    service.value.image_id = null
    if (service.value?.tl?.image) {
      service.value.tl.image = null
    } else {
      service.value.tl = {
        image: null
      }
    }
    return
  }
  try {
    let imageCacheId = null
    try {
      let respImage = await axios.get(
        import.meta.env.VITE_API_URL + '/api/v1/img/' + service.value.image_id,
        { validateStatus: false }
      )
      imageCacheId = respImage.id
    } catch (error) {
      //
    }
    let response = await apiService.delete(
      import.meta.env.VITE_API_URL + '/api/v1/img',
      service.value.image_id
    )
    if (response.success) {
      alertsStore.success($t('successfully.deleted_image'))
      service.value.image_id = null
      if (service.value?.tl?.image) {
        service.value.tl.image = null
      } else {
        service.value.tl = {
          image: null
        }
      }
      if (imageCacheId) {
        axios.storage.remove(imageCacheId)
      }
      servicesStore.addOrUpdate({
        id: service.value.id,
        image_id: service.value.image_id,
        updated_at: datetime.iso(Date.now())
      })
    } else {
      alertsStore.error($t('errors.ups_delete'))
    }
  } catch (error) {
    if (error?.response?.status == 404) {
      alertsStore.success($t('successfully.deleted_image'))
      service.value.image_id = null
      if (service.value?.tl?.image) {
        service.value.tl.image = null
      } else {
        service.value.tl = {
          image: null
        }
      }
      if (imageCacheId) {
        axios.storage.remove(imageCacheId)
      }
      servicesStore.addOrUpdate({
        id: service.value.id,
        image_id: service.value.image_id,
        updated_at: datetime.iso(Date.now())
      })
    } else if (apiService.checkIfServerError(error)) {
      //
    } else {
      alertsStore.error($t('errors.ups'))
      console.log(error)
      captureException(error)
    }
  }
  isLoading.value = false
}
</script>
