<template>
  <div class="pl-2 pr-6 py-2 flex flex-row group items-center">
    <div class="w-12 h-12 flex justify-center items-center mr-4">
      <div
        class="w-10 h-10 aspect-square flex justify-center items-center squircle-clip"
        :style="{
          color: !item.image_id
            ? timelinkService.generateForegroundColor(
                item.color ? item.color : timelinkService.generateBackgroundColor(item)
              )
            : null,
          backgroundColor: !item.image_id
            ? item.color
              ? item.color
              : timelinkService.generateBackgroundColor(item)
            : null
        }"
      >
        <img
          v-if="item.image_id"
          :src="timelinkService.getImageFor(item)"
          class="squircle-clip aspect-square w-48 roboto-regular"
        />
        <span
          class="text-lg font-bold"
          v-else
          v-text="timelinkService.acronymOrShortName(item)"
        ></span>
      </div>
    </div>

    <div class="flex flex-col">
      <div>
        <div
          class="font-medium"
          :class="{
            'text-mossgray-500 line-through ': !item.active
          }"
        >
          <router-link
            :to="{ name: 'ProjectEdit', params: { project_id: item.id } }"
            class="flex items-center gap-2 leading-5"
            >
            {{ item.name }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timelinkStoresService from '@/services/timelink-stores.service'
// vue default export
export default {
  props: ['project'],
  components: {},
  setup() {
    const timelinkService = timelinkStoresService
    return {
      timelinkService
    }
  },
  created() {
    this.item = { ...this.project }
  },
  mounted() {
    this.$echo
      .private('project.' + this.item.id)
      .listen('ProjectUpdated', (payload) => {
        this.item = { ...this.item, ...payload.project }
      })
      .listen('.project.updated', (payload) => {
        this.item = { ...this.item, ...payload.project }
      })
  },
  beforeUnmount() {
    this.$echo.leave('project.' + this.item.id)
  },
  data() {
    return {
      item: {}
    }
  },
  computed: {},
  methods: {}
}
</script>
