<template>
  <div class="flex" v-if="activeTimeEntry?.id">
    <BaseButton @click="openTimeEntryModal"
      class="button button-white relative !rounded-r-none font-bold !pl-4 !pr-3 hover:!bg-white hover:!text-mossgray">
      <font-awesome-icon class="!text-red-500 me-2 text-lg" :icon="['fa-kit', 'tl-stopwatch']" fade
        style="--fa-animation-duration: 3s; --fa-fade-opacity: 0.3" />
      <div class="font-normal w-14 font-grotesk" v-if="stopwatch != null">
        <span v-if="stopwatch.days > 0"><span v-text="stopwatch.days.toString().padStart(2, '0')"></span>:</span>
        <span v-text="stopwatch.hours.toString().padStart(2, '0')"></span>:<span
          v-text="stopwatch.minutes.toString().padStart(2, '0')"></span>:<span
          v-text="stopwatch.seconds.toString().padStart(2, '0')"></span>
      </div>
    </BaseButton>
    <BaseButton type="button" class="button button-white relative -ml-px !rounded-l-none !pl-3 !pr-4" @click="stopTime"
      v-tippy="$t('stop_recording')">
      <font-awesome-icon :icon="['fa-kit', 'tl-stop']" class="text-lg" />
    </BaseButton>
  </div>

  <template v-else>
    <BaseButton @click="createNewTimeEntry" class="button-lime !text-mossgray" :disabled="isLoading">
      <font-awesome-icon class="text-lg me-2" :icon="['fa-kit', 'tl-play']" /> {{ $t('start_recording') }}
    </BaseButton>
  </template>
  <TimeEntryModalComponent v-model="showTimeEntryModal" v-model:item="timeEntryModalModel" :is-active-time-entry="true"
    :stop-active-entry="wantsToStopEntry" :focus-on="focusOn" />
</template>

<script setup>
import { nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, watchEffect } from 'vue'
import BaseButton from '@/components/general/BaseButton.vue'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useStopwatch } from 'vue-timer-hook'
import TimeEntryModalComponent from '@/components/modals/TimeEntryModalComponent.vue'
import timelinkStoresService from '@/services/timelink-stores.service'

const isLoading = ref(false)
const activeTimeEntry = ref(null)
const stopwatch = ref(null)
const timeEntryStore = useTimeEntryStore()
const showTimeEntryModal = ref(false)
const timeEntryModalModel = ref(null)
const wantsToStopEntry = ref(false)
const focusOn = ref(null)

const createNewTimeEntry = async () => {
  isLoading.value = true
  await timeEntryStore.createNewActiveTimeEntry()
  isLoading.value = false
}

function openTimeEntryModal(stopEntry = false) {
  focusOn.value = null
  wantsToStopEntry.value = typeof stopEntry == 'boolean' ? stopEntry : false
  if (stopEntry instanceof Event) {
    if (stopEntry.type == 'open-active-time-entry-modal-panel') {
      focusOn.value = 'description'
    }
  }
  if (!timeEntryModalModel.value || timeEntryModalModel.value.id == 'tempNewEntry') {
    timeEntryModalModel.value = timeEntryStore.getActiveTimeEntry
  }
  if (!timeEntryModalModel.value) {
    timeEntryStore.internalUpdateActiveTimeEntry()
    return
  }
  showTimeEntryModal.value = true
}

const stopTime = async () => {
  if (!activeTimeEntry.value) {
    return
  }
  if (Date.now() - Date.parse(activeTimeEntry.value.started_at) < 60_000) {
    await timeEntryStore.stopActiveEntry()
    activeTimeEntry.value = timeEntryStore.getActiveTimeEntry
    return
  }
  let validate = timeEntryStore.validate(activeTimeEntry.value)
  console.log(validate)
  if (validate === true) {
    await timeEntryStore.stopActiveEntry()
    activeTimeEntry.value = null
  } else {
    openTimeEntryModal(true)
  }
  // initActiveTimeEntry()
}

watchEffect(async () => {
  if (activeTimeEntry.value == null) {
    wantsToStopEntry.value = false
    timeEntryModalModel.value = timeEntryStore.newEntry()
    stopwatch.value = null
    return
  }
  timeEntryModalModel.value = timeEntryStore.getActiveTimeEntry
  try {
    if (!activeTimeEntry.value) {
      return
    }
    stopwatch.value = useStopwatch(
      (Date.now() - Date.parse(activeTimeEntry.value?.started_at)) / 1000,
      true
    )
    stopwatch.value.start()
    // If the button should only be available after 1 minute.
    // if (
    //   activeTimeEntry.value &&
    //   activeTimeEntry.value.started_at &&
    //   Date.parse(activeTimeEntry.value.started_at) > Date.now() - 60_000
    // ) {
    //   timelinkStoresService.setOrRenewTimeout(
    //     'activeTrackingComponent',
    //     'activateButton',
    //     async () => {
    //       stopwatch.value = useStopwatch(
    //         (Date.now() - Date.parse(activeTimeEntry.value.started_at)) / 1000,
    //         true
    //       )
    //       stopwatch.value.start()
    //     },
    //     Date.parse(activeTimeEntry.value.started_at) + 61_000 - Date.now()
    //   )
    // }
  } catch (error) {
    console.log(error)
  }
})

onMounted(() => {
  timeEntryStore.$subscribe((mutation) => {
    if (
      !Object.hasOwn(mutation, 'payload') ||
      !Object.hasOwn(mutation.payload, 'activeTimeEntry') ||
      (mutation.payload.activeTimeEntry != null &&
        activeTimeEntry.value?.id == mutation.payload.activeTimeEntry)
    ) {
      return
    }
    activeTimeEntry.value = timeEntryStore.getActiveTimeEntry
  })
  activeTimeEntry.value = timeEntryStore.getActiveTimeEntry
  timeEntryStore.loadActive()
  window.addEventListener('open-active-time-entry-modal-panel', openTimeEntryModal)
})
onBeforeUnmount(() => {
  window.removeEventListener('open-active-time-entry-modal-panel', openTimeEntryModal)
})
</script>
