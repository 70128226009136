<template>
    <button
        class="flex items-center justify-center p-4 text-3xl bg-white cursor-pointer ring-1 ring-mossgray-200 sm:rounded-xl hover:ring hover:ring-mossgray hover:ring-2 disabled:bg-mossgray-200 disabled:hover:ring-0"
        :disabled="props.disabled" @click="chooseProvider">
        <slot>{{ props.provider ?? 'Provider name' }}</slot>
    </button>
</template>

<script setup>

const props = defineProps({
    provider: {
        type: String,
        required: true
    },
    disabled: {
        type: Boolean,
        default: false
    }
})
const emit = defineEmits(['setProvider'])

function chooseProvider() {
    if (!props.provider) {
        throw Error('Define a provider')
    }
    emit('setProvider', props.provider)
}
</script>