<template>
  <loading-spinner v-model="isLoading" :fullPage="true" />
  <div
    class="flex flex-col pb-6 px-6 site-background min-h-screen h-full basis-full"
    v-if="!isOffline"
  >
    <div class="py-3 mb-3 flex w-full items-center">
      <h1 class="text-2xl font-bold mr-auto">
        {{ $t('menu.settings_.clients_and_projects') }}
      </h1>
      <div class="flex space-x-2">
        <input
          type="text"
          id="search"
          name="search"
          v-model="search"
          class="input"
          :placeholder="$t('search')"
        />
      </div>
      <div class="flex flex-row space-x-4 pl-3">
        <BaseButton
          class="button-mossgray"
          @click="showCreateModal = true"
          :disabled="companyStore.company.pull_provider"
          v-if="authUserStore.user.admin >= 9"
        >
          <font-awesome-icon :icon="['fa-kit', 'tl-plus']" class="mr-2 text-lg" /> {{ $t('new_client') }}
        </BaseButton>
      </div>
    </div>

    <div class="overflow-x-auto">
      <!-- TODO: Translation -->
      <Alert v-if="clients.length == 0">Keine Kunden vorhanden.</Alert>

      <div class="border border-mossgray-200 rounded-lg" v-if="clients.length > 0">
        <div class="divide-y divide-mossray-300 bg-white rounded-t-lg">
          <template v-for="client in clients" :key="client.id">
            <ClientsListElement :client="client" />
          </template>
        </div>

        <div
          class="flex items-center justify-between border-t border-mossgray-200 bg-white px-4 py-3 sm:px-6 rounded-b-lg"
        >
          <div class="flex flex-1 justify-between sm:hidden">
            <a href="#" class="button button-gray">{{ $t('previous') }}</a>
            <a href="#" class="button button-gray">{{ $t('next') }}</a>
          </div>
          <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                {{ $t('pagination_showing') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.from }}</span>
                {{ ' ' }}
                {{ $t('pagination_to') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.to }}</span>
                {{ ' ' }}
                {{ $t('pagination_of') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.total }}</span>
                {{ ' ' }}
                {{ $t('pagination_total') }}
              </p>
            </div>
            <div>
              <input
                type="number"
                class="input"
                min="1"
                :max="this.pagination.last_page"
                v-model="this.page"
                @change="fetch"
              />
            </div>
            <div>
              <nav
                class="isolate inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <button
                  type="button"
                  @click="previous"
                  class="relative inline-flex items-center rounded-l-full px-2 pl-3 py-2 text-mossgray-800 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span class="sr-only">{{ $t('previous') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-left']" fixed-width />
                </button>

                <template v-for="page in this.pagination.links" :key="page.label">
                  <button
                    :class="{
                      'relative z-10 inline-flex items-center bg-mossgray px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mossgray':
                        this.page == page.label,
                      'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0':
                        this.page != page.label
                    }"
                    type="button"
                    :disabled="page.label == '...'"
                    @click="gotoPage(parseInt(page.label))"
                  >
                    {{ page.label }}
                  </button>
                </template>

                <button
                  type="button"
                  @click="next"
                  class="relative inline-flex items-center rounded-r-full px-2 pr-3 py-2 text-mossgray-800 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span class="sr-only">{{ $t('next') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-right']" fixed-width />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ClientsCreate v-model="showCreateModal" />
  </div>
  <!-- TODO: Translation -->
  <div v-else>Du bist <u>offline!</u></div>

  <SquirclePath></SquirclePath>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import SquirclePath from '@/components/general/SquirclePath.vue'
import apiService from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import Alert from '@/components/general/AlertComponent.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import ClientsCreate from '@/components/settings/ClientsCreate.vue'
import { useCompanyStore } from '@/stores/company'
import ClientsListElement from '@/components/settings/ClientsListElement.vue'
import { useAuthUserStore } from '@/stores/auth-user'
import { useAlertsStore } from '@/stores/alerts'

export default {
  // props: ['page'],
  components: {
    ClientsCreate,
    BaseButton,
    LoadingSpinner,
    ClientsListElement,
    Alert,
    SquirclePath
  },
  setup() {
    const timelinkService = timelinkStoresService
    const companyStore = useCompanyStore()
    const authUserStore = useAuthUserStore()
    return { timelinkService, companyStore, authUserStore }
  },
  data() {
    return {
      isLoading: true,
      clients: [],
      saveComplete: null,
      errorMessage: null,
      // page: 1,
      limit: 10,
      pagination: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [],
        path: '',
        per_page: 10,
        to: 1,
        total: 1
      },
      showCreateModal: false,
      search: '',
      isOffline: false
    }
  },
  created() {},
  mounted() {
    this.fetch()
    this.$echo
      .private('company.' + useAuthUserStore().user.company_id)
      .listen('ClientCreated', () => {
        this.fetch(true)
      })
      .listen('.client.created', () => {
        this.fetch(true)
      })
  },
  beforeUnmount() {
    this.unregisterEcho()
    this.$echo.leave('company.' + useAuthUserStore().user.company_id)
  },
  watch: {
    search() {
      this.timelinkService.setOrRenewTimeout(
        'clientsList',
        'search',
        () => {
          if (this.page != 1) {
            this.page = 1
          } else {
            this.fetch()
          }
        },
        300
      )
    },
    $route() {
      this.fetch()
    },
    showCreateModal(newVal) {
      if (!newVal) {
        this.fetch(true)
      }
    }
  },
  computed: {
    page: {
      get() {
        return parseInt(this.$route.query.page ?? 1)
      },
      set(value) {
        value = parseInt(value)
        this.$router.replace({ name: 'ClientsList', query: { ...this.$route.query, page: value } })
      }
    }
  },
  methods: {
    async fetch(withoutLoading = false) {
      if (!withoutLoading) {
        this.isLoading = true
      }
      this.unregisterEcho()
      try {
        let data = await apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/clients', {
          limit: this.limit,
          page: this.page,
          search: this.search,
          orders: [
            {
              column: 'name',
              direction: 'asc'
            }
          ]
        })
        this.pagination = { ...data.meta }
        this.pagination.links = this.pagination.links.filter(
          (item) => !item.label.includes('Previous') && !item.label.includes('Next')
        )
        this.clients = data.data

        this.registerEcho()
      } catch (error) {
        if (error.code == 'ERR_NETWORK' || error.code == 'ERR_CANCELED') {
          useAlertsStore().error(this.$t('errors.noConnection'))
        } else {
          useAlertsStore().error(this.$t('errors.ups'))
        }
      }
      if (!withoutLoading) {
        this.isLoading = false
      }
    },
    unregisterEcho() {
      this.clients.forEach((item) => {
        this.$echo.leave('client.' + item.id)
      })
    },
    registerEcho() {
      this.clients.forEach((item) => {
        this.$echo
          .private('client.' + item.id)
          .listen('ClientDeleted', () => {
            this.fetch()
          })
          .listen('.client.deleted', () => {
            this.fetch()
          })
      })
    },
    next() {
      if (this.page >= this.pagination.last_page) {
        return
      }
      this.page += 1
      // this.fetch()
    },
    previous() {
      if (this.page <= 1) {
        return
      }
      this.page -= 1
      // this.fetch()
    },
    gotoPage(page = 1) {
      if (page < 1) {
        this.page = 1
      } else if (page > this.pagination.last_page) {
        this.page = this.pagination.last_page
      } else {
        this.page = page
      }
    }
  }
}
</script>
